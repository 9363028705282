// charts
import Highcharts from 'highcharts';

// local components
import Container from './components/Container';
import Grade from './components/Grade';

// local lib
import sortData from './lib/sortData.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// risk lib
import getScoreColor from '../../../risk/lib/getScoreColor.lib.risk';

// uuid
import {v4} from 'uuid';

const ComplianceBreakdownChart = ({
  compliance,
  data = [],
  height = '340px',
  onSectionClick = () => {},
  title = 'Compliance Rating',
}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'pie',
        height,
      },
      title: {
        text: title,
        x: 0,
      },
      tooltip: {
        formatter: function () {
          return `<b>${this.point.name}</b><br/>Score: ${
            this.point.score
          }<br/>Coverage: ${this.point.completeness}%<div>${
            this.point.shortDescription || ''
          }</div>`;
        },
        outside: true,
        useHTML: true,
      },
      plotOptions: {
        pie: {
          innerSize: 150,
          depth: 45,
          format: '{y:,.0f}',
          allowPointSelect: false,
        },
        series: {
          allowPointSelect: true,
          dataLabels: {
            enabled: false,
          },
          cursor: 'pointer',
          point: {
            events: {
              click: function (event) {
                const compliance = event?.point?.compliance || null;
                if (!compliance) return;
                onSectionClick({compliance, frameworks: data});
              },
            },
          },
        },
      },

      series: [
        {
          name: 'Cyber Rating',
          colorByPoint: true,
          data: sortData(data),
        },
      ],
    });
    // eslint-disable-next-line
  }, [data, height, title]);
  return (
    <Container>
      <div id={id} />
      <Grade color={getScoreColor(compliance)}>{compliance}%</Grade>
    </Container>
  );
};

ComplianceBreakdownChart.propTypes = {
  compliance: PropTypes.number,
  data: PropTypes.array,
  height: PropTypes.string,
  onSectionClick: PropTypes.func,
  title: PropTypes.string,
};

export default ComplianceBreakdownChart;
