import styled from 'styled-components';

const MenuItem = styled.div`
  width: 100%;
  padding: 8px 0px;
  font-size: ${({indent}) => (indent ? '16px' : '16px')};
  padding-left: ${({indent}) => (indent ? '32px' : '0px')};
  font-weight: ${({indent}) => (indent ? 300 : 500)};
  padding-right: 32px;
`;

export default MenuItem;
