import React from 'react';
import {Table} from 'antd';

const MostRecentFilingsTable = () => {
  const data = [
    {
      key: '1',
      legalFiling: 'Collection',
      dateFiled: '10/22/2024',
      filingType: 'Collection',
      caseNumber: 'DD727648',
      status: 'Active',
      releasedDate: '-',
    },
    {
      key: '2',
      legalFiling: 'Liens',
      dateFiled: '09/13/2024',
      filingType: 'Liens',
      caseNumber: '509123493',
      status: 'NA',
      releasedDate: '-',
    },
    {
      key: '3',
      legalFiling: 'Liens',
      dateFiled: '07/22/2024',
      filingType: 'Liens',
      caseNumber: '507486753',
      status: 'NA',
      releasedDate: '-',
    },
    {
      key: '4',
      legalFiling: 'Liens',
      dateFiled: '07/19/2024',
      filingType: 'Liens',
      caseNumber: '507435525',
      status: 'NA',
      releasedDate: '-',
    },
    {
      key: '5',
      legalFiling: 'Liens',
      dateFiled: '07/17/2024',
      filingType: 'Liens',
      caseNumber: '507373416',
      status: 'NA',
      releasedDate: '-',
    },
  ];

  const columns = [
    {
      title: 'Legal Filing',
      dataIndex: 'legalFiling',
      key: 'legalFiling',
    },
    {
      title: 'Date Filed',
      dataIndex: 'dateFiled',
      key: 'dateFiled',
    },
    {
      title: 'Filing Type',
      dataIndex: 'filingType',
      key: 'filingType',
    },
    {
      title: 'Case Number',
      dataIndex: 'caseNumber',
      key: 'caseNumber',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Released Date',
      dataIndex: 'releasedDate',
      key: 'releasedDate',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      style={{width: '100%'}}
    />
  );
};

export default MostRecentFilingsTable;
