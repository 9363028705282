import React from 'react';
import {Table} from 'antd';
import Highcharts from 'highcharts';

// uuid
import {v4} from 'uuid';
import {useEffect} from 'react';

const TradePaymentsByCreditExtended = () => {
  const id = v4();
  const tableData = [
    {
      key: '1',
      creditExtended: 'Over $1M',
      tradeLines: '-',
      totalAmount: '-',
      pastDue: '-',
      withinTerms: '-',
    },
    {
      key: '2',
      creditExtended: '$100K - $1M',
      tradeLines: '-',
      totalAmount: '-',
      pastDue: '-',
      withinTerms: '-',
    },
    {
      key: '3',
      creditExtended: '$50K - $100K',
      tradeLines: '-',
      totalAmount: '-',
      pastDue: '-',
      withinTerms: '-',
    },
    {
      key: '4',
      creditExtended: '$25K - $50K',
      tradeLines: '-',
      totalAmount: '-',
      pastDue: '-',
      withinTerms: '-',
    },
    {
      key: '5',
      creditExtended: '$5K - $25K',
      tradeLines: '-',
      totalAmount: '-',
      pastDue: '-',
      withinTerms: '-',
    },
    {
      key: '6',
      creditExtended: '$1K - $5K',
      tradeLines: '-',
      totalAmount: '-',
      pastDue: '-',
      withinTerms: '-',
    },
    {
      key: '7',
      creditExtended: 'Under $1K',
      tradeLines: 7,
      totalAmount: 'Can$70',
      pastDue: 'Can$3K',
      withinTerms: '100%',
    },
  ];

  const columns = [
    {
      title: 'Credit Extended',
      dataIndex: 'creditExtended',
      key: 'creditExtended',
    },
    {title: 'Trade Lines', dataIndex: 'tradeLines', key: 'tradeLines'},
    {title: 'Total Amount', dataIndex: 'totalAmount', key: 'totalAmount'},
    {title: 'Past Due', dataIndex: 'pastDue', key: 'pastDue'},
    {title: 'Within Terms', dataIndex: 'withinTerms', key: 'withinTerms'},
  ];

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: 'bar',
      },
      title: {
        text: 'Within Terms (%)',
      },
      xAxis: {
        categories: [
          'Over $1M',
          '$100K - $1M',
          '$50K - $100K',
          '$25K - $50K',
          '$5K - $25K',
          '$1K - $5K',
          'Under $1K',
        ],
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: 'Percentage',
        },
      },
      series: [
        {
          name: 'Within Terms (%)',
          data: [0, 0, 0, 0, 0, 0, 100],
          color: '#007BFF',
        },
      ],
    };
    Highcharts.chart(id, chartOptions);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        width: '100%',
      }}
    >
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        style={{flex: 1}}
      />
      <div style={{flex: 1}}>
        <div id={id} />
      </div>
    </div>
  );
};

export default TradePaymentsByCreditExtended;
