// ant components
import {Card, Table} from 'antd';

// local lib
import getColumns from './lib/getColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ComplianceEntriesTable = ({entries, onSelect, title}) => (
  <Card
    title={title}
    styles={{
      body: {
        padding: 0,
        overflow: 'hidden',
      },
    }}
  >
    <Table
      columns={getColumns([...entries].find(({score}) => score !== undefined))}
      dataSource={entries}
      pagination={false}
      rowKey="area"
      size="small"
      onRow={(record) => ({
        onClick: () => onSelect(record.id),
      })}
    />
  </Card>
);

ComplianceEntriesTable.propTypes = {
  entries: PropTypes.array,
  onSelect: PropTypes.func,
  title: PropTypes.string,
};

export default ComplianceEntriesTable;
