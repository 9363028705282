import styled from 'styled-components';

// layout colors
import whiteColor from '../../../../layout/colors/white.color.layout';

const ListContainer = styled.div`
  width: 100%;
  background: ${whiteColor};
`;

export default ListContainer;
