// ant components
import {message as messageApi} from 'antd';

// engagementService components
import EngagementServiceEditorModal from '../../components/EngagementServiceEditorModal/EngagementServiceEditorModal';

// engagementService events
import showUpdateEngagementServiceModalEvent from '../../events/showUpdateModal.event.engagementService';

// engagementService services
import updateEngagementServiceService from '../../services/update.service.engagementService';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class UpdateEngagementServiceContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static INITIAL_STATE = {
    companyId: null,
    engagementId: null,
    engagementService: null,
    name: '',
    saving: false,
    visible: false,
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    this.props.subscribe(
      showUpdateEngagementServiceModalEvent.subscribe(this.show)
    );
  }

  show = ({companyId, engagementId, engagementService}) => {
    this.setState({
      ...this.constructor.INITIAL_STATE,
      companyId,
      engagementId,
      engagementService,
      name: engagementService.name,
      visible: true,
    });
  };

  hide = () => {
    if (this.state.saving) return;
    this.setState({visible: false});
  };

  change = (key) => (e) => {
    if (this.state.saving) return;
    const value = !!e?.target ? e.target.value : e;
    this.setState({[key]: value});
  };

  save = async () => {
    const {companyId, engagementId, engagementService, name, saving} =
      this.state;

    if (saving) return;

    this.setState({saving: true});

    try {
      await updateEngagementServiceService({
        companyId,
        engagementId,
        engagementServiceId: engagementService.id,
        name,
      });
      this.setState({saving: false, visible: false});
    } catch (error) {
      messageApi.error(error.message);
      this.setState({saving: false});
    }
  };

  render() {
    const {name, saving, visible} = this.state;
    return (
      <EngagementServiceEditorModal
        name={name}
        onChange={this.change}
        onClose={this.hide}
        onSave={this.save}
        saving={saving}
        visible={visible}
        title="Update Service"
      />
    );
  }
}

export default subscriptionHOC(UpdateEngagementServiceContainer);
