// ant components
import {Card} from 'antd';

// compliance components
import ComplianceScoreBar from '../../components/ComplianceScoreBar/ComplianceScoreBar';

// compliance lib
import isCustomAnsysFindingResponse from '../../lib/isCustomAnsysFindingResponse.lib.compliance';
import parseFindingControlDescription from '../../lib/parseFindingControlDescription.lib.compliance';
import parseFindingResponse from '../../lib/parseFindingResponse.lib.compliance';

// file components
import InlineFileDownloader from '../../../file/components/InlineFileDownloader/InlineFileDownloader';

// local components
import Container from './components/Container';
import Content from './components/Content';
import Files from './components/Files';
import Line from './components/Line';
import Row from './components/Row';
import ScoreBar from './components/ScoreBar';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ComplianceFindingCard = ({
  completeness,
  compliance,
  controlDescription,
  controlId,
  explanation,
  files = [],
  isPassed,
  isTested,
  reference,
  response,
}) => {
  return (
    <Card>
      <Container>
        {!!controlId && (
          <Row>
            <Title>Control ID</Title>
            <Content>{controlId}</Content>
          </Row>
        )}
        {!!controlDescription && (
          <Row>
            <Title>Control Description</Title>
            <Content>
              {parseFindingControlDescription(controlDescription)}
            </Content>
          </Row>
        )}
        <Line />
        <Row>
          <Title>Control Efficacy</Title>
          <ScoreBar>
            <ComplianceScoreBar score={compliance} />
          </ScoreBar>
        </Row>
        {!!explanation && (
          <Row>
            <Title>Commentary</Title>
            <Content dangerouslySetInnerHTML={{__html: explanation || '-'}} />
          </Row>
        )}
        {!!response ? (
          isCustomAnsysFindingResponse(response) ? (
            <Container>
              {parseFindingResponse(response).map((entry) => (
                <Row key={entry.title}>
                  <Title>{entry.title}</Title>
                  <Content
                    dangerouslySetInnerHTML={{
                      __html: entry.content || '-',
                    }}
                  />
                </Row>
              ))}
            </Container>
          ) : (
            <Row>
              <Title>Response</Title>
              <Content
                dangerouslySetInnerHTML={{
                  __html: response || '-',
                }}
              />
            </Row>
          )
        ) : null}
        <Row>
          <Title>Coverage</Title>
          <Content bold>{completeness}%</Content>
        </Row>
        <Row>
          <Title>Control Tested</Title>
          <Content bold>{isTested ? 'Yes' : 'No'}</Content>
        </Row>
        <Row>
          <Title>Test Result</Title>
          <Content bold>
            {!isTested ? 'n/a' : isPassed ? 'Passed' : 'Failed'}
          </Content>
        </Row>
        {!!reference && (
          <Row>
            <Title>References</Title>
            <Content>{reference}</Content>
          </Row>
        )}
        {!!files?.length && (
          <Row>
            <Title>{files.length > 1 ? 'Files' : 'File'}</Title>
            <Content>
              <Files>
                {files.map((file) => (
                  <InlineFileDownloader
                    fileId={file.fileId}
                    fileVersionId={file.fileVersionId}
                    key={file.fileId}
                  >
                    {file.fileName}
                  </InlineFileDownloader>
                ))}
              </Files>
            </Content>
          </Row>
        )}
      </Container>
    </Card>
  );
};

ComplianceFindingCard.propTypes = {
  completeness: PropTypes.number,
  compliance: PropTypes.number,
  confidence: PropTypes.number,
  controlDescription: PropTypes.string,
  controlId: PropTypes.string,
  explanation: PropTypes.string,
  files: PropTypes.array,
  isPassed: PropTypes.bool,
  isTested: PropTypes.bool,
  reference: PropTypes.string,
  response: PropTypes.string,
};

export default ComplianceFindingCard;
