// ant
import {ConfigProvider} from 'antd';
import {createStyles} from 'antd-style';

// layout colors
import green400Color from '../../colors/green400.color.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const useStyle = createStyles(({prefixCls, css}) => ({
  linearGradientButton: css`
    &.${prefixCls}-btn-primary:not([disabled]):not(
        .${prefixCls}-btn-dangerous
      ) {
      > span {
        position: relative;
      }

      &::before {
        content: '';
        background: linear-gradient(135deg, #153443 20%, #63d57e 100%);
        position: absolute;
        inset: -1px;
        opacity: 1;
        transition: all 0.4s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `,
}));

const Theme = ({children}) => {
  const {styles} = useStyle();
  return (
    <ConfigProvider
      theme={{token: {colorPrimary: green400Color}}}
      button={{
        className: styles.linearGradientButton,
      }}
    >
      {children}
    </ConfigProvider>
  );
};

Theme.propTypes = {
  children: PropTypes.node,
};

export default Theme;
