// ant components
import {Col as Column, Row} from 'antd';

// local components
import FirmographicTable from '../FirmographicTable/FirmographicTable';
import IndustryBusinessClassificationTable from '../IndustryBusinessClassificationTable/IndustryBusinessClassificationTable';
import ContactInformationTable from '../ContactInformationTable/ContactInformationTable';
import WebsiteTechnologiesTable from '../WebsiteTechnologiesTable/WebsiteTechnologiesTable';
import CompanyDescriptions from '../CompanyDescriptions/CompanyDescriptions';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const FirmographicTables = ({company, printView = false}) => {
  const firmographics = company?.firmographic || {};
  return (
    <Row gutter={[20, 20]}>
      <Column span={printView ? 24 : 12}>
        <Row gutter={[20, 20]}>
          <Column span={24}>
            <FirmographicTable firmographics={firmographics} />
          </Column>
          <Column span={24}>
            <IndustryBusinessClassificationTable
              firmographics={firmographics}
            />
          </Column>
        </Row>
      </Column>
      <Column span={printView ? 24 : 12}>
        <Row gutter={[20, 20]}>
          <Column span={24}>
            <ContactInformationTable firmographics={firmographics} />
          </Column>
          <Column span={24}>
            <CompanyDescriptions
              firmographics={firmographics}
              printView={printView}
            />
          </Column>
          <Column span={24}>
            <WebsiteTechnologiesTable firmographics={firmographics} />
          </Column>
        </Row>
      </Column>
    </Row>
  );
};

FirmographicTables.propTypes = {
  company: PropTypes.object,
};

export default FirmographicTables;
