// engagement routes
import engagementsRoute from '../EngagementsPage/route';

// object lib
import getRawObjectId from '../../../object/lib/getRawId.lib.object';

// route lib
import parseRoute from '../../../route/lib/parseRoute.lib.route';

const route = (
  {companyId = ':id', engagementId = ':engagementId'} = {},
  {query, hash} = {}
) =>
  parseRoute({
    path: `${engagementsRoute({companyId})}/engagement/${getRawObjectId(
      engagementId
    )}`,
    query,
    hash,
  });

export default route;
