import React from 'react';
import {Table} from 'antd';

const data = [
  {
    key: '1',
    reportedDate: '01/10/2025',
    claimDate: '11/24/2021',
    creditor: 'ISI INNOVATIVE SOLUTIONS',
    debtor: 'GFL ENVIRONMENT',
    claimAmount: 'Can$317',
    amountPaid: 'Can$317',
    accountBalance: 'Can$0',
  },
  {
    key: '2',
    reportedDate: '01/09/2025',
    claimDate: '11/13/2024',
    creditor: 'ROGERS COMMUNICATIONS CANADA',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$212',
    amountPaid: 'Can$0',
    accountBalance: 'Can$212',
  },
  {
    key: '3',
    reportedDate: '01/08/2025',
    claimDate: '04/04/2023',
    creditor: 'TOTAL FIRE SOLUTIONS INC',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$1,469',
    amountPaid: 'Can$0',
    accountBalance: 'Can$1,469',
  },
  {
    key: '4',
    reportedDate: '01/06/2025',
    claimDate: '05/23/2024',
    creditor: '-',
    debtor: 'GFL ENVIRONMENTAL',
    claimAmount: 'Can$1,213',
    amountPaid: 'Can$0',
    accountBalance: 'Can$1,213',
  },
  {
    key: '5',
    reportedDate: '12/24/2024',
    claimDate: '10/25/2024',
    creditor: 'CITY OF THUNDER BAY FINES',
    debtor: 'GFL ENVIRONMENTAL INC.',
    claimAmount: 'Can$615',
    amountPaid: 'Can$0',
    accountBalance: 'Can$615',
  },
  {
    key: '6',
    reportedDate: '12/20/2024',
    claimDate: '10/10/2024',
    creditor: 'ENMAX (CH)',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$8,638',
    amountPaid: 'Can$0',
    accountBalance: 'Can$8,638',
  },
  {
    key: '7',
    reportedDate: '11/27/2024',
    claimDate: '11/02/2020',
    creditor: 'UNISERVE COMMUNICATIONS',
    debtor: 'GFL ENVIRONMENTAL INC.',
    claimAmount: 'Can$9,238',
    amountPaid: 'Can$9,238',
    accountBalance: 'Can$0',
  },
  {
    key: '8',
    reportedDate: '10/02/2024',
    claimDate: '08/12/2024',
    creditor: 'CITY OF RICHMOND- PARKING',
    debtor: 'GFL ENVIRONMENTAL INC.',
    claimAmount: 'Can$135',
    amountPaid: 'Can$0',
    accountBalance: 'Can$135',
  },
  {
    key: '9',
    reportedDate: '09/19/2024',
    claimDate: '07/31/2024',
    creditor: 'BURLINGTON POA',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$231',
    amountPaid: 'Can$0',
    accountBalance: 'Can$231',
  },
  {
    key: '10',
    reportedDate: '09/19/2024',
    claimDate: '07/31/2024',
    creditor: 'BURLINGTON POA',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$581',
    amountPaid: 'Can$0',
    accountBalance: 'Can$581',
  },
  {
    key: '11',
    reportedDate: '09/18/2024',
    claimDate: '06/06/2024',
    creditor: 'QUICKBOOKS ONLINE PAYROLL',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$637',
    amountPaid: 'Can$0',
    accountBalance: 'Can$637',
  },
  {
    key: '12',
    reportedDate: '09/18/2024',
    claimDate: '05/30/2024',
    creditor: 'UNITED RENTALS OF CANADA INC.',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$1,935',
    amountPaid: 'Can$1,935',
    accountBalance: 'Can$0',
  },
  {
    key: '13',
    reportedDate: '09/13/2024',
    claimDate: '10/26/2022',
    creditor: '0860999002535690Z00 POA',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$386',
    amountPaid: 'Can$0',
    accountBalance: 'Can$386',
  },
  {
    key: '14',
    reportedDate: '09/06/2024',
    claimDate: '06/08/2024',
    creditor: 'TELUS MOBILITY INC',
    debtor: 'GFL ENVIRONMENTINC',
    claimAmount: 'Can$2,735',
    amountPaid: 'Can$0',
    accountBalance: 'Can$2,735',
  },
  {
    key: '15',
    reportedDate: '07/25/2024',
    claimDate: '11/24/2021',
    creditor: 'ISI INNOVATIVE SOLUTIONS',
    debtor: 'GFL ENVIRONMENT',
    claimAmount: 'Can$317',
    amountPaid: 'Can$317',
    accountBalance: 'Can$0',
  },
  {
    key: '16',
    reportedDate: '07/19/2024',
    claimDate: '03/01/2024',
    creditor: '1260999002573411Z00 POA',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$566',
    amountPaid: 'Can$0',
    accountBalance: 'Can$566',
  },
  {
    key: '17',
    reportedDate: '07/19/2024',
    claimDate: '03/01/2024',
    creditor: '1260999002573412Z00 POA',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$225',
    amountPaid: 'Can$0',
    accountBalance: 'Can$225',
  },
  {
    key: '18',
    reportedDate: '07/19/2024',
    claimDate: '05/23/2024',
    creditor: '-',
    debtor: 'GFL ENVIRONMENTAL',
    claimAmount: 'Can$1,213',
    amountPaid: 'Can$0',
    accountBalance: 'Can$1,213',
  },
  {
    key: '19',
    reportedDate: '07/10/2024',
    claimDate: '06/08/2024',
    creditor: 'TELUS MOBILITY INC',
    debtor: 'GFL ENVIRONMENTINC',
    claimAmount: 'Can$2,735',
    amountPaid: 'Can$0',
    accountBalance: 'Can$2,735',
  },
  {
    key: '20',
    reportedDate: '06/14/2024',
    claimDate: '10/26/2022',
    creditor: '0860999002535690Z00 POA',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$386',
    amountPaid: 'Can$0',
    accountBalance: 'Can$386',
  },
  {
    key: '21',
    reportedDate: '04/17/2024',
    claimDate: '03/13/2024',
    creditor: 'ROGERS COMMUNICATIONS CANADA',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$294',
    amountPaid: 'Can$294',
    accountBalance: 'Can$0',
  },
  {
    key: '22',
    reportedDate: '07/06/2023',
    claimDate: '04/12/2023',
    creditor: 'UPS SUPPLY CHAIN SOLUTIONS INC',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$180',
    amountPaid: 'Can$0',
    accountBalance: 'Can$180',
  },
  {
    key: '23',
    reportedDate: '12/03/2021',
    claimDate: '10/29/2021',
    creditor: 'CITY OF PORTAGE LA PRAIRIE (',
    debtor: 'GREEN FOR LIFE',
    claimAmount: 'Can$202',
    amountPaid: 'Can$0',
    accountBalance: 'Can$202',
  },
  {
    key: '24',
    reportedDate: '10/30/2021',
    claimDate: '09/28/2021',
    creditor: 'THE CITY OF EDMONTON-CORPORA',
    debtor: 'GFL ENVIRONMENTAL SOLID WASTE',
    claimAmount: 'Can$1,230',
    amountPaid: 'Can$1,230',
    accountBalance: 'Can$0',
  },
  {
    key: '25',
    reportedDate: '09/21/2021',
    claimDate: '08/12/2021',
    creditor: 'AIR LIQUIDE CANADA (WESTERN)',
    debtor: 'GFL ENVIRONMENTAL INC',
    claimAmount: 'Can$260',
    amountPaid: 'Can$260',
    accountBalance: 'Can$0',
  },
  {
    key: '26',
    reportedDate: '06/19/2020',
    claimDate: '05/07/2020',
    creditor: 'CINTAS CANADA',
    debtor: 'GFL ENVIRONMENTAL INC.',
    claimAmount: 'Can$3,506',
    amountPaid: 'Can$0',
    accountBalance: 'Can$3,506',
  },
];

const columns = [
  {
    title: 'Reported Date',
    dataIndex: 'reportedDate',
    key: 'reportedDate',
  },
  {
    title: 'Claim Date',
    dataIndex: 'claimDate',
    key: 'claimDate',
  },
  {
    title: 'Creditor',
    dataIndex: 'creditor',
    key: 'creditor',
  },
  {
    title: 'Debtor',
    dataIndex: 'debtor',
    key: 'debtor',
  },
  {
    title: 'Claim Amount',
    dataIndex: 'claimAmount',
    key: 'claimAmount',
  },
  {
    title: 'Amount Paid',
    dataIndex: 'amountPaid',
    key: 'amountPaid',
  },
  {
    title: 'Account Balanced',
    dataIndex: 'accountBalance',
    key: 'accountBalance',
  },
];

const CollectionDetails = ({printView = false}) => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={printView ? false : {pageSize: 10}}
    />
  );
};

export default CollectionDetails;
