import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding-left: 5px;
`;

export default Container;
