// ant components
import {CaretDownOutlined} from '@ant-design/icons';

// layout components
import Bar from './components/Bar';
import Box from './components/Box';
import Container from './components/Container';
import ScoreContainer from './components/ScoreContainer';
import ScoreRow from './components/ScoreRow';

// react
import React from 'react';

// propTypes
import PropTypes from 'prop-types';

// risk constants
import SCORE_LEVELS from '../../../risk/constants/scoreLevels.constant.risk';

// risk lib
import getScoreColor from '../../../risk/lib/getScoreColor.lib.risk';

const ComplianceScoreBar = ({score, width = '100%'}) => {
  const color = getScoreColor(score);
  const {bars} = [...SCORE_LEVELS].reduce(
    (compliance, [level, color]) => ({
      previousScore: level,
      bars: [
        ...compliance.bars,
        {
          width: level - compliance.previousScore,
          level,
          color,
        },
      ],
    }),
    {previousScore: 0, bars: []}
  );
  return (
    <Box>
      <ScoreRow>
        <ScoreContainer score={score} color={color}>
          <div>{score}%</div>
          <CaretDownOutlined />
        </ScoreContainer>
      </ScoreRow>
      <Container width={width}>
        {[...bars].map(({width, color}) => (
          <Bar key={color} width={width} color={color} />
        ))}
      </Container>
    </Box>
  );
};

ComplianceScoreBar.propTypes = {
  score: PropTypes.number,
  width: PropTypes.string,
};

export default ComplianceScoreBar;
