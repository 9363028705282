import React from 'react';
import {Table, Badge} from 'antd';

const QuarterlyIndexPaymentHistory = () => {
  const data = [
    {
      key: '1',
      quarter: 'Q4 2024',
      paymentIndex: 0,
      creditIndex: 38,
      references: 3,
      currentOwing: 'Can$838',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$838',
    },
    {
      key: '2',
      quarter: 'Q3 2024',
      paymentIndex: 0,
      creditIndex: 36,
      references: 4,
      currentOwing: 'Can$78.8K',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$78.8K',
    },
    {
      key: '3',
      quarter: 'Q2 2024',
      paymentIndex: 65,
      creditIndex: 46,
      references: 4,
      currentOwing: 'Can$987',
      period1: 'Can$0',
      period2: 'Can$10K',
      period3: 'Can$0',
      totalOwing: 'Can$11K',
    },
    {
      key: '4',
      quarter: 'Q1 2024',
      paymentIndex: 52,
      creditIndex: 35,
      references: 5,
      currentOwing: 'Can$7.1K',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$6.8K',
      totalOwing: 'Can$13.9K',
    },
    {
      key: '5',
      quarter: 'Q4 2023',
      paymentIndex: 0,
      creditIndex: 31,
      references: 5,
      currentOwing: 'Can$5.2K',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$5.2K',
    },
    {
      key: '6',
      quarter: 'Q3 2023',
      paymentIndex: 0,
      creditIndex: 31,
      references: 6,
      currentOwing: 'Can$2.4K',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$2.4K',
    },
    {
      key: '7',
      quarter: 'Q2 2023',
      paymentIndex: 64,
      creditIndex: 38,
      references: 6,
      currentOwing: 'Can$3.3K',
      period1: 'Can$524',
      period2: 'Can$3.3K',
      period3: 'Can$151',
      totalOwing: 'Can$7.3K',
    },
    {
      key: '8',
      quarter: 'Q1 2023',
      paymentIndex: 3,
      creditIndex: 33,
      references: 5,
      currentOwing: 'Can$5.3K',
      period1: 'Can$37',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$5.3K',
    },
    {
      key: '9',
      quarter: 'Q4 2022',
      paymentIndex: 0,
      creditIndex: 21,
      references: 4,
      currentOwing: 'Can$7.1K',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$7.1K',
    },
    {
      key: '10',
      quarter: 'Q3 2022',
      paymentIndex: 0,
      creditIndex: 36,
      references: 4,
      currentOwing: 'Can$9.4K',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$9.4K',
    },
  ];

  const columns = [
    {title: 'Quarter', dataIndex: 'quarter', key: 'quarter'},
    {
      title: 'Payment Index',
      dataIndex: 'paymentIndex',
      key: 'paymentIndex',
      render: (value) => {
        let color = 'green';
        if (value > 50) color = 'red';
        else if (value > 0) color = 'yellow';
        return <Badge color={color} text={`${value}`} />;
      },
    },
    {title: 'Credit Index', dataIndex: 'creditIndex', key: 'creditIndex'},
    {title: 'No. of References', dataIndex: 'references', key: 'references'},
    {title: 'Current Owing', dataIndex: 'currentOwing', key: 'currentOwing'},
    {title: 'Period 1', dataIndex: 'period1', key: 'period1'},
    {title: 'Period 2', dataIndex: 'period2', key: 'period2'},
    {title: 'Period 3', dataIndex: 'period3', key: 'period3'},
    {title: 'Total Owing', dataIndex: 'totalOwing', key: 'totalOwing'},
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default QuarterlyIndexPaymentHistory;
