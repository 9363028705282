// engagement components
import CreateEngagementCard from '../../components/CreateEngagementCard/CreateEngagementCard';
import DueDiligenceLayout from '../../components/DueDiligenceLayout/DueDiligenceLayout';

// engagement events
import createEngagementEvent from '../../events/create.event.engagement';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// tour containers
import WalkthroughContainer from '../../../tour/containers/WalkthroughContainer/WalkthroughContainer';

class CreateEngagementContainer extends Component {
  static propTypes = {
    creatingEngagement: PropTypes.bool,
  };

  save = () => {
    createEngagementEvent.publish();
  };

  tourSteps = () => [
    {
      title: 'Welcome',
      description:
        'Welcome to the due diligence center. This guide will walk you through all of the functionalities of the due diligence center.',
    },
    {
      title: 'Start',
      description:
        'To start, please click on the button to create an engagement.',
    },
  ];

  render() {
    const {creatingEngagement} = this.props;
    return (
      <DueDiligenceLayout hideActions hideFiles>
        <WalkthroughContainer
          autoStart
          steps={this.tourSteps()}
          walkthroughName="create-engagement"
        >
          {({domReferences}) => (
            <CreateEngagementCard
              domReferences={domReferences}
              onSave={this.save}
              saving={creatingEngagement}
            />
          )}
        </WalkthroughContainer>
      </DueDiligenceLayout>
    );
  }
}

export default connect((state) => ({
  creatingEngagement: state.engagement.creatingEngagement,
}))(CreateEngagementContainer);
