// ant components
import {Modal, Select} from 'antd';

// contract components
import ContractQuestionCard from '../ContractQuestionCard/ContractQuestionCard';

// layout components
import SmallLoader from '../../../layout/components/SmallLoader/SmallLoader';

// local components
import QuestionnaireContainer from './components/QuestionnaireContainer';

// math lib
import multiply from '../../../math/lib/multiply.lib.math';

// number lib
import integer from '../../../number/lib/integer.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const ContractAnswersModal = ({
  answers,
  fileName,
  loading,
  onClose,
  onQuestionnaire,
  questionnaire,
  questionnaires,
  visible,
}) => (
  <Modal
    title={fileName || 'Contract Answers'}
    centered
    open={visible}
    onOk={onClose}
    onCancel={onClose}
    width="1200px"
  >
    {loading ? (
      <SmallLoader />
    ) : (
      <Fragment>
        {!questionnaire || !answers.length ? (
          <Fragment>No answers found</Fragment>
        ) : (
          <Fragment>
            <Select
              value={questionnaire}
              style={{width: '100%'}}
              options={questionnaires}
              onChange={onQuestionnaire}
            />
            <QuestionnaireContainer>
              {answers.map((question) => (
                <ContractQuestionCard
                  key={question.id}
                  answer={question?.answer?.response}
                  coverage={integer(
                    multiply(question?.answer?.coverage || 0, 100)
                  )}
                  explanation={question?.answer?.explanation}
                  question={question?.prompt}
                  questionId={question?.name}
                  reference={question?.answer?.reference}
                />
              ))}
            </QuestionnaireContainer>
          </Fragment>
        )}
      </Fragment>
    )}
  </Modal>
);

ContractAnswersModal.propTypes = {
  answers: PropTypes.array,
  fileName: PropTypes.string,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onQuestionnaire: PropTypes.func,
  questionnaire: PropTypes.string,
  questionnaires: PropTypes.array,
  visible: PropTypes.bool,
};

export default ContractAnswersModal;
