// ant components
import {message as messageApi} from 'antd';

// engagementService events
import createEngagementServiceEvent from '../../events/create.event.engagementService';

// engagementService routes
import engagementServiceDetailRoute from '../../pages/EngagementServiceDetailPage/route';

// engagementService redux actions
import {setGeneralEngagementData as setGeneralEngagementDataAction} from '../../../engagement/redux/reducer.redux.engagement';

// engagementService services
import createEngagementServiceService from '../../services/create.service.engagementService';

// event HOC
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// navigation components
import Redirect from '../../../navigation/components/Redirect/Redirect';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// supplier lib
import getCompanyId from '../../../supplier/lib/getCompanyId.lib.supplier';

class EngagementServiceCreatorContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    creatingEngagementService: PropTypes.bool,
    engagement: PropTypes.object,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    redirect: null,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(createEngagementServiceEvent.subscribe(this.save));
  }

  save = async () => {
    const {company, creatingEngagementService, dispatch, engagement} =
      this.props;

    if (creatingEngagementService) return;

    dispatch(setGeneralEngagementDataAction({creatingEngagementService: true}));

    try {
      const companyId = getCompanyId(company);
      const engagementService = await createEngagementServiceService({
        companyId,
        engagementId: engagement.id,
      });
      const redirect = engagementServiceDetailRoute({
        companyId,
        engagementId: engagement.id,
        serviceId: engagementService.id,
      });
      this.setState({redirect});
    } catch (error) {
      messageApi.error(error.message);
    }

    dispatch(
      setGeneralEngagementDataAction({creatingEngagementService: false})
    );
  };

  render() {
    const {redirect} = this.state;
    return <Redirect url={redirect} />;
  }
}

export default connect((state) => ({
  company: state.company.company,
  creatingEngagementService: state.engagement.creatingEngagementService,
  engagement: state.engagement.engagement,
}))(subscriptionHOC(EngagementServiceCreatorContainer));
