import React from 'react';
import {Table} from 'antd';

const BankruptcyDetails = () => {
  const data = [
    {
      key: '1',
      reportedDate: '01/15/2025',
      estateNumber: '459709',
      estateName: 'MALVERN CONTRACT INTERIORS LIMITED',
      courtFileNumber: '-',
      filedDate: '10/31/2023',
      liabilityValue: '-',
    },
  ];

  const columns = [
    {title: 'Reported Date', dataIndex: 'reportedDate', key: 'reportedDate'},
    {title: 'Estate Number', dataIndex: 'estateNumber', key: 'estateNumber'},
    {title: 'Estate Name', dataIndex: 'estateName', key: 'estateName'},
    {
      title: 'Court File Number',
      dataIndex: 'courtFileNumber',
      key: 'courtFileNumber',
    },
    {title: 'Filed Date', dataIndex: 'filedDate', key: 'filedDate'},
    {
      title: 'Liability Value',
      dataIndex: 'liabilityValue',
      key: 'liabilityValue',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default BankruptcyDetails;
