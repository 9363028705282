import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  height: calc(100vh - 322px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
`;

export default Content;
