// engagement api
import updateEngagementApi from '../api/update.api.engagement';

// engagement events
import engagementDeletedEvent from '../events/deleted.event.engagement';

const deleteEngagementService = async ({engagementId, services = []}) => {
  const engagement = await updateEngagementApi(engagementId, {
    is_active: false,
  });

  const updatedEngagement = {
    ...engagement,
    services,
  };

  engagementDeletedEvent.publish({engagement: updatedEngagement});

  return updatedEngagement;
};

export default deleteEngagementService;
