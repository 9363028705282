// ant components
import {Collapse} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const {Panel} = Collapse;

const CompanyDescriptions = ({firmographics, printView}) => {
  if (!firmographics) return null;

  const {short_description, long_description} = firmographics;

  return (
    <StatementBlock title="Company Description" padding>
      <p>
        {printView ? long_description || short_description : short_description}
      </p>
      {!printView && long_description && (
        <Collapse>
          <Panel header="Show More" key="1">
            <p>{long_description}</p>
          </Panel>
        </Collapse>
      )}
    </StatementBlock>
  );
};

CompanyDescriptions.propTypes = {
  firmographics: PropTypes.object,
  printView: PropTypes.bool,
};

export default CompanyDescriptions;
