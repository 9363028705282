import styled from 'styled-components';

const Title = styled.div`
  width: 180px;
  max-width: 180px;
  min-width: 180px;
  font-weight: 700;
`;

export default Title;
