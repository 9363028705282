// ant components
import {Select, Tag} from 'antd';

// ant icons
import {EyeOutlined} from '@ant-design/icons';

// compliance components
import ComplianceEntriesTable from '../ComplianceEntriesTable/ComplianceEntriesTable';
import ComplianceFrameworkDetails from '../ComplianceFrameworkDetails/ComplianceFrameworkDetails';

// contract components
import ContractQuestionnaireDetails from '../../../contract/components/ContractQuestionnaireDetails/ContractQuestionnaireDetails';

// file components
import AnalyzingIndicator from '../../../file/components/AnalyzingIndicator/AnalyzingIndicator';

// local components
import AnalyzingContainer from './components/AnalyzingContainer';
import Container from './components/Container';
import OptionGroupTitle from './components/OptionGroupTitle';
import ScoreTagBackground from './components/ScoreTagBackground';
import ScoreTagContainer from './components/ScoreTagContainer';
import ScoreValue from './components/ScoreValue';
import ScoreValueContainer from './components/ScoreValueContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ComplianceAndQuestionnairesDetail = ({
  areFilesBeingProcessed,
  onSelect,
  selectableOptions,
  selectedObject,
  selectedObjectType,
}) => (
  <Container>
    <Select
      value={selectedObject?.optionId || null}
      onChange={onSelect}
      size="large"
      style={{width: '100%'}}
      placeholder="Select a framework or questionnaire to see details"
    >
      {selectableOptions.map((optionGroup) => (
        <Select.OptGroup
          key={optionGroup.id}
          label={
            <ScoreValueContainer>
              {optionGroup.score !== undefined && (
                <ScoreTagContainer>
                  <ScoreTagBackground color={optionGroup.color} />
                  <ScoreValue>Compliance: {optionGroup.score}%</ScoreValue>
                </ScoreTagContainer>
              )}
              {optionGroup.coverage !== undefined && (
                <ScoreTagContainer>
                  <ScoreTagBackground />
                  <ScoreValue>Coverage: {optionGroup.coverage}%</ScoreValue>
                </ScoreTagContainer>
              )}
              <OptionGroupTitle>{optionGroup.label}</OptionGroupTitle>
            </ScoreValueContainer>
          }
        >
          {optionGroup.entries.map((option) => (
            <Select.Option key={option.id} value={option.id}>
              <ScoreValueContainer>
                {option.score !== undefined && (
                  <ScoreTagContainer>
                    <ScoreTagBackground color={option.color} />
                    <ScoreValue>{option.score}%</ScoreValue>
                  </ScoreTagContainer>
                )}
                {option.completeness !== undefined && (
                  <ScoreTagContainer>
                    <ScoreTagBackground />
                    <ScoreValue>{option.completeness}%</ScoreValue>
                  </ScoreTagContainer>
                )}
                {option.label}
                {option.isCustom && <Tag color="#f50">Custom</Tag>}
              </ScoreValueContainer>
            </Select.Option>
          ))}
        </Select.OptGroup>
      ))}
      <Select.Option value="all">
        <ScoreValueContainer>
          <ScoreTagContainer>
            <ScoreTagBackground />
            <ScoreValue>
              <EyeOutlined />
            </ScoreValue>
          </ScoreTagContainer>
          Show all
        </ScoreValueContainer>
      </Select.Option>
    </Select>
    {areFilesBeingProcessed && (
      <AnalyzingContainer>
        <AnalyzingIndicator />
      </AnalyzingContainer>
    )}
    {!selectedObject &&
      !selectedObjectType &&
      selectableOptions.map((optionGroup) => (
        <ComplianceEntriesTable
          entries={[...optionGroup.entries]}
          key={optionGroup.id}
          onSelect={onSelect}
          title={optionGroup.label}
        />
      ))}
    {!!selectedObject &&
      (selectedObjectType === 'compliance' ? (
        <ComplianceFrameworkDetails framework={selectedObject} />
      ) : (
        <ContractQuestionnaireDetails questionnaire={selectedObject} />
      ))}
  </Container>
);

ComplianceAndQuestionnairesDetail.propTypes = {
  areFilesBeingProcessed: PropTypes.bool,
  onSelect: PropTypes.func,
  selectableOptions: PropTypes.array,
  selectedObject: PropTypes.object,
  selectedObjectType: PropTypes.string,
};

export default ComplianceAndQuestionnairesDetail;
