// engagement events
import engagementFileCreatedEvent from '../events/fileCreated.event.engagement';

// file services
import uploadFileService from '../../file/services/upload.service.file';

const uploadEngagementFileService = async ({
  company,
  engagement,
  file,
  service,
}) => {
  const engagementId = engagement.id;
  const serviceId = service?.id || null;
  const createdFile = await uploadFileService({
    company,
    file,
    fileAttributes: {
      engagement_id: engagementId,
      engagement_service_id: serviceId,
    },
  });
  engagementFileCreatedEvent.publish({engagement, file: createdFile, company});
  return createdFile;
};

export default uploadEngagementFileService;
