// currency
import currency from 'currency.js';

const divide = (number, ...numbersToSum) =>
  numbersToSum.reduce(
    (total, numberToSum) => total.divide(numberToSum),
    currency(number)
  ).value;

export default divide;
