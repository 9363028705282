// compliance containers
import EngagementsContainer from '../../containers/EngagementsContainer/EngagementsContainer';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const EngagementPage = () => {
  return (
    <SubPage page={page}>
      <EngagementsContainer />
    </SubPage>
  );
};

export default EngagementPage;
