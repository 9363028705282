// ant icons
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';

// engagement containers
import DueDiligenceDataContainer from '../../containers/DueDiligenceDataContainer/DueDiligenceDataContainer';

// engagement events
import showEngagementDeleteModalEvent from '../../events/showDeleteModal.event.engagement';
import showEngagementUpdateModalEvent from '../../events/showUpdateModal.event.engagement';

// engagement lib
import generateActionCardText from '../../lib/generateActionCardText.lib.engagement';

// engagement redux actions
import {setGeneralEngagementData as setGeneralEngagementDataAction} from '../../redux/reducer.redux.engagement';

// engagementService routes
import engagementServiceDetailRoute from '../../../engagementService/pages/EngagementServiceDetailPage/route';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// react redux
import {connect} from 'react-redux';

// react router
import {Navigate} from 'react-router-dom';

// supplier lib
import getCompanyId from '../../../supplier/lib/getCompanyId.lib.supplier';

class EngagementDetailContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    dispatch: PropTypes.func,
    engagement: PropTypes.object,
    engagementFiles: PropTypes.array,
  };

  state = {
    initialized: false,
    redirect: null,
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = () => {
    const {company, dispatch, engagement} = this.props;
    const services = [...(engagement?.services || [])];
    const [service, ...remainingServices] = services;

    const companyId = getCompanyId(company);

    if (!!service && !remainingServices.length)
      return this.setState({
        redirect: engagementServiceDetailRoute({
          companyId,
          engagementId: engagement.id,
          serviceId: service.id,
        }),
      });

    dispatch(setGeneralEngagementDataAction({service: null, file: null}));
    this.setState({initialized: true});
  };

  showEngagementEditorModal = () => {
    const {company, engagement} = this.props;
    showEngagementUpdateModalEvent.publish({
      companyId: getCompanyId(company),
      engagement,
    });
  };

  showEngagementDeleteModal = () => {
    const {engagement} = this.props;
    showEngagementDeleteModalEvent.publish({engagement});
  };

  actions = () => {
    return [
      {
        key: 'editEngagement',
        label: 'Rename Engagement',
        icon: <EditOutlined />,
        onClick: this.showEngagementEditorModal,
      },
      {
        key: 'deleteEngagement',
        label: 'Close Engagement',
        icon: <DeleteOutlined />,
        danger: true,
        onClick: this.showEngagementDeleteModal,
      },
    ];
  };

  render() {
    const {engagement, engagementFiles} = this.props;
    const {initialized, redirect} = this.state;

    return (
      <Fragment>
        {initialized && (
          <DueDiligenceDataContainer
            complianceChartTitle={engagement?.name}
            layoutProps={{
              actions: this.actions(),
              breadcrumbs: [
                {
                  key: engagement.id,
                  label: engagement.name,
                },
              ],
            }}
            textProps={generateActionCardText({engagement, engagementFiles})}
          />
        )}
        {redirect && <Navigate to={redirect} />}
      </Fragment>
    );
  }
}

export default connect((state) => ({
  company: state.company.company,
  engagement: state.engagement.engagement,
  engagementFiles: state.engagement.engagementFiles,
}))(EngagementDetailContainer);
