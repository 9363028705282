import styled from 'styled-components';

// colors
// import grey200Color from '../../../../layout/colors/grey200.color.layout';

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  ${
    '' /* width: calc(100% - 200px);
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 119px;
  left: 200px;
  padding: 30px;
  z-index: 2;
  background: ${grey200Color}; */
  }
`;

export default Header;
