import styled from 'styled-components';

const Title = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 1em;
  padding-right: 20px;
  padding-top: ${({flat}) => (flat ? '20px' : '0px')};
`;

export default Title;
