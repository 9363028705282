import styled from 'styled-components';

// layout colors
import grey600Color from '../../../../layout/colors/grey600.color.layout';

const ScoreTagBackground = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: ${({color, fade, opacity}) =>
    fade ? `rgba(1, 165, 78, ${opacity})` : color || grey600Color};
`;

export default ScoreTagBackground;
