// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import setGeneralFileDataAction from './actions/setGeneralData.action.redux.file';

// style redux constants
import DEFAULT_FILE_REDUX_STATE from './constants/defaultState.constant.redux.file';

const fileSlice = createSlice({
  name: 'file',
  initialState: DEFAULT_FILE_REDUX_STATE,
  reducers: {
    setGeneralFileData: setGeneralFileDataAction,
  },
});

export const {setGeneralFileData} = fileSlice.actions;

export default fileSlice.reducer;
