// local components
import Container from './components/Container';
import Content from './components/Content';
import Label from './components/Label';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const HeadMenu = ({description, label}) => (
  <Container>
    {!!label && <Label>{label}</Label>}
    {!!description && <Content>{description}</Content>}
  </Container>
);

HeadMenu.propTypes = {
  description: PropTypes.string,
  label: PropTypes.string,
};

export default HeadMenu;
