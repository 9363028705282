// currency
import currency from 'currency.js';

const sum = (number, ...numbersToSum) =>
  numbersToSum.reduce(
    (total, numberToSum) => total.add(numberToSum),
    currency(number)
  ).value;

export default sum;
