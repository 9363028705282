import React from 'react';
import {Table} from 'antd';

const BusinessRelationships = () => {
  const data = [
    {
      key: '1',
      relationshipId: 1450330,
      startDate: '10/23/2024',
      partner: 'Purolator',
      role: 'Client',
      source:
        'https://www.canadianconsultingengineer.com/cima-consults-on-electrification-of-purolators-fleet/',
    },
    {
      key: '2',
      relationshipId: 1163583,
      startDate: '03/30/2023',
      partner: 'Public Works and Government Services Canada, Comtech Group',
      role: 'Client, Vendor',
      source:
        'https://www.canadianconsultingengineer.com/buildings/comtech-and-cima-partner-for-confederation-building-rehabilitation/1003416300/?utm_source=rss&utm_medium=rss&utm_campaign=comtech-and-cima-partner-for-confederation-building-rehabilitation',
    },
    {
      key: '3',
      relationshipId: 1159297,
      startDate: '12/22/2022',
      partner: 'Systemex Industrial Consulting',
      role: 'Partner',
      source:
        'https://www.newswire.ca/news-releases/cima-and-systemex-industries-conseils-announce-strategic-alliance-891890936.html',
    },
    {
      key: '4',
      relationshipId: 1161916,
      startDate: '12/01/2022',
      partner: 'Systemex Industrial Consulting',
      role: 'Partner',
      source:
        'https://www.canadianconsultingengineer.com/companies-people/cima-and-sic-enter-strategic-alliance/1003416260/?utm_source=rss&utm_medium=rss&utm_campaign=cima-and-sic-enter-strategic-alliance',
    },
    {
      key: '5',
      relationshipId: 131647,
      startDate: '12/19/2017',
      partner: 'Canadian Metals',
      role: 'Partner',
      source:
        'http://www.marketwired.com/mw/release.do?id=2243537&sourceType=3',
    },
  ];

  const columns = [
    {
      title: 'Relationship ID',
      dataIndex: 'relationshipId',
      key: 'relationshipId',
    },
    {title: 'Start Date', dataIndex: 'startDate', key: 'startDate'},
    {title: 'Business Partner', dataIndex: 'partner', key: 'partner'},
    {title: 'Role', dataIndex: 'role', key: 'role'},
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          Link
        </a>
      ),
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default BusinessRelationships;
