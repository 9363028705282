// ant components
import {message as messageApi} from 'antd';

// file services
import getDownloadLinkService from '../../services/getDownloadLink.service.file';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

class FileDownloaderContainer extends Component {
  static propTypes = {
    children: PropTypes.func,
    fileId: PropTypes.string,
    fileVersionId: PropTypes.string,
  };

  static defaultProps = {
    children: () => null,
  };

  state = {
    downloading: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  downloadFile = async () => {
    const {fileId, fileVersionId} = this.props;
    const {downloading} = this.state;

    if (downloading) return;

    this.setState({downloading: true});

    try {
      const url = await getDownloadLinkService({fileId, fileVersionId});
      if (!this.mounted || !url) return;
      window.open(url, '_blank');
      this.setState({downloading: false});
    } catch (error) {
      if (!this.mounted) return;
      this.setState({downloading: false});
      messageApi.error(error?.message || 'Download failed');
    }
  };

  render() {
    const {children} = this.props;
    const {downloading} = this.state;
    return children({
      downloading,
      onDownload: this.downloadFile,
    });
  }
}

export default FileDownloaderContainer;
