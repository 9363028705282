// file api
import createFileApi from '../api/create.api.file';
import uploadFileToS3Api from '../api/uploadToS3.api.file';

// file events
import fileCreatedEvent from '../events/created.event.file';

// supplier lib
import getCompanyId from '../../supplier/lib/getCompanyId.lib.supplier';

const uploadFileService = async ({
  company,
  file,
  fileAttributes = {},
  fileCreated = fileCreatedEvent.publish,
}) => {
  const createdFile = await createFileApi({
    company_id: getCompanyId(company),
    name: file.name,
    ...fileAttributes,
  });
  fileCreated({file: createdFile, company});
  await uploadFileToS3Api({
    url: createdFile?.version?.upload_url,
    file,
  });
  return createdFile;
};

export default uploadFileService;
