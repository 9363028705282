import React from 'react';
import {Table} from 'antd';

const LegalSummaries = () => {
  const data = [
    {
      key: '1',
      legalType: 'Returned cheques',
      numberOnFile: 0,
      totalAmount: '-',
      lastItemDate: '-',
      comments: 'No',
    },
    {
      key: '2',
      legalType: 'Collections',
      numberOnFile: 26,
      totalAmount: 'Can$25,865.2',
      lastItemDate: '11/13/2024',
      comments: 'Yes',
    },
    {
      key: '3',
      legalType: 'Legal suits',
      numberOnFile: 37,
      totalAmount: 'Can$37,815,858',
      lastItemDate: '10/03/2024',
      comments: 'Yes',
    },
    {
      key: '4',
      legalType: 'Judgments',
      numberOnFile: 2,
      totalAmount: 'Can$384,701',
      lastItemDate: '02/13/2024',
      comments: 'Yes',
    },
    {
      key: '5',
      legalType: 'Other legal info',
      numberOnFile: 22,
      totalAmount: 'Can$58,643',
      lastItemDate: '05/01/2024',
      comments: 'Yes',
    },
    {
      key: '6',
      legalType: 'Liens',
      numberOnFile: 201,
      totalAmount: 'Can$2,647,870',
      lastItemDate: '09/16/2024',
      comments: 'Yes',
    },
    {
      key: '7',
      legalType: 'Bankruptcy',
      numberOnFile: 0,
      totalAmount: '-',
      lastItemDate: '-',
      comments: 'No',
    },
    {
      key: '8',
      legalType: 'Total Legal',
      numberOnFile: 240,
      totalAmount: 'Can$40,848,429',
      lastItemDate: '-',
      comments: '-',
    },
  ];

  const columns = [
    {title: 'Legal Type', dataIndex: 'legalType', key: 'legalType'},
    {title: 'Number on File', dataIndex: 'numberOnFile', key: 'numberOnFile'},
    {
      title: 'Total Amount on File',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: 'Date of Last Item',
      dataIndex: 'lastItemDate',
      key: 'lastItemDate',
    },
    {title: 'Comments on File', dataIndex: 'comments', key: 'comments'},
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default LegalSummaries;
