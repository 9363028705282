// propTypes
import PropTypes from 'prop-types';

// react
import {useEffect} from 'react';

// react router
import {useNavigate} from 'react-router-dom';

const Redirect = ({url}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!url) return;
    navigate(url);
    // eslint-disable-next-line
  }, [url]);

  return null;
};

Redirect.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Redirect;
