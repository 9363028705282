import styled from 'styled-components';

const ScoreValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 3px 0px;
`;

export default ScoreValueContainer;
