const uploadFileToS3Api = async ({url, file}) => {
  const responseUploadToS3 = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/octet-stream',
      'X-Amz-Acl': 'private',
    },
    body: file,
  });
  if (responseUploadToS3.ok) {
    return responseUploadToS3;
  } else {
    throw new Error('Failed to upload file.');
  }
};

export default uploadFileToS3Api;
