const isCustomAnsysFindingResponse = (content) => {
  try {
    const cleanedResponse = content.split('NaN, ').join('null, ');
    JSON.parse(cleanedResponse);
    return true;
  } catch (error) {
    return false;
  }
};

export default isCustomAnsysFindingResponse;
