// ant components
import {Card} from 'antd';

// file components
import InlineFileDownloader from '../../../file/components/InlineFileDownloader/InlineFileDownloader';

// local components
import Container from './components/Container';
import Content from './components/Content';
import File from './components/File';
import Row from './components/Row';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ContractQuestionCard = ({
  answer,
  coverage,
  explanation,
  file,
  question,
  questionId,
  reference,
}) => {
  return (
    <Card>
      <Container>
        {!!questionId && (
          <Row>
            <Title>Question ID</Title>
            <Content>{questionId}</Content>
          </Row>
        )}
        {!!question && (
          <Row>
            <Title>Question</Title>
            <Content bold dangerouslySetInnerHTML={{__html: question}} />
          </Row>
        )}
        {!!answer && (
          <Row>
            <Title>Answer</Title>
            <Content dangerouslySetInnerHTML={{__html: answer}} />
          </Row>
        )}
        {!!explanation && (
          <Row>
            <Title>Explanation</Title>
            <Content dangerouslySetInnerHTML={{__html: explanation}} />
          </Row>
        )}
        <Row>
          <Title>Coverage</Title>
          <Content>{coverage}%</Content>
        </Row>
        {!!reference && (
          <Row>
            <Title>Reference</Title>
            <Content dangerouslySetInnerHTML={{__html: reference}} />
          </Row>
        )}
        {!!file && (
          <Row>
            <Title>File</Title>
            <Content>
              <File>
                <InlineFileDownloader
                  fileId={file.fileId}
                  fileVersionId={file.fileVersionId}
                >
                  {file.fileName}
                </InlineFileDownloader>
              </File>
            </Content>
          </Row>
        )}
      </Container>
    </Card>
  );
};

ContractQuestionCard.propTypes = {
  answer: PropTypes.string,
  coverage: PropTypes.number,
  explanation: PropTypes.string,
  file: PropTypes.object,
  question: PropTypes.string,
  questionId: PropTypes.string,
  reference: PropTypes.string,
};

export default ContractQuestionCard;
