import React from 'react';
import {Table} from 'antd';

const MostRecentFilings = () => {
  const data = [
    {
      key: '1',
      legalFiling: 'Legal',
      dateFiled: '11/13/2024',
      filingType: 'Legal Suit',
      caseNumber: '029077152-# ENREG.',
      status: '-',
      releasedDate: '-',
    },
    {
      key: '2',
      legalFiling: 'Legal',
      dateFiled: '10/16/2024',
      filingType: 'Legal Suit',
      caseNumber: 'SCS22905',
      status: '-',
      releasedDate: '-',
    },
    {
      key: '3',
      legalFiling: 'Legal',
      dateFiled: '09/26/2024',
      filingType: 'Legal Suit',
      caseNumber: '012944213',
      status: '-',
      releasedDate: '-',
    },
    {
      key: '4',
      legalFiling: 'Legal',
      dateFiled: '08/07/2024',
      filingType: 'Legal Suit',
      caseNumber: '020737242',
      status: '-',
      releasedDate: '-',
    },
    {
      key: '5',
      legalFiling: 'Legal',
      dateFiled: '05/06/2024',
      filingType: 'Legal Suit',
      caseNumber: '036137248',
      status: '-',
      releasedDate: '-',
    },
  ];

  const columns = [
    {title: 'Legal Filing', dataIndex: 'legalFiling', key: 'legalFiling'},
    {title: 'Date Filed', dataIndex: 'dateFiled', key: 'dateFiled'},
    {title: 'Filing Type', dataIndex: 'filingType', key: 'filingType'},
    {title: 'Case Number', dataIndex: 'caseNumber', key: 'caseNumber'},
    {title: 'Status', dataIndex: 'status', key: 'status'},
    {title: 'Released Date', dataIndex: 'releasedDate', key: 'releasedDate'},
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default MostRecentFilings;
