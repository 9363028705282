import React from 'react';
import {Table} from 'antd';

const IndustryBreakdown = () => {
  const data = [
    {
      key: '1',
      industry: 'Wholesale Trade',
      tradeLines: 1,
      current: 'Can$70',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      total: 'Can$70',
    },
    {
      key: '2',
      industry: 'Services',
      tradeLines: 1,
      current: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      total: 'Can$0',
    },
  ];

  const columns = [
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: 'Trade Lines',
      dataIndex: 'tradeLines',
      key: 'tradeLines',
    },
    {
      title: 'Current',
      dataIndex: 'current',
      key: 'current',
    },
    {
      title: 'Period 1',
      dataIndex: 'period1',
      key: 'period1',
    },
    {
      title: 'Period 2',
      dataIndex: 'period2',
      key: 'period2',
    },
    {
      title: 'Period 3',
      dataIndex: 'period3',
      key: 'period3',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default IndustryBreakdown;
