import React from 'react';
import {Table} from 'antd';

const IndustryTradeSummary = () => {
  const data = [
    {
      key: '1',
      period: '90 Day',
      piScore: '-',
      creditReferences: 0,
      highCredit: 'Can$0',
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
    {
      key: '2',
      period: '13 Month',
      piScore: '-',
      creditReferences: 1,
      highCredit: 'Can$8.7K',
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
    {
      key: '3',
      period: 'All References',
      piScore: '99',
      creditReferences: 3,
      highCredit: 'Can$95.3K',
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$729',
      totalOwing: 'Can$729',
    },
  ];

  const columns = [
    {title: 'Period', dataIndex: 'period', key: 'period'},
    {title: 'PI Score', dataIndex: 'piScore', key: 'piScore'},
    {
      title: 'Credit References',
      dataIndex: 'creditReferences',
      key: 'creditReferences',
    },
    {title: 'High Credit', dataIndex: 'highCredit', key: 'highCredit'},
    {title: 'Current Owing', dataIndex: 'currentOwing', key: 'currentOwing'},
    {title: 'Period 1', dataIndex: 'period1', key: 'period1'},
    {title: 'Period 2', dataIndex: 'period2', key: 'period2'},
    {title: 'Period 3', dataIndex: 'period3', key: 'period3'},
    {title: 'Total Owing', dataIndex: 'totalOwing', key: 'totalOwing'},
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default IndustryTradeSummary;
