// ant components
import {Tour} from 'antd';

// lib
import wait from '../../../lib/wait';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, createRef, Fragment} from 'react';

// tour storages
import walkthroughStorageGenerator from '../../storages/walkthrough.storage.tour';

class WalkthroughContainer extends Component {
  static propTypes = {
    autoStart: PropTypes.bool,
    autoStartIn: PropTypes.number,
    children: PropTypes.func,
    continuousDisplay: PropTypes.bool,
    steps: PropTypes.array.isRequired,
    tourProps: PropTypes.object,
    walkthroughName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    autoStart: false,
    autoStartIn: 800,
    children: () => null,
    continuousDisplay: false,
    tourProps: {},
  };

  state = {
    initialized: false,
    visible: false,
  };

  domReferences = {};
  steps = [];
  walkthroughStorage = null;

  componentDidMount() {
    this.initializeTour();
  }

  initializeTour = async () => {
    const {autoStart, autoStartIn, continuousDisplay, walkthroughName} =
      this.props;
    this.walkthroughStorage = walkthroughStorageGenerator(walkthroughName);
    const wasViewed = this.walkthroughStorage.get() === 'true';
    this.generateSteps();
    this.setState({initialized: true});
    if (!autoStart || (!continuousDisplay && wasViewed)) return;
    await wait(autoStartIn);
    this.startTour();
  };

  generateSteps = () => {
    const {steps} = this.props;
    const preparedSteps = [...steps].map((step) => {
      const domReference = createRef();
      const stepName = step?.step || step?.title?.trim?.()?.toLowerCase?.();
      const domName = step.domName || stepName;
      return {
        ...step,
        step: stepName,
        domName,
        domReference,
        target: () => domReference.current,
      };
    });
    const domReferences = [...preparedSteps].reduce(
      (combined, step) => ({
        ...combined,
        [step.domName]: step.domReference,
      }),
      {}
    );

    this.steps = preparedSteps;
    this.domReferences = domReferences;
  };

  startTour = async ({openIn = 0, checkIfViewed = false} = {}) => {
    await wait(openIn);
    const wasViewed = this.walkthroughStorage.get() === 'true';
    if (checkIfViewed && wasViewed) return;
    this.setState({visible: true});
  };

  completeTour = () => {
    const {continuousDisplay} = this.props;
    this.setState({visible: false});
    if (continuousDisplay) return;
    this.walkthroughStorage.add('true');
  };

  render() {
    const {children, tourProps} = this.props;
    const {initialized, visible} = this.state;
    return initialized ? (
      <Fragment>
        {children({
          domReferences: this.domReferences,
          onStartTour: this.startTour,
          tourVisible: visible,
        })}
        <Tour
          disabledInteraction
          {...tourProps}
          open={visible}
          onClose={this.completeTour}
          steps={this.steps}
        />
      </Fragment>
    ) : null;
  }
}

export default WalkthroughContainer;
