import React from 'react';
import {Table} from 'antd';
import Highcharts from 'highcharts';

// uuid
import {v4} from 'uuid';
import {useEffect} from 'react';

const TradePaymentsByCreditExtended = () => {
  const id = v4();
  const tableData = [
    {
      key: '1',
      creditExtended: 'Over $1M',
      tradeLines: '-',
      totalAmount: '-',
      pastDue: '-',
      withinTerms: '-',
    },
    {
      key: '2',
      creditExtended: '$100K - $1M',
      tradeLines: 5,
      totalAmount: 'Can$1.1M',
      pastDue: 'Can$668K',
      withinTerms: '100%',
    },
    {
      key: '3',
      creditExtended: '$50K - $100K',
      tradeLines: 4,
      totalAmount: 'Can$318.5K',
      pastDue: 'Can$21.4K',
      withinTerms: '100%',
    },
    {
      key: '4',
      creditExtended: '$25K - $50K',
      tradeLines: 7,
      totalAmount: 'Can$242.5K',
      pastDue: 'Can$86.9K',
      withinTerms: '100%',
    },
    {
      key: '5',
      creditExtended: '$5K - $25K',
      tradeLines: 20,
      totalAmount: 'Can$279K',
      pastDue: 'Can$198.9K',
      withinTerms: '100%',
    },
    {
      key: '6',
      creditExtended: '$1K - $5K',
      tradeLines: 27,
      totalAmount: 'Can$58.9K',
      pastDue: 'Can$104.9K',
      withinTerms: '100%',
    },
    {
      key: '7',
      creditExtended: 'Under $1K',
      tradeLines: 204,
      totalAmount: 'Can$14.2K',
      pastDue: 'Can$447.7K',
      withinTerms: '100%',
    },
  ];

  const columns = [
    {
      title: 'Credit Extended',
      dataIndex: 'creditExtended',
      key: 'creditExtended',
    },
    {
      title: 'Trade Lines',
      dataIndex: 'tradeLines',
      key: 'tradeLines',
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: 'Past Due',
      dataIndex: 'pastDue',
      key: 'pastDue',
    },
    {
      title: 'Within Terms',
      dataIndex: 'withinTerms',
      key: 'withinTerms',
    },
  ];

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: [
          'Over $1M',
          '$100K - $1M',
          '$50K - $100K',
          '$25K - $50K',
          '$5K - $25K',
          '$1K - $5K',
          'Under $1K',
        ],
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Within Terms (%)',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
      },
      series: [
        {
          name: 'Within Terms (%)',
          data: [0, 100, 100, 100, 100, 100, 100],
          color: '#007BFF',
        },
      ],
    };
    Highcharts.chart(id, chartOptions);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        width: '100%',
      }}
    >
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        style={{flex: 1}}
      />
      <div style={{flex: 1}}>
        <div id={id} />
      </div>
    </div>
  );
};

export default TradePaymentsByCreditExtended;
