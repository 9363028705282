import styled from 'styled-components';

const ScoreTagBackground = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: ${({color}) => color};
`;

export default ScoreTagBackground;
