// engagementService api
import updateEngagementServiceApi from '../api/update.api.engagementService';

// engagementService events
import engagementServiceDeletedEvent from '../events/deleted.event.engagementService';

const deleteEngagementServiceService = async ({
  engagementId,
  engagementServiceId,
}) => {
  const engagement = await updateEngagementServiceApi(
    engagementId,
    engagementServiceId,
    {
      is_active: false,
    }
  );

  const engagementService = {...engagement.service};

  engagementServiceDeletedEvent.publish({engagementService});

  return engagementService;
};

export default deleteEngagementServiceService;
