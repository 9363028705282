// firmographic components
import FirmographicsLayout from '../../../firmographic/components/FirmographicsLayout/FirmographicsLayout';

// propType
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class FirmographicsContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    dispatch: PropTypes.func,
  };

  render() {
    return <FirmographicsLayout />;
  }
}

export default connect((state) => ({company: state.company.company}))(
  FirmographicsContainer
);
