import React from 'react';
import {Table} from 'antd';
import Highcharts from 'highcharts';
import {v4} from 'uuid';
import {useEffect} from 'react';

const HighCreditUtilizationWithChart = () => {
  const id = v4();
  const tableData = [
    {
      key: '1',
      band: '0 - 25%',
      balance: 'Can$1,425',
      highCredit: 'Can$442,446',
      percentage: '0.32%',
    },
    {
      key: '2',
      band: '25 - 50%',
      balance: '-',
      highCredit: '-',
      percentage: '-',
    },
    {
      key: '3',
      band: '50 - 75%',
      balance: 'Can$40,391',
      highCredit: 'Can$63,581',
      percentage: '63.53%',
    },
    {
      key: '4',
      band: '75% +',
      balance: 'Can$54,001',
      highCredit: 'Can$54,001',
      percentage: '100.00%',
    },
    {
      key: '5',
      band: 'Total',
      balance: 'Can$95,817',
      highCredit: 'Can$560,028',
      percentage: '17.11%',
    },
  ];

  const columns = [
    {
      title: 'Band',
      dataIndex: 'band',
      key: 'band',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: 'High Credit',
      dataIndex: 'highCredit',
      key: 'highCredit',
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
    },
  ];

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: 'bar',
      },
      title: {
        text: 'Comparison Chart',
      },
      xAxis: {
        categories: ['0 - 25%', '25 - 50%', '50 - 75%', '75% +'],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Amount',
        },
      },
      series: [
        {
          name: 'High Credit',
          data: [442446, 0, 63581, 54001],
          color: '#FFA500',
        },
        {
          name: 'Balance',
          data: [1425, 0, 40391, 54001],
          color: '#007BFF',
        },
      ],
    };
    Highcharts.chart(id, chartOptions);
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{display: 'flex', gap: '16px', flexWrap: 'wrap'}}>
      <div style={{flex: 1}}>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <div style={{flex: 1}}>
        <div id={id} />
      </div>
    </div>
  );
};

export default HighCreditUtilizationWithChart;
