// engagement lib
import getFiles from './getFiles.lib.engagement';

const areFilesBeingProcessed = ({
  files,
  engagement,
  service,
  uploadingFiles,
  analyzingFiles,
}) => {
  const filteredFiles = getFiles({files, engagement, service});
  return filteredFiles.some(
    (file) =>
      uploadingFiles.includes(file.id) || analyzingFiles.includes(file.id)
  );
};

export default areFilesBeingProcessed;
