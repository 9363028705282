import React from 'react';
import {Table} from 'antd';
import Highcharts from 'highcharts';

// uuid
import {v4} from 'uuid';
import {useEffect} from 'react';

const HighCreditUtilizationWithChart = () => {
  const id = v4();
  const tableData = [
    {
      key: '1',
      band: '0 - 25%',
      balance: 'Can$226,424',
      highCredit: 'Can$4,348,265',
      percentage: '5.21%',
    },
    {
      key: '2',
      band: '25 - 50%',
      balance: 'Can$93,230',
      highCredit: 'Can$235,556',
      percentage: '39.58%',
    },
    {
      key: '3',
      band: '50 - 75%',
      balance: 'Can$495,775',
      highCredit: 'Can$746,646',
      percentage: '66.40%',
    },
    {
      key: '4',
      band: '75% +',
      balance: 'Can$1,148,856',
      highCredit: 'Can$1,198,201',
      percentage: '95.88%',
    },
    {
      key: '5',
      band: 'Total',
      balance: 'Can$1,964,285',
      highCredit: 'Can$6,528,668',
      percentage: '30.09%',
    },
  ];

  const columns = [
    {
      title: 'Band',
      dataIndex: 'band',
      key: 'band',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: 'High Credit',
      dataIndex: 'highCredit',
      key: 'highCredit',
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
    },
  ];

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: 'bar',
      },
      title: {
        text: 'Comparison Chart',
      },
      xAxis: {
        categories: ['0 - 25%', '25 - 50%', '50 - 75%', '75% +'],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Amount',
        },
      },
      series: [
        {
          name: 'High Credit',
          data: [4348265, 235556, 746646, 1198201],
          color: '#FFA500',
        },
        {
          name: 'Balance',
          data: [226424, 93230, 495775, 1148856],
          color: '#007BFF',
        },
      ],
    };
    Highcharts.chart(id, chartOptions);
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{display: 'flex', gap: '16px', flexWrap: 'wrap'}}>
      <div style={{flex: 1}}>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <div style={{flex: 1}}>
        <div id={id} />
      </div>
    </div>
  );
};

export default HighCreditUtilizationWithChart;
