// data lib
import getDefinedOrNullValue from '../../data/lib/getDefinedOrNullValue.lib.data';

// financial dataMappers
import assetsDataMap from '../dataMappers/assets.dataMapper.financial';
import assetUtilizationDataMap from '../dataMappers/assetUtilization.dataMapper.financial';
import capitalStructureDataMap from '../dataMappers/capitalStructure.dataMapper.financial';
import cashFlowDataMap from '../dataMappers/cashFlow.dataMapper.financial';
import growthRatesDataMap from '../dataMappers/growthRates.dataMapper.financial';
import incomeStatementDataMap from '../dataMappers/incomeStatement.dataMapper.financial';
import keyPerformanceIndicatorsDataMap from '../dataMappers/keyPerformanceIndicators.dataMapper.financial';
import liabilitiesDataMap from '../dataMappers/liabilities.dataMapper.financial';
import liquidityRatiosDataMap from '../dataMappers/liquidityRatios.dataMapper.financial';
import profitabilityRatiosDataMap from '../dataMappers/profitabilityRatios.dataMapper.financial';
import strategicRiskItemsDataMap from '../dataMappers/strategicRiskItems.dataMapper.financial';

const mapFinancials = (company) => {
  const currency =
    company?.financials?.report?.companySummary?.creditRating?.creditLimit
      ?.currency ||
    company?.financials?.report?.companySummary?.latestTurnoverFigure
      ?.currency ||
    company?.financials?.report?.companySummary?.latestShareholdersEquityFigure
      ?.currency ||
    null;

  const creditSafeRiskScore = getDefinedOrNullValue(
    company?.financials?.report?.companySummary?.creditRating?.providerValue
      ?.value
  );

  const riskScore =
    creditSafeRiskScore >= 1000
      ? ((creditSafeRiskScore - 1000) / (1722 - 1000)) * 100
      : creditSafeRiskScore;

  return {
    currency,
    riskScore,
    internationalScore: getDefinedOrNullValue(
      company?.financials?.report?.companySummary?.creditRating?.commonValue
    ),
    creditLimit: getDefinedOrNullValue(
      company?.financials?.report?.companySummary?.creditRating?.creditLimit
        ?.value
    ),
    judgmentFilings: getDefinedOrNullValue(
      company?.financials?.report?.negativeInformation?.legalFilingSummary
        ?.judgmentFilings
    ),
    judgmentSum: getDefinedOrNullValue(
      company?.financials?.report?.negativeInformation?.legalFilingSummary?.sum
        ?.value
    ),
    possibleOfac: getDefinedOrNullValue(
      company?.financials?.report?.negativeInformation?.possibleOfac
    ),
    dbt: getDefinedOrNullValue(
      company?.financials?.report?.paymentData?.dbt?.dbt
    ),
    paymentExperiences: !!company?.financials?.report?.additionalInformation
      ?.extendedPaymentData?.paidTradePaymentInformation
      ? Object.entries(
          company?.financials?.report?.additionalInformation
            ?.extendedPaymentData?.paidTradePaymentInformation
        ).reduce(
          (combined, [key, value]) =>
            key.startsWith('invoicesPaid') ? combined + value : combined,
          0
        )
      : null,
    paymentTrend: 50,
    inquiriesTrend: 50,
    tradePayment: {
      totalTradeLines: getDefinedOrNullValue(
        company?.financials?.report?.paymentData?.tradeLinesInformation?.total
          ?.number
      ),
      values: {
        categories: [
          {
            key: 'status',
            label: 'Status',
          },
          {
            key: 'current',
            label: 'Current',
          },
          {
            key: '1to30',
            label: '1 - 30',
          },
          {
            key: '31to60',
            label: '31 - 60',
          },
          {
            key: '61to90',
            label: '61 - 90',
          },
          {
            key: '91plus',
            label: '91 +',
          },
          {
            key: 'total',
            label: 'Total',
          },
        ],
        statuses: [
          {
            status: 'Outstanding',
            current: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.paymentData?.tradeLinesInformation
                  ?.total?.current
              ),
              type: 'currency',
            },
            '1to30': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.paymentData?.tradeLinesInformation
                  ?.total?.range1to30
              ),
              type: 'currency',
            },
            '31to60': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.paymentData?.tradeLinesInformation
                  ?.total?.range31to60
              ),
              type: 'currency',
            },
            '61to90': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.paymentData?.tradeLinesInformation
                  ?.total?.range61to90
              ),
              type: 'currency',
            },
            '91plus': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.paymentData?.tradeLinesInformation
                  ?.total?.range91plus
              ),
              type: 'currency',
            },
            total: {
              value: !!company?.financials?.report?.paymentData
                ?.tradeLinesInformation?.total
                ? Object.entries(
                    company?.financials?.report?.paymentData
                      ?.tradeLinesInformation?.total
                  ).reduce(
                    (combined, [key, value]) =>
                      key === 'current' || key.startsWith('range')
                        ? combined + value
                        : combined,
                    0
                  )
                : null,
              type: 'currency',
            },
          },
          {
            status: 'Payment Experience Value',
            current: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidTradePaymentInformation
                  ?.invoicesValuePaidOnTime
              ),
              type: 'currency',
            },
            '1to30': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidTradePaymentInformation
                  ?.invoicesValuePaid1To30Late
              ),
              type: 'currency',
            },
            '31to60': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidTradePaymentInformation
                  ?.invoicesValuePaid31To60Late
              ),
              type: 'currency',
            },
            '61to90': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidTradePaymentInformation
                  ?.invoicesValuePaid61To90Late
              ),
              type: 'currency',
            },
            '91plus': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidTradePaymentInformation
                  ?.invoicesValuePaid91PlusLate
              ),
              type: 'currency',
            },
            total: {
              value: !!company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.paidTradePaymentInformation
                ? Object.entries(
                    company?.financials?.report?.additionalInformation
                      ?.extendedPaymentData?.paidTradePaymentInformation
                  ).reduce(
                    (combined, [key, value]) =>
                      key.startsWith('invoicesValuePaid')
                        ? combined + value
                        : combined,
                    0
                  )
                : null,
              type: 'currency',
            },
          },
          {
            status: 'Payment Experiences',
            current: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidTradePaymentInformation
                  ?.invoicesPaidOnTime
              ),
              type: 'integer',
            },
            '1to30': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidTradePaymentInformation
                  ?.invoicesPaid1To30Late
              ),
              type: 'integer',
            },
            '31to60': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidTradePaymentInformation
                  ?.invoicesPaid31To60Late
              ),
              type: 'integer',
            },
            '61to90': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidTradePaymentInformation
                  ?.invoicesPaid61To90Late
              ),
              type: 'integer',
            },
            '91plus': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidTradePaymentInformation
                  ?.invoicesPaid91PlusLate
              ),
              type: 'integer',
            },
            total: {
              value: !!company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.paidTradePaymentInformation
                ? Object.entries(
                    company?.financials?.report?.additionalInformation
                      ?.extendedPaymentData?.paidTradePaymentInformation
                  ).reduce(
                    (combined, [key, value]) =>
                      key.startsWith('invoicesPaid')
                        ? combined + value
                        : combined,
                    0
                  )
                : null,
              type: 'integer',
            },
          },
        ],
      },
    },
    dbtHistory: !!company?.financials?.report?.paymentData?.dbtHistory
      ? {
          dates: [...company?.financials?.report?.paymentData?.dbtHistory].map(
            ({date}) => date
          ),
          sections: [
            {
              section: 'dbt',
              label: 'DBT',
            },
            {
              section: 'industryDBT',
              label: 'Industry Average',
            },
          ].map((section) => ({
            ...section,
            ...[...company?.financials?.report?.paymentData?.dbtHistory].reduce(
              (combined, {date, ...history}) => ({
                ...combined,
                [date]: {value: history[section.section], type: 'integer'},
              }),
              {}
            ),
          })),
        }
      : null,
    tradePaymentsByCreditExtended: {
      categories: [
        {
          key: 'creditExtended',
          label: 'Credit Extended',
        },
        {
          key: 'tradeLinesWithOutstandingBalance',
          label: 'Trade Lines With Outstanding Balance',
        },
        {
          key: 'tradeLinesPaid',
          label: 'Trade Lines Paid',
        },
        {
          key: 'totalAmountOutstanding',
          label: 'Total Amount Outstanding',
        },
        {
          key: 'totalAmountPaid',
          label: 'Total Amount Paid',
        },
        {
          key: 'outstandingPastDue',
          label: 'Outstanding Past Due',
        },
        {
          key: 'paidPastDue',
          label: 'Paid Past Due',
        },
      ],
      entries: [
        {
          creditExtended: 'Over $1M',
          tradeLinesWithOutstandingBalance: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.creditOver1m?.tradeLines
            ),
            type: 'integer',
          },
          tradeLinesPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.creditOver1m?.tradeLines
            ),
            type: 'integer',
          },
          totalAmountOutstanding: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.creditOver1m?.totalAmount
            ),
            type: 'currency',
          },
          totalAmountPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.creditOver1m?.totalAmount
            ),
            type: 'currency',
          },
          outstandingPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.creditOver1m?.pastDue
            ),
            type: 'currency',
          },
          paidPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.creditOver1m?.pastDue
            ),
            type: 'currency',
          },
        },
        {
          creditExtended: '$100K - $1M',
          tradeLinesWithOutstandingBalance: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit100kTo1m?.tradeLines
            ),
            type: 'integer',
          },
          tradeLinesPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit100kTo1m?.tradeLines
            ),
            type: 'integer',
          },
          totalAmountOutstanding: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit100kTo1m?.totalAmount
            ),
            type: 'currency',
          },
          totalAmountPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit100kTo1m?.totalAmount
            ),
            type: 'currency',
          },
          outstandingPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit100kTo1m?.pastDue
            ),
            type: 'currency',
          },
          paidPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit100kTo1m?.pastDue
            ),
            type: 'currency',
          },
        },
        {
          creditExtended: '$50K - $100K',
          tradeLinesWithOutstandingBalance: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit50kTo100k?.tradeLines
            ),
            type: 'integer',
          },
          tradeLinesPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit50kTo100k?.tradeLines
            ),
            type: 'integer',
          },
          totalAmountOutstanding: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit50kTo100k?.totalAmount
            ),
            type: 'currency',
          },
          totalAmountPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit50kTo100k?.totalAmount
            ),
            type: 'currency',
          },
          outstandingPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit50kTo100k?.pastDue
            ),
            type: 'currency',
          },
          paidPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit50kTo100k?.pastDue
            ),
            type: 'currency',
          },
        },
        {
          creditExtended: '$25K - $50K',
          tradeLinesWithOutstandingBalance: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit25kTo50k?.tradeLines
            ),
            type: 'integer',
          },
          tradeLinesPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit25kTo50k?.tradeLines
            ),
            type: 'integer',
          },
          totalAmountOutstanding: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit25kTo50k?.totalAmount
            ),
            type: 'currency',
          },
          totalAmountPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit25kTo50k?.totalAmount
            ),
            type: 'currency',
          },
          outstandingPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit25kTo50k?.pastDue
            ),
            type: 'currency',
          },
          paidPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit25kTo50k?.pastDue
            ),
            type: 'currency',
          },
        },
        {
          creditExtended: '$5K - $25K',
          tradeLinesWithOutstandingBalance: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit5kTo25k?.tradeLines
            ),
            type: 'integer',
          },
          tradeLinesPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit5kTo25k?.tradeLines
            ),
            type: 'integer',
          },
          totalAmountOutstanding: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit5kTo25k?.totalAmount
            ),
            type: 'currency',
          },
          totalAmountPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit5kTo25k?.totalAmount
            ),
            type: 'currency',
          },
          outstandingPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit5kTo25k?.pastDue
            ),
            type: 'currency',
          },
          paidPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit5kTo25k?.pastDue
            ),
            type: 'currency',
          },
        },
        {
          creditExtended: '$1K - $5K',
          tradeLinesWithOutstandingBalance: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit1kTo5k?.tradeLines
            ),
            type: 'integer',
          },
          tradeLinesPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit1kTo5k?.tradeLines
            ),
            type: 'integer',
          },
          totalAmountOutstanding: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit1kTo5k?.totalAmount
            ),
            type: 'currency',
          },
          totalAmountPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit1kTo5k?.totalAmount
            ),
            type: 'currency',
          },
          outstandingPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.credit1kTo5k?.pastDue
            ),
            type: 'currency',
          },
          paidPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.credit1kTo5k?.pastDue
            ),
            type: 'currency',
          },
        },
        {
          creditExtended: 'Under $1K',
          tradeLinesWithOutstandingBalance: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.creditUnder1k?.tradeLines
            ),
            type: 'integer',
          },
          tradeLinesPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.creditUnder1k?.tradeLines
            ),
            type: 'integer',
          },
          totalAmountOutstanding: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.creditUnder1k?.totalAmount
            ),
            type: 'currency',
          },
          totalAmountPaid: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.creditUnder1k?.totalAmount
            ),
            type: 'currency',
          },
          outstandingPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsByCreditExtended
                ?.creditUnder1k?.pastDue
            ),
            type: 'currency',
          },
          paidPastDue: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.tradePaymentsPaidByCreditExtended
                ?.creditUnder1k?.pastDue
            ),
            type: 'currency',
          },
        },
      ],
    },
    highCreditBalances: {
      values: {
        categories: [
          {
            key: 'entry',
            label: '',
          },
          {
            key: 'current',
            label: 'Current',
          },
          {
            key: '1to30',
            label: '1 - 30',
          },
          {
            key: '31to60',
            label: '31 - 60',
          },
          {
            key: '61to90',
            label: '61 - 90',
          },
          {
            key: '91plus',
            label: '91 +',
          },
        ],
        entries: [
          {
            entry: 'Single Trade Lines',
            current: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances
                  ?.singleBalanceCurrent
              ),
              type: 'shortCurrency',
            },
            '1to30': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances?.singleBalance130
              ),
              type: 'shortCurrency',
            },
            '31to60': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances?.singleBalance3160
              ),
              type: 'shortCurrency',
            },
            '61to90': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances?.singleBalance6190
              ),
              type: 'shortCurrency',
            },
            '91plus': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances?.singleBalance91Plus
              ),
              type: 'shortCurrency',
            },
          },
          {
            entry: 'Trade Lines',
            current: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances
                  ?.tradeLinesBalanceCurrent
              ),
              type: 'shortCurrency',
            },
            '1to30': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances
                  ?.tradeLinesBalance130
              ),
              type: 'shortCurrency',
            },
            '31to60': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances
                  ?.tradeLinesBalance3160
              ),
              type: 'shortCurrency',
            },
            '61to90': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances
                  ?.tradeLinesBalance6190
              ),
              type: 'shortCurrency',
            },
            '91plus': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances
                  ?.tradeLinesBalance91Plus
              ),
              type: 'shortCurrency',
            },
          },
          {
            entry: 'Average',
            current: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances
                  ?.averageBalanceCurrent
              ),
              type: 'shortCurrency',
            },
            '1to30': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances?.averageBalance130
              ),
              type: 'shortCurrency',
            },
            '31to60': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances?.averageBalance3160
              ),
              type: 'shortCurrency',
            },
            '61to90': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances?.averageBalance6190
              ),
              type: 'shortCurrency',
            },
            '91plus': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.highCreditBalances
                  ?.averageBalance91Plus
              ),
              type: 'shortCurrency',
            },
          },
        ],
      },
    },
    lifetimeCreditBalances: {
      values: {
        categories: [
          {
            key: 'entry',
            label: '',
          },
          {
            key: '3MonthHigh',
            label: '3 Month High',
          },
          {
            key: '6MonthHigh',
            label: '6 Month High',
          },
          {
            key: '12MonthHigh',
            label: '12 Month High',
          },
          {
            key: 'lifetimeHigh',
            label: 'Lifetime High',
          },
        ],
        entries: [
          {
            entry: 'Single Trade Lines',
            '3MonthHigh': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.lifetimeHighBalanceChart?.high3Month
                  ?.singleHighBalance
              ),
              type: 'shortCurrency',
            },
            '6MonthHigh': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.lifetimeHighBalanceChart?.high6Month
                  ?.singleHighBalance
              ),
              type: 'shortCurrency',
            },
            '12MonthHigh': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.lifetimeHighBalanceChart?.high12Month
                  ?.singleHighBalance
              ),
              type: 'shortCurrency',
            },
            lifetimeHigh: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.lifetimeHighBalanceChart?.highLifetime
                  ?.singleHighBalance
              ),
              type: 'shortCurrency',
            },
          },
          {
            entry: 'Trade Lines',
            '3MonthHigh': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.lifetimeHighBalanceChart?.high3Month
                  ?.tradeLinesHighBalance
              ),
              type: 'shortCurrency',
            },
            '6MonthHigh': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.lifetimeHighBalanceChart?.high6Month
                  ?.tradeLinesHighBalance
              ),
              type: 'shortCurrency',
            },
            '12MonthHigh': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.lifetimeHighBalanceChart?.high12Month
                  ?.tradeLinesHighBalance
              ),
              type: 'shortCurrency',
            },
            lifetimeHigh: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.lifetimeHighBalanceChart?.highLifetime
                  ?.tradeLinesHighBalance
              ),
              type: 'shortCurrency',
            },
          },
          {
            entry: 'Average',
            '3MonthHigh': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.lifetimeHighBalanceChart?.high3Month
                  ?.averageHighBalance
              ),
              type: 'shortCurrency',
            },
            '6MonthHigh': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.lifetimeHighBalanceChart?.high6Month
                  ?.averageHighBalance
              ),
              type: 'shortCurrency',
            },
            '12MonthHigh': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.lifetimeHighBalanceChart?.high12Month
                  ?.averageHighBalance
              ),
              type: 'shortCurrency',
            },
            lifetimeHigh: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.lifetimeHighBalanceChart?.highLifetime
                  ?.averageHighBalance
              ),
              type: 'shortCurrency',
            },
          },
        ],
      },
    },
    pastDueBalances: {
      categories: [
        {
          key: 'entry',
          label: '',
        },
        {
          key: 'singleHigh',
          label: 'Single High',
        },
        {
          key: 'singleHighSevere',
          label: 'Single High (60 + Days)',
        },
        {
          key: 'highBalance',
          label: 'High Balance',
        },
        {
          key: 'highBalanceSevere',
          label: 'High Balance (60 + Days)',
        },
        {
          key: 'totalBalancePast',
          label: 'Total Balance Past',
        },
        {
          key: 'totalBalancePastSevere',
          label: 'Total Balance Past (60 + Days)',
        },
      ],
      entries: [
        {
          entry: 'Lifetime',
          singleHigh: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.pastDueBalances?.pastDueBalance
                ?.highSingleLifetime
            ),
            type: 'shortCurrency',
          },
          singleHighSevere: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.pastDueBalances?.pastDueBalance
                ?.highSingleSevereLifetime
            ),
            type: 'shortCurrency',
          },
          highBalance: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.pastDueBalances?.pastDueBalance
                ?.highBalanceLifetime
            ),
            type: 'shortCurrency',
          },
          highBalanceSevere: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.pastDueBalances?.pastDueBalance
                ?.highBalanceSevereLifetime
            ),
            type: 'shortCurrency',
          },
          totalBalancePast: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.pastDueBalances?.pastDueBalance
                ?.totalBalanceLifetime
            ),
            type: 'shortCurrency',
          },
          totalBalancePastSevere: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.pastDueBalances?.pastDueBalance
                ?.totalBalanceSevereLifetime
            ),
            type: 'shortCurrency',
          },
        },
        {
          entry: '12 Month',
          singleHigh: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.pastDueBalances?.pastDueBalance
                ?.highSingle12Month
            ),
            type: 'shortCurrency',
          },
          singleHighSevere: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.pastDueBalances?.pastDueBalance
                ?.highSingleSevere12Month
            ),
            type: 'shortCurrency',
          },
          highBalance: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.pastDueBalances?.pastDueBalance
                ?.highBalance12Month
            ),
            type: 'shortCurrency',
          },
          highBalanceSevere: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.pastDueBalances?.pastDueBalance
                ?.highBalanceSevere12Month
            ),
            type: 'shortCurrency',
          },
          totalBalancePast: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.pastDueBalances?.pastDueBalance
                ?.totalBalance12Month
            ),
            type: 'shortCurrency',
          },
          totalBalancePastSevere: {
            value: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.pastDueBalances?.pastDueBalance
                ?.totalBalanceSevere12Month
            ),
            type: 'shortCurrency',
          },
        },
      ],
    },
    groupTradePayment: {
      totalTradeLines: getDefinedOrNullValue(
        company?.financials?.report?.additionalInformation?.extendedPaymentData
          ?.groupTradePaymentInformation?.tradeLines
      ),
      values: {
        categories: [
          {
            key: 'status',
            label: 'Status',
          },
          {
            key: 'current',
            label: 'Current',
          },
          {
            key: '1to30',
            label: '1 - 30',
          },
          {
            key: '31to60',
            label: '31 - 60',
          },
          {
            key: '61to90',
            label: '61 - 90',
          },
          {
            key: '91plus',
            label: '91 +',
          },
          {
            key: 'total',
            label: 'Total',
          },
        ],
        statuses: [
          {
            status: 'Outstanding',
            current: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.groupTradePaymentInformation
                  ?.balanceCurrent
              ),
              type: 'currency',
            },
            '1to30': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.groupTradePaymentInformation
                  ?.balance130
              ),
              type: 'currency',
            },
            '31to60': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.groupTradePaymentInformation
                  ?.balance3160
              ),
              type: 'currency',
            },
            '61to90': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.groupTradePaymentInformation
                  ?.balance6190
              ),
              type: 'currency',
            },
            '91plus': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.groupTradePaymentInformation
                  ?.balance91plus
              ),
              type: 'currency',
            },
            total: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.groupTradePaymentInformation
                  ?.balanceTotal
              ),
              type: 'currency',
            },
          },
          {
            status: 'Payment Experience Value',
            current: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidGroupTradePaymentInformation
                  ?.invoicesValuePaidOnTime
              ),
              type: 'currency',
            },
            '1to30': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidGroupTradePaymentInformation
                  ?.invoicesValuePaid1To30Late
              ),
              type: 'currency',
            },
            '31to60': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidGroupTradePaymentInformation
                  ?.invoicesValuePaid31To60Late
              ),
              type: 'currency',
            },
            '61to90': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidGroupTradePaymentInformation
                  ?.invoicesValuePaid61To90Late
              ),
              type: 'currency',
            },
            '91plus': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidGroupTradePaymentInformation
                  ?.invoicesValuePaid91PlusLate
              ),
              type: 'currency',
            },
            total: {
              value: !!company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.paidGroupTradePaymentInformation
                ? Object.entries(
                    company?.financials?.report?.additionalInformation
                      ?.extendedPaymentData?.paidGroupTradePaymentInformation
                  ).reduce(
                    (combined, [key, value]) =>
                      key.startsWith('invoicesValuePaid')
                        ? combined + value
                        : combined,
                    0
                  )
                : null,
              type: 'currency',
            },
          },
          {
            status: 'Payment Experiences',
            current: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidGroupTradePaymentInformation
                  ?.invoicesPaidOnTime
              ),
              type: 'integer',
            },
            '1to30': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidGroupTradePaymentInformation
                  ?.invoicesPaid1To30Late
              ),
              type: 'integer',
            },
            '31to60': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidGroupTradePaymentInformation
                  ?.invoicesPaid31To60Late
              ),
              type: 'integer',
            },
            '61to90': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidGroupTradePaymentInformation
                  ?.invoicesPaid61To90Late
              ),
              type: 'integer',
            },
            '91plus': {
              value: getDefinedOrNullValue(
                company?.financials?.report?.additionalInformation
                  ?.extendedPaymentData?.paidGroupTradePaymentInformation
                  ?.invoicesPaid91PlusLate
              ),
              type: 'integer',
            },
            total: {
              value: !!company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.paidGroupTradePaymentInformation
                ? Object.entries(
                    company?.financials?.report?.additionalInformation
                      ?.extendedPaymentData?.paidGroupTradePaymentInformation
                  ).reduce(
                    (combined, [key, value]) =>
                      key.startsWith('invoicesPaid')
                        ? combined + value
                        : combined,
                    0
                  )
                : null,
              type: 'integer',
            },
          },
        ],
      },
    },
    industryBreakdown: {
      values: {
        categories: [
          {
            key: 'industry',
            label: 'Industry',
          },
          {
            key: 'status',
            label: 'Status',
          },
          {
            key: 'tradeLines',
            label: 'Trade Lines',
          },
          {
            key: 'experiences',
            label: 'Experiences',
          },
          {
            key: 'current',
            label: 'Current',
          },
          {
            key: '1to30',
            label: '1 - 30',
          },
          {
            key: '31to60',
            label: '31 - 60',
          },
          {
            key: '61to90',
            label: '61 - 90',
          },
          {
            key: '91plus',
            label: '91 +',
          },
          {
            key: 'total',
            label: 'Total',
          },
        ],
        entries: [
          ...(company?.financials?.report?.additionalInformation
            ?.extendedPaymentData?.tpdIndustryBreakdown || []),
        ]
          .reduce((combined, outstandingIndustry) => {
            const paidIndustry = [
              ...(company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.paidTpdIndustries || []),
            ].find(
              ({industryCode}) =>
                industryCode === outstandingIndustry.industryCode
            );
            return !!paidIndustry
              ? [
                  ...combined,
                  {
                    industry: getDefinedOrNullValue(
                      outstandingIndustry?.industryDescription
                    ),
                    industryCode: getDefinedOrNullValue(
                      outstandingIndustry?.industryCode
                    ),
                    status: 'Outstanding',
                    tradeLines: {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.tradeLines
                      ),
                      type: 'integer',
                    },
                    experiences: {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.invoices
                      ),
                      type: 'integer',
                    },
                    current: {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.currentValue
                      ),
                      type: 'currency',
                    },
                    '1to30': {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.range1to30
                      ),
                      type: 'currency',
                    },
                    '31to60': {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.range31to60
                      ),
                      type: 'currency',
                    },
                    '61to90': {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.range61to90
                      ),
                      type: 'currency',
                    },
                    '91plus': {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.range91plus
                      ),
                      type: 'currency',
                    },
                    total: {
                      value: getDefinedOrNullValue(outstandingIndustry?.total),
                      type: 'currency',
                    },
                  },
                  {
                    industry: getDefinedOrNullValue(paidIndustry?.industryCode),
                    industryCode: getDefinedOrNullValue(
                      paidIndustry?.industryCode
                    ),
                    status: 'Paid',
                    tradeLines: {
                      value: getDefinedOrNullValue(
                        paidIndustry?.tradeLinesPaid
                      ),
                      type: 'integer',
                    },
                    experiences: {
                      value: getDefinedOrNullValue(paidIndustry?.invoicesPaid),
                      type: 'integer',
                    },
                    current: {
                      value: getDefinedOrNullValue(
                        paidIndustry?.invoicesValuePaidOnTime
                      ),
                      type: 'currency',
                    },
                    '1to30': {
                      value: getDefinedOrNullValue(
                        paidIndustry?.invoicesValuePaid1To30Late
                      ),
                      type: 'currency',
                    },
                    '31to60': {
                      value: getDefinedOrNullValue(
                        paidIndustry?.invoicesValuePaid31To60Late
                      ),
                      type: 'currency',
                    },
                    '61to90': {
                      value: getDefinedOrNullValue(
                        paidIndustry?.invoicesValuePaid61To90Late
                      ),
                      type: 'currency',
                    },
                    '91plus': {
                      value: getDefinedOrNullValue(
                        paidIndustry?.invoicesValuePaid91PlusLate
                      ),
                      type: 'currency',
                    },
                    total: {
                      value: Object.entries(paidIndustry).reduce(
                        (combined, [key, value]) =>
                          key.startsWith('invoicesValuePaid')
                            ? combined + value
                            : combined,
                        0
                      ),
                      type: 'currency',
                    },
                  },
                ]
              : combined;
          }, [])
          .filter((entry, index, entries) => {
            const connectedEntry = [...entries].find(
              ({industryCode, status}) =>
                entry.industryCode === industryCode && entry.status !== status
            );
            return !!entry?.total?.value || !!connectedEntry?.total?.value;
          }),
      },
    },
    groupIndustryBreakdown: {
      values: {
        categories: [
          {
            key: 'industry',
            label: 'Industry',
          },
          {
            key: 'status',
            label: 'Status',
          },
          {
            key: 'tradeLines',
            label: 'Trade Lines',
          },
          {
            key: 'experiences',
            label: 'Experiences',
          },
          {
            key: 'current',
            label: 'Current',
          },
          {
            key: '1to30',
            label: '1 - 30',
          },
          {
            key: '31to60',
            label: '31 - 60',
          },
          {
            key: '61to90',
            label: '61 - 90',
          },
          {
            key: '91plus',
            label: '91 +',
          },
          {
            key: 'total',
            label: 'Total',
          },
        ],
        entries: [
          ...(company?.financials?.report?.additionalInformation
            ?.extendedPaymentData?.tpdGroupIndustryBreakdown || []),
        ]
          .reduce((combined, outstandingIndustry) => {
            const paidIndustry = [
              ...(company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.paidGroupTpdIndustries || []),
            ].find(
              ({industryCode}) =>
                industryCode === outstandingIndustry.industryCode
            );
            return !!paidIndustry
              ? [
                  ...combined,
                  {
                    industry: getDefinedOrNullValue(
                      outstandingIndustry?.industryDescription
                    ),
                    industryCode: getDefinedOrNullValue(
                      outstandingIndustry?.industryCode
                    ),
                    status: 'Outstanding',
                    tradeLines: {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.tradeLines
                      ),
                      type: 'integer',
                    },
                    experiences: {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.invoices
                      ),
                      type: 'integer',
                    },
                    current: {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.currentValue
                      ),
                      type: 'currency',
                    },
                    '1to30': {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.range1to30
                      ),
                      type: 'currency',
                    },
                    '31to60': {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.range31to60
                      ),
                      type: 'currency',
                    },
                    '61to90': {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.range61to90
                      ),
                      type: 'currency',
                    },
                    '91plus': {
                      value: getDefinedOrNullValue(
                        outstandingIndustry?.range91plus
                      ),
                      type: 'currency',
                    },
                    total: {
                      value: getDefinedOrNullValue(outstandingIndustry?.total),
                      type: 'currency',
                    },
                  },
                  {
                    industry: getDefinedOrNullValue(paidIndustry?.industryCode),
                    industryCode: getDefinedOrNullValue(
                      paidIndustry?.industryCode
                    ),
                    status: 'Paid',
                    tradeLines: {
                      value: getDefinedOrNullValue(
                        paidIndustry?.tradeLinesPaid
                      ),
                      type: 'integer',
                    },
                    experiences: {
                      value: getDefinedOrNullValue(paidIndustry?.invoicesPaid),
                      type: 'integer',
                    },
                    current: {
                      value: getDefinedOrNullValue(
                        paidIndustry?.invoicesValuePaidOnTime
                      ),
                      type: 'currency',
                    },
                    '1to30': {
                      value: getDefinedOrNullValue(
                        paidIndustry?.invoicesValuePaid1To30Late
                      ),
                      type: 'currency',
                    },
                    '31to60': {
                      value: getDefinedOrNullValue(
                        paidIndustry?.invoicesValuePaid31To60Late
                      ),
                      type: 'currency',
                    },
                    '61to90': {
                      value: getDefinedOrNullValue(
                        paidIndustry?.invoicesValuePaid61To90Late
                      ),
                      type: 'currency',
                    },
                    '91plus': {
                      value: getDefinedOrNullValue(
                        paidIndustry?.invoicesValuePaid91PlusLate
                      ),
                      type: 'currency',
                    },
                    total: {
                      value: Object.entries(paidIndustry).reduce(
                        (combined, [key, value]) =>
                          key.startsWith('invoicesValuePaid')
                            ? combined + value
                            : combined,
                        0
                      ),
                      type: 'currency',
                    },
                  },
                ]
              : combined;
          }, [])
          .filter((entry, index, entries) => {
            const connectedEntry = [...entries].find(
              ({industryCode, status}) =>
                entry.industryCode === industryCode && entry.status !== status
            );
            return !!entry?.total?.value || !!connectedEntry?.total?.value;
          }),
      },
    },
    historicalTradeInformation: {
      values: {
        categories: [
          {
            key: 'date',
            label: '',
          },
          {
            key: 'status',
            label: '',
          },
          {
            key: 'current',
            label: 'Current',
          },
          {
            key: '1to30',
            label: '1 - 30',
          },
          {
            key: '31to60',
            label: '31 - 60',
          },
          {
            key: '61to90',
            label: '61 - 90',
          },
          {
            key: '91plus',
            label: '91 +',
          },
          {
            key: 'total',
            label: 'Total',
          },
        ],
        entries: [
          ...(company?.financials?.report?.additionalInformation
            ?.extendedPaymentData?.tpdMonthlyBalanceHistories || []),
        ]
          .reduce((combined, outstandingBalance) => {
            const paidBalance = [
              ...(company?.financials?.report?.additionalInformation
                ?.extendedPaymentData?.paidMonthlyBalanceHistories || []),
            ].find(({date}) => date === outstandingBalance.date);
            return !!paidBalance
              ? [
                  ...combined,
                  {
                    date: {
                      value: getDefinedOrNullValue(outstandingBalance?.date),
                      type: 'date',
                      format: 'MMMM yyyy',
                    },
                    status: 'Outstanding',
                    current: {
                      value: getDefinedOrNullValue(
                        outstandingBalance?.balanceCurrent
                      ),
                      type: 'currency',
                    },
                    '1to30': {
                      value: getDefinedOrNullValue(
                        outstandingBalance?.range1to30
                      ),
                      type: 'currency',
                    },
                    '31to60': {
                      value: getDefinedOrNullValue(
                        outstandingBalance?.range31to60
                      ),
                      type: 'currency',
                    },
                    '61to90': {
                      value: getDefinedOrNullValue(
                        outstandingBalance?.range61to90
                      ),
                      type: 'currency',
                    },
                    '91plus': {
                      value: getDefinedOrNullValue(
                        outstandingBalance?.range91plus
                      ),
                      type: 'currency',
                    },
                    total: {
                      value: getDefinedOrNullValue(
                        outstandingBalance?.totalBalance
                      ),
                      type: 'currency',
                    },
                  },
                  {
                    date: {
                      value: getDefinedOrNullValue(paidBalance?.date),
                      type: 'date',
                      format: 'MMMM yyyy',
                    },
                    status: 'Paid',
                    current: {
                      value: getDefinedOrNullValue(paidBalance?.paidCurrent),
                      type: 'currency',
                    },
                    '1to30': {
                      value: getDefinedOrNullValue(paidBalance?.paid1To30),
                      type: 'currency',
                    },
                    '31to60': {
                      value: getDefinedOrNullValue(paidBalance?.paid31To60),
                      type: 'currency',
                    },
                    '61to90': {
                      value: getDefinedOrNullValue(paidBalance?.paid61To90),
                      type: 'currency',
                    },
                    '91plus': {
                      value: getDefinedOrNullValue(paidBalance?.paid91plus),
                      type: 'currency',
                    },
                    total: {
                      value: Object.entries(paidBalance).reduce(
                        (combined, [key, value]) =>
                          key.startsWith('paid') ? combined + value : combined,
                        0
                      ),
                      type: 'currency',
                    },
                  },
                ]
              : combined;
          }, [])
          .filter((entry, index, entries) => {
            const connectedEntry = [...entries].find(
              ({date, status}) =>
                entry.date.value === date.value && entry.status !== status
            );
            return !!entry?.total?.value || !!connectedEntry?.total?.value;
          }),
      },
    },
    financials: {
      incomeStatement: incomeStatementDataMap(company),
      assets: assetsDataMap(company),
      liabilities: liabilitiesDataMap(company),
      cashFlow: cashFlowDataMap(company),
      profitabilityRatios: profitabilityRatiosDataMap(company),
      assetUtilization: assetUtilizationDataMap(company),
      liquidityRatios: liquidityRatiosDataMap(company),
      capitalStructure: capitalStructureDataMap(company),
      strategicRiskItems: strategicRiskItemsDataMap(company),
      growthRates: growthRatesDataMap(company),
      keyPerformanceIndicators: keyPerformanceIndicatorsDataMap(company),
    },
    officers: [
      ...(company?.financials?.report?.directors?.currentDirectors || []),
    ].map((director) => ({
      ...director,
      position:
        [...(director?.positions || [])].map(
          ({positionName}) => positionName
        ) || '-',
      addressStreet: director?.address?.street || '-',
      addressCity: director?.address?.city || '-',
      addressState: director?.address?.province || '-',
      addressZip: director?.address?.postalCode || '-',
    })),
    legal: {
      filingsSummary: {
        categories: [
          {
            key: 'entry',
            label: '',
          },
          {
            key: 'bankruptcy',
            label: 'Bankruptcy',
          },
          {
            key: 'judgments',
            label: 'Judgments',
          },
          {
            key: 'suits',
            label: 'Suits',
          },
          {
            key: 'taxLienFilings',
            label: 'Tax Lien Filings',
          },
          {
            key: 'sum',
            label: 'Sum',
          },
          {
            key: 'cautionaryUCCFilings',
            label: 'Cautionary UCC Filings',
          },
          {
            key: 'uccFilings',
            label: 'UCC Filings',
          },
        ],
        entries: [
          {
            entry: 'This Company',
            bankruptcy: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingSummary?.bankruptcy
              ),
              type: 'yesNo',
            },
            judgments: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingSummary?.judgmentFilings
              ),
              type: 'integer',
            },
            suits: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingSummary?.suits
              ),
              type: 'integer',
            },
            taxLienFilings: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingSummary?.taxLienFilings
              ),
              type: 'integer',
            },
            sum: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingSummary?.sum?.value
              ),
              type: 'currency',
            },
            cautionaryUCCFilings: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingSummary?.cautionaryUCCFilings
              ),
              type: 'integer',
            },
            uccFilings: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingSummary?.uccFilings
              ),
              type: 'integer',
            },
          },
          {
            entry: 'Branches',
            bankruptcy: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingBranchSummary?.bankruptcy
              ),
              type: 'yesNo',
            },
            judgments: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingBranchSummary?.judgmentFilings
              ),
              type: 'integer',
            },
            suits: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingBranchSummary?.suits
              ),
              type: 'integer',
            },
            taxLienFilings: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingBranchSummary?.taxLienFilings
              ),
              type: 'integer',
            },
            sum: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingBranchSummary?.sum?.value
              ),
              type: 'currency',
            },
            cautionaryUCCFilings: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingBranchSummary?.cautionaryUCCFilings
              ),
              type: 'integer',
            },
            uccFilings: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingBranchSummary?.uccFilings
              ),
              type: 'integer',
            },
          },
          {
            entry: 'Group',
            bankruptcy: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingGroupSummary?.bankruptcy
              ),
              type: 'yesNo',
            },
            judgments: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingGroupSummary?.judgmentFilings
              ),
              type: 'integer',
            },
            suits: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingGroupSummary?.suits
              ),
              type: 'integer',
            },
            taxLienFilings: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingGroupSummary?.taxLienFilings
              ),
              type: 'integer',
            },
            sum: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingGroupSummary?.sum?.value
              ),
              type: 'currency',
            },
            cautionaryUCCFilings: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingGroupSummary?.cautionaryUCCFilings
              ),
              type: 'integer',
            },
            uccFilings: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.legalFilingGroupSummary?.uccFilings
              ),
              type: 'integer',
            },
          },
        ],
      },
      mostRecentFilings: {
        categories: [
          {
            key: 'legalFiling',
            label: 'Legal Filing',
          },
          {
            key: 'dateFiled',
            label: 'Date Filed',
          },
          {
            key: 'filingType',
            label: 'Filing Type',
          },
          {
            key: 'caseNumber',
            label: 'Case/Filing Number',
          },
          {
            key: 'status',
            label: 'Status',
          },
          {
            key: 'releasedDate',
            label: 'Released Date',
          },
        ],
        entries: [
          ...(company?.financials?.report?.negativeInformation
            ?.mostRecentLegalFilings || []),
        ].map((filing) => ({
          legalFiling: getDefinedOrNullValue(filing?.legalFilingType),
          dateFiled: {
            value: getDefinedOrNullValue(filing?.filedDate),
            type: 'date',
            format: 'MM/dd/yyyy',
          },
          filingType: getDefinedOrNullValue(filing?.filingType),
          caseNumber: getDefinedOrNullValue(filing?.case),
          status: getDefinedOrNullValue(filing?.status),
          releasedDate: {
            value: getDefinedOrNullValue(filing?.releasedDate),
            type: 'date',
            format: 'MM/dd/yyyy',
          },
        })),
      },
      suitsAndJudgments: {
        summary: {
          suits: {
            categories: [
              {
                key: 'entry',
                label: '',
              },
              {
                key: 'total',
                label: 'Total',
              },
              {
                key: 'active',
                label: 'Active',
              },
              {
                key: 'released',
                label: 'Released',
              },
            ],
            entries: [
              {
                entry: 'Number Of Suits',
                total:
                  company?.financials?.report?.negativeInformation?.suits
                    ?.length || 0,
                active: [
                  ...(company?.financials?.report?.negativeInformation?.suits ||
                    []),
                ].filter((suit) => suit.status === 'Active').length,
                released: [
                  ...(company?.financials?.report?.negativeInformation?.suits ||
                    []),
                ].filter((suit) => suit.status !== 'Active').length,
              },
            ],
          },
          judgments: {
            categories: [
              {
                key: 'entry',
                label: '',
              },
              {
                key: 'total',
                label: 'Total',
              },
              {
                key: 'active',
                label: 'Active',
              },
              {
                key: 'released',
                label: 'Released',
              },
            ],
            entries: [
              {
                entry: 'Number Of Judgments',
                total:
                  company?.financials?.report?.negativeInformation
                    ?.judgmentDetails?.length || 0,
                active: [
                  ...(company?.financials?.report?.negativeInformation
                    ?.judgmentDetails || []),
                ].filter((suit) => suit.status === 'Active').length,
                released: [
                  ...(company?.financials?.report?.negativeInformation
                    ?.judgmentDetails || []),
                ].filter((suit) => suit.status !== 'Active').length,
              },
            ],
          },
        },
        activeSuits: {
          categories: [
            {
              key: 'dateFiled',
              label: 'Date Filed',
            },
            {
              key: 'filingType',
              label: 'Filing Type',
            },
            {
              key: 'caseNumber',
              label: 'Case Number',
            },
            {
              key: 'amount',
              label: 'Amount',
            },
            {
              key: 'plaintiff',
              label: 'Plaintiff',
            },
          ],
          entries: [
            ...(company?.financials?.report?.negativeInformation?.suits || []),
          ]
            .filter((suit) => suit.status === 'Active')
            .map((suit) => ({
              dateFiled: {
                value: getDefinedOrNullValue(suit?.filedDate),
                type: 'date',
                format: 'MM/dd/yyyy',
              },
              filingType: getDefinedOrNullValue(suit?.filingType),
              caseNumber: getDefinedOrNullValue(suit?.filingNumber),
              amount: {
                value: getDefinedOrNullValue(suit?.amount),
                type: 'currency',
              },
              plaintiff: getDefinedOrNullValue(suit?.plaintiff),
            })),
        },
        activeJudgments: {
          categories: [
            {
              key: 'dateFiled',
              label: 'Date Filed',
            },
            {
              key: 'filingType',
              label: 'Filing Type',
            },
            {
              key: 'caseNumber',
              label: 'Case Number',
            },
            {
              key: 'amount',
              label: 'Amount',
            },
            {
              key: 'plaintiff',
              label: 'Plaintiff',
            },
          ],
          entries: [
            ...(company?.financials?.report?.negativeInformation
              ?.judgmentDetails || []),
          ]
            .filter((judgment) => judgment.status === 'Active')
            .map((judgment) => ({
              dateFiled: {
                value: getDefinedOrNullValue(judgment?.filedDate),
                type: 'date',
                format: 'MM/dd/yyyy',
              },
              filingType: getDefinedOrNullValue(judgment?.filingType),
              caseNumber: getDefinedOrNullValue(judgment?.filingNumber),
              amount: {
                value: getDefinedOrNullValue(judgment?.amount),
                type: 'currency',
              },
              plaintiff: getDefinedOrNullValue(judgment?.plaintiff),
            })),
        },
      },
      ucc: {
        summary: {
          categories: [
            {
              key: 'total',
              label: 'Total',
            },
            {
              key: 'cautionaryUCCs',
              label: 'Cautionary UCCs',
            },
            {
              key: 'uccs',
              label: 'UCCs',
            },
          ],
          entries: [
            {
              total:
                (company?.financials?.report?.negativeInformation
                  ?.cautionaryUccDetails?.length || 0) +
                (company?.financials?.report?.negativeInformation?.uccDetails
                  ?.length || 0),
              cautionaryUCCs: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation
                  ?.cautionaryUccDetails?.length
              ),
              uccs: getDefinedOrNullValue(
                company?.financials?.report?.negativeInformation?.uccDetails
                  ?.length
              ),
            },
          ],
        },
        cautionaryUCCs: {
          categories: [
            {
              key: 'dateFiled',
              label: 'Date Filed',
            },
            {
              key: 'expirationDate',
              label: 'Expiration Date',
            },
            {
              key: 'filingNumber',
              label: 'Filing Number',
            },
            {
              key: 'jurisdiction',
              label: 'Jurisdiction',
            },
            {
              key: 'collateral',
              label: 'Collateral',
            },
            {
              key: 'status',
              label: 'Status',
            },
          ],
          entries: [
            ...(company?.financials?.report?.negativeInformation
              ?.cautionaryUccDetails || []),
          ].map((ucc) => ({
            dateFiled: {
              value: getDefinedOrNullValue(ucc?.filedDate),
              type: 'date',
              format: 'MM/dd/yyyy',
            },
            expirationDate: {
              value: getDefinedOrNullValue(ucc?.expirationDate),
              type: 'date',
              format: 'MM/dd/yyyy',
            },
            filingNumber: getDefinedOrNullValue(ucc?.filingNumber),
            jurisdiction: getDefinedOrNullValue(ucc?.jurisdiction),
            collateral: getDefinedOrNullValue(ucc?.collateral),
            status: getDefinedOrNullValue(ucc?.status),
          })),
        },
        uccs: {
          categories: [
            {
              key: 'dateFiled',
              label: 'Date Filed',
            },
            {
              key: 'expirationDate',
              label: 'Expiration Date',
            },
            {
              key: 'filingNumber',
              label: 'Filing Number',
            },
            {
              key: 'jurisdiction',
              label: 'Jurisdiction',
            },
            {
              key: 'collateral',
              label: 'Collateral',
            },
            {
              key: 'status',
              label: 'Status',
            },
          ],
          entries: [
            ...(company?.financials?.report?.negativeInformation?.uccDetails ||
              []),
          ].map((ucc) => ({
            dateFiled: {
              value: getDefinedOrNullValue(ucc?.filedDate),
              type: 'date',
              format: 'MM/dd/yyyy',
            },
            expirationDate: {
              value: getDefinedOrNullValue(ucc?.expirationDate),
              type: 'date',
              format: 'MM/dd/yyyy',
            },
            filingNumber: getDefinedOrNullValue(ucc?.filingNumber),
            jurisdiction: getDefinedOrNullValue(ucc?.jurisdiction),
            collateral: getDefinedOrNullValue(ucc?.collateral),
            status: getDefinedOrNullValue(ucc?.status),
          })),
        },
      },
    },
    companyDetails: {
      companyInformation: {
        categories: [
          {
            key: 'companyType',
            label: 'Company Type',
          },
          {
            key: 'corporatePrimaryAddress',
            label: 'Address',
          },
          {
            key: 'addressType',
            label: 'Address Type',
          },
          {
            key: 'estimatedNumberOfEmployees',
            label: 'Estimated Number Of Employees',
          },
          {
            key: 'primarySICCodeIndustryClassification',
            label: 'Primary SIC Code Industry Classification',
          },
          {
            key: 'yearsInBusiness',
            label: 'Years In Business',
          },
          {
            key: 'estimatedAnnualRevenue',
            label: 'Estimated Annual Revenue',
          },
          {
            key: 'locationType',
            label: 'Location Type',
          },
        ],
        entries: [
          {
            companyType: getDefinedOrNullValue(
              company?.financials?.report?.companyIdentification
                ?.basicInformation?.legalForm?.description
            ),
            corporatePrimaryAddress: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation?.misc
                ?.corporatePrimaryAddress
            ),
            addressType: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation?.misc
                ?.addressType
            ),
            estimatedNumberOfEmployees: getDefinedOrNullValue(
              company?.financials?.report?.otherInformation
                ?.employeesInformation?.[0]?.numberOfEmployees
            ),
            primarySICCodeIndustryClassification: getDefinedOrNullValue(
              company?.financials?.report?.companySummary?.mainActivity
                ?.industrySector
            ),
            yearsInBusiness: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation?.misc
                ?.yearsInBusiness
            ),
            estimatedAnnualRevenue: {
              value: getDefinedOrNullValue(
                company?.financials?.report?.companySummary
                  ?.latestTurnoverFigure?.value
              ),
              type: 'shortCurrency',
            },
            locationType: getDefinedOrNullValue(
              company?.financials?.report?.additionalInformation?.misc
                ?.locationType
            ),
          },
        ],
      },
      activities: {
        categories: [
          {
            key: 'classification',
            label: 'Classification',
          },
          {
            key: 'code',
            label: 'Code',
          },
          {
            key: 'description',
            label: 'Description',
          },
        ],
        entries: [
          ...(company?.financials?.report?.companyIdentification
            ?.activityClassifications || []),
        ]
          .map((activities) =>
            [...(activities?.activities || [])].map((activity) => ({
              classification: activities.classification,
              code: activity.code,
              description: activity.description,
            }))
          )
          .flat(),
      },
    },
  };
};

export default mapFinancials;
