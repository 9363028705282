// compliance components
import ComplianceAndQuestionnairesDetail from '../../components/ComplianceAndQuestionnairesDetail/ComplianceAndQuestionnairesDetail';

// compliance events
import showComplianceDetailEvent from '../../events/showComplianceDetail.event.compliance';

// engagement lib
import areFilesBeingProcessed from '../../../engagement/lib/areFilesBeingProcessed.lib.engagement';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// math lib
import divide from '../../../math/lib/divide.lib.math';
import multiply from '../../../math/lib/multiply.lib.math';
import sum from '../../../math/lib/sum.lib.math';

// number lib
import integer from '../../../number/lib/integer.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// risk lib
import getScoreColor from '../../../risk/lib/getScoreColor.lib.risk';

class ComplianceAndQuestionnairesDetailContainer extends Component {
  static propTypes = {
    analyzingFiles: PropTypes.array,
    compliance: PropTypes.object,
    engagement: PropTypes.object,
    engagementFiles: PropTypes.array,
    isCompanyLevel: PropTypes.bool,
    questionnaires: PropTypes.array,
    service: PropTypes.object,
    subscribe: PropTypes.func,
    uploadingFiles: PropTypes.array,
  };

  state = {
    selectedObjectType: null,
    selectedObject: null,
  };

  componentDidMount() {
    this.props.subscribe(
      showComplianceDetailEvent.subscribe(this.showComplianceDetailFromEvent)
    );
  }

  showComplianceDetailFromEvent = ({compliance}) => {
    this.select(compliance.framework);
  };

  selectableOptions = () => {
    const {compliance, isCompanyLevel, questionnaires} = this.props;

    const frameworks = [...compliance.frameworks]
      .map(({framework, color, completeness, score, is_custom}) => ({
        id: framework,
        label: framework,
        color,
        completeness,
        score,
        isCustom: is_custom,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
      .sort((a, b) => (a.isCustom ? -1 : 1));

    const complianceScore = integer(
      divide(
        [...frameworks].reduce((acc, {score}) => sum(acc, score), 0),
        frameworks.length
      )
    );
    const complianceCoverage = integer(
      divide(
        [...frameworks].reduce(
          (acc, {completeness}) => sum(acc, completeness),
          0
        ),
        frameworks.length
      )
    );

    const availableQuestionnaires = [...questionnaires]
      .map(({id, type, questions}) => {
        const coverages = [...questions].map(({answer}) =>
          multiply(answer?.coverage || 0, 100)
        );
        const completeness = integer(
          divide(sum(...coverages), coverages.length)
        );
        return {
          id,
          label: type,
          completeness,
          coverages,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    const questionnaireCoverage = integer(
      divide(
        [...availableQuestionnaires].reduce(
          (acc, {completeness}) => sum(acc, completeness),
          0
        ),
        availableQuestionnaires.length
      )
    );

    return [
      {
        id: 'frameworks',
        label: 'Standard Frameworks',
        score: complianceScore,
        coverage: complianceCoverage,
        color: getScoreColor(complianceScore),
        entries: frameworks,
      },
      !isCompanyLevel && {
        id: 'questionnaires',
        label: 'Questionnaires',
        coverage: questionnaireCoverage,
        entries: availableQuestionnaires,
      },
    ].filter(Boolean);
  };

  select = (value) => {
    if (value === 'all')
      return this.setState({
        selectedObject: null,
        selectedObjectType: null,
      });
    const {compliance, questionnaires} = this.props;
    const objectType = value.startsWith('contract_questionnaire_')
      ? 'questionnaire'
      : 'compliance';
    const optionSelectors = {
      questionnaire: () => [...questionnaires].find(({id}) => id === value),
      compliance: () =>
        [...compliance.frameworks].find(({framework}) => framework === value),
    };
    const object = optionSelectors[objectType]();
    if (!object) return;
    this.setState({
      selectedObject: {optionId: value, ...object},
      selectedObjectType: objectType,
    });
  };

  render() {
    const {
      analyzingFiles,
      engagement,
      engagementFiles,
      service,
      uploadingFiles,
    } = this.props;
    const {selectedObject, selectedObjectType} = this.state;
    return (
      <ComplianceAndQuestionnairesDetail
        areFilesBeingProcessed={areFilesBeingProcessed({
          analyzingFiles,
          engagement,
          files: engagementFiles,
          service,
          uploadingFiles,
        })}
        onSelect={this.select}
        selectableOptions={this.selectableOptions()}
        selectedObject={selectedObject}
        selectedObjectType={selectedObjectType}
      />
    );
  }
}

export default connect((state) => ({
  analyzingFiles: state.file.analyzing,
  engagement: state.engagement.engagement,
  engagementFiles: state.engagement.engagementFiles,
  service: state.engagement.service,
  uploadingFiles: state.file.uploading,
}))(subscriptionHOC(ComplianceAndQuestionnairesDetailContainer));
