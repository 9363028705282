// alert containers
import AlertsGeneratorContainer from '../../../alert/containers/AlertsGeneratorContainer/AlertsGeneratorContainer';

// app components
import DataLoadScreen from '../../../app/components/DataLoadScreen/DataLoadScreen';

// contract containers
import ContractAnswersContainer from '../../../contract/containers/ContractAnswersContainer/ContractAnswersContainer';

// collection containers
import CollectionEventHandlerContainer from '../../../collection/containers/CollectionEventHandlerContainer/CollectionEventHandlerContainer';
import CollectionsDataContainer from '../../../collection/containers/CollectionsDataContainer/CollectionsDataContainer';
import CreateCollectionContainer from '../../../collection/containers/CreateCollectionContainer/CreateCollectionContainer';
import UpdateCollectionContainer from '../../../collection/containers/UpdateCollectionContainer/UpdateCollectionContainer';

// company containers
import VSIContainer from '../../../company/containers/VSIContainer/VSIContainer';

// contact containers
import ContactContainer from '../../../contact/containers/ContactContainer/ContactContainer';

// cyberSecurity containers
// import CyberRatingDetailContainer from '../../../cyberSecurity/containers/CyberRatingDetailContainer/CyberRatingDetailContainer';

// engagement containers
import DeleteEngagementContainer from '../../../engagement/containers/DeleteEngagementContainer/DeleteEngagementContainer';
import EngagementCreatorContainer from '../../../engagement/containers/EngagementCreatorContainer/EngagementCreatorContainer';
import UpdateEngagementContainer from '../../../engagement/containers/UpdateEngagementContainer/UpdateEngagementContainer';

// engagementService containers
import DeleteEngagementServiceContainer from '../../../engagementService/containers/DeleteEngagementServiceContainer/DeleteEngagementServiceContainer';
import EngagementServiceCreatorContainer from '../../../engagementService/containers/EngagementServiceCreatorContainer/EngagementServiceCreatorContainer';
import UpdateEngagementServiceContainer from '../../../engagementService/containers/UpdateEngagementServiceContainer/UpdateEngagementServiceContainer';

// dashboard containers
import LoadDashboardDataContainer from '../../../dashboard/containers/LoadDashboardDataContainer/LoadDashboardDataContainer';

// file containers
import FileStatusCheckContainer from '../../../file/containers/FileStatusCheckContainer/FileStatusCheckContainer';

// layout components
import NotImplemented from '../NotImplemented/NotImplemented';

// local components
import Container from './components/Container';

// navigation containers
import NavigationContainer from '../../../navigation/containers/NavigationContainer/NavigationContainer';

// news containers
import NewsArticleContainer from '../../../news/containers/NewsArticleContainer/NewsArticleContainer';

// notification container
import NotificationGeneratorContainer from '../../../notification/containers/NotificationGeneratorContainer/NotificationGeneratorContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

// supplier containers
import LoadSuppliersForSearchContainer from '../../../supplier/containers/LoadSuppliersForSearchContainer/LoadSuppliersForSearchContainer';

// tag containers
import BlackKiteTagDetailContainer from '../../../tag/containers/BlackKiteTagDetailContainer/BlackKiteTagDetailContainer';
import CreateTagContainer from '../../../tag/containers/CreateTagContainer/CreateTagContainer';
import TagDetailContainer from '../../../tag/containers/TagDetailContainer/TagDetailContainer';
import TagsLoaderContainer from '../../../tag/containers/TagsLoaderContainer/TagsLoaderContainer';
import UpdateTagContainer from '../../../tag/containers/UpdateTagContainer/UpdateTagContainer';

// tenant containers
import LoadCustomDataContainer from '../../../tenant/containers/LoadCustomDataContainer/LoadCustomDataContainer';

const Layout = ({children}) => (
  <Fragment>
    <DataLoadScreen>
      <AlertsGeneratorContainer>
        <Container>
          <NavigationContainer />
          {children}
        </Container>
        <VSIContainer />

        <CreateTagContainer />
        <UpdateTagContainer />
        <TagDetailContainer />

        <BlackKiteTagDetailContainer />
        {/* <CyberRatingDetailContainer /> */}
        <NewsArticleContainer />
        <ContactContainer />
        <NotificationGeneratorContainer />
        <CollectionsDataContainer />

        <CollectionEventHandlerContainer />
        <CreateCollectionContainer />
        <UpdateCollectionContainer />

        <EngagementCreatorContainer />
        <UpdateEngagementContainer />
        <DeleteEngagementContainer />
        <EngagementServiceCreatorContainer />
        <UpdateEngagementServiceContainer />
        <DeleteEngagementServiceContainer />
        <ContractAnswersContainer />

        <FileStatusCheckContainer />

        <NotImplemented />
      </AlertsGeneratorContainer>
    </DataLoadScreen>
    <LoadDashboardDataContainer />
    <LoadSuppliersForSearchContainer />
    <LoadCustomDataContainer />
    <TagsLoaderContainer />
  </Fragment>
);

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
