import React from 'react';
import {Table} from 'antd';

const IndustryBreakdown = () => {
  const data = [
    {
      key: '1',
      industry: 'Wholesale Trade',
      tradeLines: 292,
      current: 'Can$4,765,918',
      period1: 'Can$932,676',
      period2: 'Can$718,858',
      period3: 'Can$456,389',
      total: 'Can$6,873,841',
    },
    {
      key: '2',
      industry: 'Retail Trade',
      tradeLines: 2,
      current: 'Can$1,809',
      period1: 'Can$481',
      period2: 'Can$0',
      period3: 'Can$0',
      total: 'Can$2,290',
    },
    {
      key: '3',
      industry: 'Manufacturing',
      tradeLines: 49,
      current: 'Can$337,621',
      period1: 'Can$97,349',
      period2: 'Can$31,254',
      period3: 'Can$10,705',
      total: 'Can$476,929',
    },
    {
      key: '4',
      industry: 'Services',
      tradeLines: 126,
      current: 'Can$600,800',
      period1: 'Can$414,851',
      period2: 'Can$310,603',
      period3: 'Can$26,731',
      total: 'Can$1,352,985',
    },
    {
      key: '5',
      industry: 'Nonclassifiable Establishments',
      tradeLines: 4,
      current: 'Can$4,188,264',
      period1: 'Can$2,227',
      period2: 'Can$0',
      period3: 'Can$0',
      total: 'Can$4,190,491',
    },
    {
      key: '6',
      industry: 'Construction',
      tradeLines: 6,
      current: 'Can$28,736',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      total: 'Can$28,736',
    },
    {
      key: '7',
      industry: 'Finance, Insurance, and Real Estate',
      tradeLines: 100,
      current: 'Can$384,012',
      period1: 'Can$1,017',
      period2: 'Can$0',
      period3: 'Can$0',
      total: 'Can$385,029',
    },
    {
      key: '8',
      industry:
        'Transportation, Communications, Electric, Gas, and Sanitary Services',
      tradeLines: 8,
      current: 'Can$20,502',
      period1: 'Can$8,667',
      period2: 'Can$17,914',
      period3: 'Can$460',
      total: 'Can$47,543',
    },
    {
      key: '9',
      industry: 'Federal, State, and Local Government',
      tradeLines: 4,
      current: 'Can$21,754',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      total: 'Can$21,754',
    },
  ];

  const columns = [
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: 'Trade Lines',
      dataIndex: 'tradeLines',
      key: 'tradeLines',
    },
    {
      title: 'Current',
      dataIndex: 'current',
      key: 'current',
    },
    {
      title: 'Period 1',
      dataIndex: 'period1',
      key: 'period1',
    },
    {
      title: 'Period 2',
      dataIndex: 'period2',
      key: 'period2',
    },
    {
      title: 'Period 3',
      dataIndex: 'period3',
      key: 'period3',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default IndustryBreakdown;
