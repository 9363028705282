// ant components
import {Modal} from 'antd';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const DeleteEngagementServiceModal = ({
  loading,
  onClose,
  onDelete,
  service,
  visible,
}) => (
  <Modal
    title="Close Service"
    centered
    open={visible}
    onOk={onDelete}
    onCancel={onClose}
    okText="Delete"
    loading={loading}
    width={'300px'}
  >
    Are you sure you want to close "{service}"?
  </Modal>
);

DeleteEngagementServiceModal.propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  service: PropTypes.string,
  visible: PropTypes.bool,
};

export default DeleteEngagementServiceModal;
