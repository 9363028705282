// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import setGeneralEngagementDataAction from './actions/setGeneralData.action.redux.engagement';
import setupEngagementAction from './actions/setupEngagement.action.redux.engagement';

// style redux constants
import DEFAULT_DASHBOARD_REDUX_STATE from './constants/defaultState.constant.redux.engagement';

const engagementSlice = createSlice({
  name: 'engagement',
  initialState: DEFAULT_DASHBOARD_REDUX_STATE,
  reducers: {
    setGeneralEngagementData: setGeneralEngagementDataAction,
    setupEngagement: setupEngagementAction,
  },
});

export const {setGeneralEngagementData, setupEngagement} =
  engagementSlice.actions;

export default engagementSlice.reducer;
