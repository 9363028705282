import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';
import blueGrey300Color from '../../../../layout/colors/blueGrey300.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

const Container = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: ${whiteColor};
  background: ${blueGrey300Color};
  padding: 4px 12px;
  border-radius: 16px;
  user-select: none;
  font-size: 12px;

  &:hover {
    background: ${blackColor};
  }
`;

export default Container;
