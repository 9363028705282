import styled from 'styled-components';

// layout color
import blueGrey400Color from '../../../../layout/colors/blueGrey400.color.layout';

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${blueGrey400Color};
  margin: 5px 0px;
`;

export default SubTitle;
