import React from 'react';
import {Table} from 'antd';

// types
import currency from '../../../number/lib/currency.lib.number';

const SanctionAmlCategories = ({data, printView}) => {
  const formattedData = Object.values(data)
    .filter((entry) => Array.isArray(entry))
    .flat()
    .map((entry) => ({
      key: [entry?.value?.category, entry?.value?.subCategory].join('-'),
      category: entry?.value?.category || '-',
      subCategory: entry?.value?.subCategory || '-',
      events: entry?.events || [],
    }));

  // Columns for the main table (Categories)
  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Sub-Category',
      dataIndex: 'subCategory',
      key: 'subCategory',
    },
  ];

  // Columns for the subtable (Events)
  const eventColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) =>
        record.amount && record.amount !== 'None'
          ? `${currency(record.amount)} ${record.currencyCode || ''}`
          : '-',
    },
    {
      title: 'Source',
      dataIndex: 'sourceLink',
      key: 'sourceLink',
      render: (_, record) =>
        record.sources.length > 0 ? (
          <a
            href={record.sources[0].link}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Source
          </a>
        ) : (
          '-'
        ),
    },
    {
      title: 'Document',
      dataIndex: 'documentLink',
      key: 'documentLink',
      render: (_, record) =>
        record.sources.length > 0 &&
        record.sources[0].documentLink !== 'None' ? (
          <a
            href={record.sources[0].documentLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </a>
        ) : (
          '-'
        ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={formattedData}
      expandable={{
        expandedRowRender: (record) => (
          <Table
            columns={eventColumns}
            dataSource={[...(record.events || [])].map((event) => ({
              key: event.id,
              type: event.type || '-',
              date: event.date || '-',
              amount: event.amount,
              currencyCode: event.currencyCode,
              sources: event.sources || [],
            }))}
            pagination={false}
          />
        ),
        rowExpandable: (record) => [...(record.events || [])].length > 0,
        defaultExpandAllRows: printView,
      }}
      pagination={false}
    />
  );
};

export default SanctionAmlCategories;
