import React from 'react';
import {Table} from 'antd';

const IndustryBreakdown = () => {
  const data = [
    {
      key: '1',
      industry: 'Construction',
      tradeLines: 1,
      current: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$10,029',
      period3: 'Can$0',
      total: 'Can$10,029',
    },
    {
      key: '2',
      industry: 'Wholesale Trade',
      tradeLines: 8,
      current: 'Can$4,209',
      period1: 'Can$356',
      period2: 'Can$22',
      period3: 'Can$6,780',
      total: 'Can$11,367',
    },
    {
      key: '3',
      industry: 'Services',
      tradeLines: 3,
      current: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$909',
      total: 'Can$909',
    },
    {
      key: '4',
      industry:
        'Transportation, Communications, Electric, Gas, and Sanitary Services',
      tradeLines: 1,
      current: 'Can$776',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      total: 'Can$776',
    },
    {
      key: '5',
      industry: 'Federal, State, and Local Government',
      tradeLines: 1,
      current: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$151',
      total: 'Can$151',
    },
    {
      key: '6',
      industry: 'Finance, Insurance, and Real Estate',
      tradeLines: 4,
      current: 'Can$660',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      total: 'Can$660',
    },
  ];

  const columns = [
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: 'Trade Lines',
      dataIndex: 'tradeLines',
      key: 'tradeLines',
    },
    {
      title: 'Current',
      dataIndex: 'current',
      key: 'current',
    },
    {
      title: 'Period 1',
      dataIndex: 'period1',
      key: 'period1',
    },
    {
      title: 'Period 2',
      dataIndex: 'period2',
      key: 'period2',
    },
    {
      title: 'Period 3',
      dataIndex: 'period3',
      key: 'period3',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default IndustryBreakdown;
