// ant components
import {Table, Tag} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// layout colors
import grey800Color from '../../../layout/colors/grey800.color.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const FirmographicTable = ({firmographics}) => {
  if (!firmographics) return null;

  const {
    company_name,
    locations,
    jurisdiction,
    legal_form,
    company_type,
    company_status,
    year_founded,
    incorporation_date,
    lei,
    ein,
    vat_id,
    tin,
    employee_count,
    business_tags,
  } = firmographics;

  const mainAddress =
    locations?.find(({main}) => main) || locations?.[0] || null;

  // Build address string dynamically, filtering out null/undefined parts
  const formattedAddress = mainAddress
    ? [
        mainAddress.street,
        mainAddress.street_number,
        mainAddress.city,
        mainAddress.region,
        mainAddress.postcode,
        mainAddress.country,
      ]
        .filter(Boolean) // Removes falsy values (null, undefined, empty strings)
        .join(', ')
    : null;

  const data = [
    company_name && {
      key: 'company_legal_name',
      field: 'Company Legal Name',
      value: company_name,
    },
    formattedAddress && {
      key: 'main_address',
      field: 'Main Address',
      value: formattedAddress,
    },
    jurisdiction && {
      key: 'jurisdiction',
      field: 'Jurisdiction',
      value: jurisdiction,
    },
    legal_form && {
      key: 'legal_form',
      field: 'Legal Form',
      value: legal_form,
    },
    company_type && {
      key: 'company_type',
      field: 'Company Type',
      value: company_type,
    },
    company_status && {
      key: 'company_status',
      field: 'Company Status',
      value: company_status,
    },
    year_founded && {
      key: 'year_founded',
      field: 'Year Founded',
      value: year_founded,
    },
    incorporation_date && {
      key: 'incorporation_date',
      field: 'Incorporation Date',
      value: incorporation_date,
    },
    lei && {
      key: 'lei',
      field: 'Legal Entity Identifier (LEI)',
      value: lei,
    },
    ein && {
      key: 'ein',
      field: 'Employer Identification Number (EIN)',
      value: ein,
    },
    vat_id && {
      key: 'vat_id',
      field: 'VAT ID',
      value: vat_id,
    },
    tin && {
      key: 'tin',
      field: 'Tax Identification Number (TIN)',
      value: tin,
    },
    employee_count && {
      key: 'employee_count',
      field: 'Employee Count',
      value: employee_count,
    },
    business_tags?.length && {
      key: 'tags',
      field: 'Tags',
      value: business_tags.map((tag) => (
        <Tag key={tag} color={grey800Color} style={{marginBottom: 8}}>
          {tag}
        </Tag>
      )),
    },
  ].filter(Boolean); // Removes null or undefined values

  return (
    <StatementBlock title="Firmographic Information">
      <Table
        columns={[
          {
            dataIndex: 'field',
            key: 'field',
            render: (text) => <strong>{text}</strong>,
          },
          {
            dataIndex: 'value',
            key: 'value',
          },
        ]}
        dataSource={data}
        pagination={false}
        size="small"
        rowKey="key"
        showHeader={false} // Hides the table header
      />
    </StatementBlock>
  );
};

FirmographicTable.propTypes = {
  firmographics: PropTypes.object,
};

export default FirmographicTable;
