const parseFindingControlDescription = (description) => {
  try {
    const cleanedDescription = description.split('NaN, ').join('null, ');
    const parsedDescription = JSON.parse(cleanedDescription);
    return parsedDescription?.Description || description;
  } catch (error) {
    return description;
  }
};

export default parseFindingControlDescription;
