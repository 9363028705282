// compliance events
import showComplianceDetailEvent from '../../../../compliance/events/showComplianceDetail.event.compliance';

const complianceClickHandler =
  () =>
  ({compliance, frameworks}) => {
    showComplianceDetailEvent.publish({
      compliance,
      frameworks,
      flat: true,
    });
  };

export default complianceClickHandler;
