// compliance containers
import ControlVarianceListContainer from '../../../compliance/containers/ControlVarianceListContainer/ControlVarianceListContainer';

// compliance services
import listFrameworkScoresAndQuestionnairesService from '../../../compliance/services/listFrameworkScoresAndQuestionnaires.service.compliance';

// engagement components
import DueDiligenceDataCard from '../../components/DueDiligenceDataCard/DueDiligenceDataCard';
import DueDiligenceLayout from '../../components/DueDiligenceLayout/DueDiligenceLayout';

// engagement lib
import areFilesBeingProcessed from '../../lib/areFilesBeingProcessed.lib.engagement';

// engagement redux actions
import {setGeneralEngagementData as setGeneralEngagementDataAction} from '../../redux/reducer.redux.engagement';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// layout components
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';

// file events
import fileAnalyzedEvent from '../../../file/events/analyzed.event.file';
import fileCheckedEvent from '../../../file/events/checked.event.file';
import fileDeletedEvent from '../../../file/events/deleted.event.file';
import fileUploadedEvent from '../../../file/events/uploaded.event.file';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// supplier lib
import getCompanyId from '../../../supplier/lib/getCompanyId.lib.supplier';

class DueDiligenceDataContainer extends Component {
  static propTypes = {
    analyzingFiles: PropTypes.array,
    company: PropTypes.object,
    compliance: PropTypes.object,
    complianceChartTitle: PropTypes.string,
    dispatch: PropTypes.func,
    domReferences: PropTypes.object,
    engagement: PropTypes.object,
    engagementFiles: PropTypes.array,
    file: PropTypes.object,
    layoutProps: PropTypes.object,
    onInitialFileUploadTour: PropTypes.func,
    questionnaires: PropTypes.array,
    service: PropTypes.object,
    subscribe: PropTypes.func,
    textProps: PropTypes.object,
    uploadingFiles: PropTypes.array,
  };

  static defaultProps = {
    layoutProps: {},
    textProps: {},
    domReferences: {},
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.mounted = true;
    this.getComplianceData();
    this.props.subscribe(
      fileAnalyzedEvent.subscribe(this.checkFileAndUpdateData),
      fileUploadedEvent.subscribe(this.checkFileAndUpdateData),
      fileCheckedEvent.subscribe(this.checkFileAndUpdateData),
      fileDeletedEvent.subscribe(this.fileDeleted)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  checkFileAndUpdateData = async ({file}) => {
    if (!!this.props.file) return;
    const fileExists = [...this.props.engagementFiles].find(
      ({id}) => id === file.id
    );
    if (!fileExists) return;
    this.getComplianceData();
  };

  fileDeleted = ({file}) => {
    if (!!this.props.file) return;
    const {dispatch} = this.props;
    const engagementFiles = [...this.props.engagementFiles].filter(
      ({id}) => id !== file.id
    );
    dispatch(setGeneralEngagementDataAction({engagementFiles}));
    this.getComplianceData();
  };

  getComplianceData = async () => {
    const {dispatch} = this.props;
    const {compliance, questionnaires} = await this.getLatestComplianceData();
    if (!this.mounted) return;
    dispatch(setGeneralEngagementDataAction({compliance, questionnaires}));
    this.setState({loading: false}, () => {
      if (this.showDetails({compliance, questionnaires})) return;
      this?.props?.onInitialFileUploadTour?.({
        checkIfViewed: true,
        openIn: 500,
      });
    });
  };

  getLatestComplianceData = () =>
    listFrameworkScoresAndQuestionnairesService(this.getComplianceQuery());

  getComplianceQuery = () => {
    const {company, engagement, file, service} = this.props;
    return {
      companyId: getCompanyId(company),
      fileVersionId: file?.latest_file_version_id,
      serviceId: service?.id,
      engagementId: engagement?.id,
    };
  };

  showDetails = ({compliance, questionnaires} = this.props) =>
    !!compliance?.frameworks?.length || !!questionnaires?.length;

  isCompanyLevel = () => {
    const {engagement, service, file} = this.props;
    return !engagement && !service && !file;
  };

  render() {
    const {
      analyzingFiles,
      compliance,
      complianceChartTitle,
      domReferences,
      engagement,
      engagementFiles,
      layoutProps,
      questionnaires,
      service,
      textProps,
      uploadingFiles,
    } = this.props;
    const {loading} = this.state;
    return loading ? (
      <ScreenLoader>Loading Scores</ScreenLoader>
    ) : (
      <DueDiligenceLayout
        {...layoutProps}
        domReferences={domReferences}
        isCompanyLevel={this.isCompanyLevel()}
      >
        <DueDiligenceDataCard
          analyzingFiles={areFilesBeingProcessed({
            analyzingFiles,
            engagement,
            files: engagementFiles,
            service,
            uploadingFiles,
          })}
          compliance={compliance}
          complianceChartTitle={complianceChartTitle}
          domReferences={domReferences}
          questionnaires={questionnaires}
          showDetails={this.showDetails()}
          textProps={textProps}
          isCompanyLevel={this.isCompanyLevel()}
        />
        <ControlVarianceListContainer />
      </DueDiligenceLayout>
    );
  }
}

export default connect((state) => ({
  analyzingFiles: state.file.analyzing,
  company: state.company.company,
  compliance: state.engagement.compliance,
  engagement: state.engagement.engagement,
  engagementFiles: state.engagement.engagementFiles,
  file: state.engagement.file,
  questionnaires: state.engagement.questionnaires,
  service: state.engagement.service,
  uploadingFiles: state.file.uploading,
}))(subscriptionHOC(DueDiligenceDataContainer));
