import React from 'react';
import {Table} from 'antd';

const PeopleTable = () => {
  const data = [
    {
      key: '1',
      name: 'Suzanne Demeules',
      linkedIn: 'linkedin.com/in/suzanne-demeules-60742b14',
      currentTitle: 'Executive Vice President',
      currentCompany: 'CIMA+',
      startDate: '05/01/2023',
    },
    {
      key: '2',
      name: 'Rock Lacroix',
      linkedIn: 'linkedin.com/in/rock-lacroix-8615948',
      currentTitle: 'Senior Vice President',
      currentCompany: 'CIMA+',
      startDate: '02/01/2024',
    },
    {
      key: '3',
      name: 'Tom Montgomery',
      linkedIn: 'linkedin.com/in/tom-montgomery-4548025a',
      currentTitle: 'Senior Vice President',
      currentCompany: 'CIMA+',
      startDate: '02/01/2024',
    },
    {
      key: '4',
      name: 'Eric Van Acker',
      linkedIn: 'linkedin.com/in/eric-van-acker-8aba568',
      currentTitle: 'Senior Vice President',
      currentCompany: 'CIMA+',
      startDate: '03/01/2022',
    },
  ];

  const columns = [
    {title: 'Name', dataIndex: 'name', key: 'name'},
    {
      title: 'LinkedIn',
      dataIndex: 'linkedIn',
      key: 'linkedIn',
      render: (text) => (
        <a href={`https://${text}`} target="_blank" rel="noopener noreferrer">
          Profile
        </a>
      ),
    },
    {title: 'Current Title', dataIndex: 'currentTitle', key: 'currentTitle'},
    {
      title: 'Current Company',
      dataIndex: 'currentCompany',
      key: 'currentCompany',
    },
    {title: 'Start Date', dataIndex: 'startDate', key: 'startDate'},
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default PeopleTable;
