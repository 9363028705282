import React from 'react';
import {Table} from 'antd';

const LegalDetails = () => {
  const data = [
    {
      key: '1',
      reportedDate: '01/06/2025',
      claimDate: '11/13/2024',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'LEGAL LIEN QC CONSTRUCTION',
      claimNumber: '029077152-# ENREG.',
      amount: 'Can$0',
    },
    {
      key: '2',
      reportedDate: '12/09/2024',
      claimDate: '10/16/2024',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'Statement of Claim',
      claimNumber: 'SCS229905',
      amount: 'Can$0',
    },
    {
      key: '3',
      reportedDate: '10/02/2024',
      claimDate: '09/26/2024',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'Writ of Summons',
      claimNumber: '012944213',
      amount: 'Can$1,962,820',
    },
    {
      key: '4',
      reportedDate: '08/20/2024',
      claimDate: '08/07/2024',
      legalItemType: 'Legal Suit',
      legalItemDetails: '-',
      claimNumber: '020737242',
      amount: 'Can$53,000',
    },
    {
      key: '5',
      reportedDate: '05/16/2024',
      claimDate: '05/06/2024',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'Writ of Summons',
      claimNumber: '036137248',
      amount: 'Can$177,488',
    },
    {
      key: '6',
      reportedDate: '05/10/2024',
      claimDate: '04/30/2024',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'Writ of Summons',
      claimNumber: '012332168',
      amount: 'Can$7,845,562',
    },
    {
      key: '7',
      reportedDate: '04/16/2024',
      claimDate: '04/10/2024',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'Writ of Summons',
      claimNumber: '004523241',
      amount: 'Can$903,883',
    },
    {
      key: '8',
      reportedDate: '12/14/2023',
      claimDate: '05/18/2023',
      legalItemType: 'Other Legal Info',
      legalItemDetails: 'Declar. - Settled out of Court',
      claimNumber: '014199201',
      amount: 'Can$138,914',
    },
    {
      key: '9',
      reportedDate: '10/25/2023',
      claimDate: '10/12/2023',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'Writ of Summons',
      claimNumber: '013136237',
      amount: 'Can$615,920',
    },
    {
      key: '10',
      reportedDate: '09/27/2023',
      claimDate: '09/18/2023',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'Writ of Summons',
      claimNumber: '126920233',
      amount: 'Can$1,259,539',
    },
    {
      key: '11',
      reportedDate: '05/26/2023',
      claimDate: '05/10/2023',
      legalItemType: 'Other Legal Info',
      legalItemDetails: 'Notice of Discontinuance',
      claimNumber: '005706219',
      amount: 'Can$0',
    },
    {
      key: '12',
      reportedDate: '03/24/2023',
      claimDate: '03/09/2023',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'Writ of Summons',
      claimNumber: '018589225',
      amount: 'Can$138,432',
    },
  ];

  const columns = [
    {
      title: 'Reported Date',
      dataIndex: 'reportedDate',
      key: 'reportedDate',
    },
    {
      title: 'Claim Date',
      dataIndex: 'claimDate',
      key: 'claimDate',
    },
    {
      title: 'Legal Item Type',
      dataIndex: 'legalItemType',
      key: 'legalItemType',
    },
    {
      title: 'Legal Item Details',
      dataIndex: 'legalItemDetails',
      key: 'legalItemDetails',
    },
    {
      title: 'Claim Number',
      dataIndex: 'claimNumber',
      key: 'claimNumber',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default LegalDetails;
