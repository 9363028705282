// ant icons
import {DeleteOutlined} from '@ant-design/icons';

// engagement containers
import DueDiligenceDataContainer from '../../../engagement/containers/DueDiligenceDataContainer/DueDiligenceDataContainer';

// engagement routes
import engagementDetailRoute from '../../../engagement/pages/EngagementDetailPage/route';

// engagement redux actions
import {setGeneralEngagementData as setGeneralEngagementDataAction} from '../../../engagement/redux/reducer.redux.engagement';

// engagementService routes
import engagementServiceDetailRoute from '../../../engagementService/pages/EngagementServiceDetailPage/route';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// file events
import fileDeletedEvent from '../../../file/events/deleted.event.file';
import showFileDeleteModalEvent from '../../../file/events/showDeleteModal.event.file';

// object lib
import areSameObjectIds from '../../../object/lib/areSameIds.lib.object';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// react redux
import {connect} from 'react-redux';

// react router
import {Navigate} from 'react-router-dom';

// supplier lib
import getCompanyId from '../../../supplier/lib/getCompanyId.lib.supplier';

class ComplianceFileDetailContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    dispatch: PropTypes.func,
    engagement: PropTypes.object,
    engagementFiles: PropTypes.array,
    file: PropTypes.object,
    fileVersionId: PropTypes.string,
    subscribe: PropTypes.func,
  };

  state = {
    redirect: null,
    service: null,
  };

  componentDidMount() {
    this.initialize();
    this.props.subscribe(fileDeletedEvent.subscribe(this.fileDeleted));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fileVersionId !== prevProps.fileVersionId) {
      this.initialize();
    }
  }

  initialize = () => {
    const {company, dispatch, engagement, engagementFiles, fileVersionId} =
      this.props;
    const files = [...(engagementFiles || [])];
    const file = [...files].find(({latest_file_version_id}) =>
      areSameObjectIds(latest_file_version_id, fileVersionId)
    );

    const companyId = getCompanyId(company);

    if (!file)
      return this.setState({
        redirect: engagementDetailRoute({
          companyId,
          engagementId: engagement.id,
        }),
      });

    const services = [...(engagement?.services || [])];
    const service =
      [...services].find(({id}) =>
        areSameObjectIds(id, file.engagement_service_id)
      ) || null;
    this.setState({service});

    dispatch(setGeneralEngagementDataAction({file, service: null}));
  };

  fileDeleted = ({file: deletedFile}) => {
    const {company, engagement, file} = this.props;
    if (file?.id !== deletedFile?.id) return;
    const companyId = getCompanyId(company);
    this.setState({
      redirect: engagementDetailRoute({
        companyId,
        engagementId: engagement.id,
      }),
    });
  };

  breadcrumbs = () => {
    const {company, engagement, file} = this.props;
    const {service} = this.state;
    const companyId = getCompanyId(company);
    return [
      {
        key: engagement.id,
        label: engagement.name,
        url: engagementDetailRoute({
          companyId,
          engagementId: engagement.id,
        }),
      },
      !!service &&
        engagement.services.length > 1 && {
          key: service.id,
          label: service.name,
          url: engagementServiceDetailRoute({
            companyId,
            engagementId: engagement.id,
            serviceId: service.id,
          }),
        },
      {
        key: file.id,
        label: file.name,
      },
    ].filter((item) => !!item);
  };

  actions = () => {
    return [
      {
        key: 'deleteFile',
        label: 'Close File',
        icon: <DeleteOutlined />,
        danger: true,
        onClick: this.showFileDeleteModal,
      },
    ];
  };

  complianceChartTitle = () => {
    const {file} = this.props;
    return file?.name;
  };

  text = () => {
    return {title: '', subtitle: ''};
  };

  showFileDeleteModal = () => {
    const {file} = this.props;
    showFileDeleteModalEvent.publish({file});
  };

  render() {
    const {file} = this.props;
    const {redirect} = this.state;

    return (
      <Fragment>
        {!!file && (
          <DueDiligenceDataContainer
            key={file?.id}
            complianceChartTitle={this.complianceChartTitle()}
            layoutProps={{
              breadcrumbs: this.breadcrumbs(),
              actions: this.actions(),
            }}
            textProps={this.text()}
          />
        )}
        {redirect && <Navigate to={redirect} />}
      </Fragment>
    );
  }
}

export default connect((state) => ({
  company: state.company.company,
  engagement: state.engagement.engagement,
  engagementFiles: state.engagement.engagementFiles,
  file: state.engagement.file,
}))(subscriptionHOC(ComplianceFileDetailContainer));
