import styled from 'styled-components';

const UploadContent = styled.div`
  width: 100%;
  height: ${({fullScreen, fullScreenHeight}) =>
    fullScreen ? fullScreenHeight : 'auto'};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default UploadContent;
