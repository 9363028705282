// local components
import MenuItem from '../components/MenuItem';

// local handlers
import openEngagementHandler from '../handlers/openEngagement.handler';
import openEngagementServiceHandler from '../handlers/openEngagementService.handler';

const generateBreadcrumbItems = ({companyId, engagements, navigate}) =>
  [...engagements].reduce((combined, existingEngagement, index) => {
    const services = existingEngagement?.services || [];
    return [
      ...combined,
      {
        key: existingEngagement.id,
        label: <MenuItem>{existingEngagement.name}</MenuItem>,
        onClick: openEngagementHandler({
          companyId,
          engagementId: existingEngagement.id,
          navigate,
        }),
      },
      ...[...services].map((service) => ({
        key: service.id,
        label: <MenuItem indent>{service.name}</MenuItem>,
        onClick: openEngagementServiceHandler({
          companyId,
          engagementId: existingEngagement.id,
          navigate,
          serviceId: service.id,
        }),
      })),
      ...(engagements.length - 1 !== index ? [{type: 'divider'}] : []),
    ];
  }, []);

export default generateBreadcrumbItems;
