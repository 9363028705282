import styled from 'styled-components';

// colors
import grey400Color from '../../../../layout/colors/grey400.color.layout';

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${grey400Color};
  margin: 10px 0px;
`;

export default Line;
