import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';

const DataEntryBackground = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  ${'' /* background: ${({opacity}) => `rgba(1, 165, 78, ${opacity})`}; */}
  background: ${({color}) => color || blackColor};
`;

export default DataEntryBackground;
