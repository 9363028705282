import React from 'react';
import {Table} from 'antd';

const LegalDetails = () => {
  const data = [
    {
      key: '1',
      reportedDate: '2023-08-11',
      claimDate: '2023-06-23',
      legalItemType: 'Other Legal Info',
      legalItemDetails: 'Legal Action Dismissed',
      claimNumber: 'SCS1912260',
      amount: 'Can$0',
    },
    {
      key: '2',
      reportedDate: '2023-03-30',
      claimDate: '2023-03-10',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'Statement of Claim',
      claimNumber: 'SC462-23',
      amount: 'Can$0',
    },
    {
      key: '3',
      reportedDate: '2022-07-15',
      claimDate: '2022-05-25',
      legalItemType: 'Other Legal Info',
      legalItemDetails: 'Legal Action Dismissed',
      claimNumber: 'SCS38588',
      amount: 'Can$0',
    },
    {
      key: '4',
      reportedDate: '2022-05-06',
      claimDate: '2022-01-25',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'Statement of Claim',
      claimNumber: 'SU675797-2',
      amount: 'Can$348,392',
    },
    {
      key: '5',
      reportedDate: '2021-04-19',
      claimDate: '2021-01-29',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'Statement of Claim',
      claimNumber: 'SU655913-2',
      amount: 'Can$0',
    },
    {
      key: '6',
      reportedDate: '2021-03-12',
      claimDate: '2020-11-30',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'Statement of Claim',
      claimNumber: 'SU85109-20',
      amount: 'Can$2,500,000',
    },
    {
      key: '7',
      reportedDate: '2021-02-08',
      claimDate: '2020-12-04',
      legalItemType: 'Legal Suit',
      legalItemDetails: 'Statement of Claim',
      claimNumber: 'SCS55489',
      amount: 'Can$0',
    },
    {
      key: '8',
      reportedDate: '2020-03-09',
      claimDate: '2020-01-20',
      legalItemType: 'Other Legal Info',
      legalItemDetails: 'Legal Action Dismissed',
      claimNumber: 'SU521836-1',
      amount: 'Can$0',
    },
  ].sort((a, b) => new Date(b.reportedDate) - new Date(a.reportedDate));

  const columns = [
    {
      title: 'Reported Date',
      dataIndex: 'reportedDate',
      key: 'reportedDate',
    },
    {
      title: 'Claim Date',
      dataIndex: 'claimDate',
      key: 'claimDate',
    },
    {
      title: 'Legal Item Type',
      dataIndex: 'legalItemType',
      key: 'legalItemType',
    },
    {
      title: 'Legal Item Details',
      dataIndex: 'legalItemDetails',
      key: 'legalItemDetails',
    },
    {
      title: 'Claim Number',
      dataIndex: 'claimNumber',
      key: 'claimNumber',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      style={{width: '100%'}}
    />
  );
};

export default LegalDetails;
