import React from 'react';
import {Table} from 'antd';

const CollectionDetailsTable = () => {
  const data = [
    {
      key: '1',
      reportedDate: '2024-10-04',
      claimDate: '2023-12-10',
      creditor: 'CITY OF MISSISSAUGA',
      debtor: 'BGIS',
      claimAmount: 'Can$365.72',
      amountPaid: 'Can$365.72',
      accountBalance: 'Can$0',
    },
    {
      key: '2',
      reportedDate: '2024-10-04',
      claimDate: '2023-03-27',
      creditor: 'CITY OF MISSISSAUGA',
      debtor: 'BGIS',
      claimAmount: 'Can$22,636.20',
      amountPaid: 'Can$0',
      accountBalance: 'Can$22,636.20',
    },
    {
      key: '3',
      reportedDate: '2024-10-04',
      claimDate: '2024-01-14',
      creditor: 'CITY OF MISSISSAUGA',
      debtor: 'BGIS',
      claimAmount: 'Can$370.22',
      amountPaid: 'Can$370.22',
      accountBalance: 'Can$0',
    },
    {
      key: '4',
      reportedDate: '2024-10-04',
      claimDate: '2023-08-28',
      creditor: 'CITY OF MISSISSAUGA',
      debtor: 'BGIS',
      claimAmount: 'Can$365.72',
      amountPaid: 'Can$365.72',
      accountBalance: 'Can$0',
    },
    {
      key: '5',
      reportedDate: '2024-10-04',
      claimDate: '2024-03-26',
      creditor: 'CITY OF MISSISSAUGA',
      debtor: 'BGIS',
      claimAmount: 'Can$370.20',
      amountPaid: 'Can$370.20',
      accountBalance: 'Can$0',
    },
    {
      key: '6',
      reportedDate: '2024-10-04',
      claimDate: '2024-03-26',
      creditor: 'CITY OF MISSISSAUGA',
      debtor: 'BGIS',
      claimAmount: 'Can$365.69',
      amountPaid: 'Can$365.69',
      accountBalance: 'Can$0',
    },
    {
      key: '7',
      reportedDate: '2024-09-27',
      claimDate: '2020-08-07',
      creditor: '3RD PARTY COLLECTION',
      debtor: 'BGIS GLOBAL INTEGRATED SOLUTIONS',
      claimAmount: 'Can$344.63',
      amountPaid: 'Can$0',
      accountBalance: 'Can$344.63',
    },
    {
      key: '8',
      reportedDate: '2024-09-18',
      claimDate: '2019-11-28',
      creditor: 'CANESSCO SERVICES INC',
      debtor: 'ATED SOLUTIONS BROOKFIELD GLOBAL INTERGR',
      claimAmount: 'Can$26,635.00',
      amountPaid: 'Can$26,496.00',
      accountBalance: 'Can$139.00',
    },
    {
      key: '9',
      reportedDate: '2024-07-19',
      claimDate: '2023-10-10',
      creditor: 'SHAW CABLE',
      debtor: 'CANADA LP BGIS GLOBAL INTEGRATED SO',
      claimAmount: 'Can$655.00',
      amountPaid: 'Can$0',
      accountBalance: 'Can$655.00',
    },
    {
      key: '10',
      reportedDate: '2024-06-11',
      claimDate: '2024-04-29',
      creditor: 'CITY OF MISSISSAUGA',
      debtor: 'BGIS',
      claimAmount: 'Can$370.20',
      amountPaid: 'Can$0',
      accountBalance: 'Can$370.20',
    },
    {
      key: '11',
      reportedDate: '2024-03-06',
      claimDate: '2024-01-09',
      creditor: 'SHAW CABLESYSTEMS G.P',
      debtor: 'BGIS GLOBAL INTEGRATED SOLUTIONS CANADA',
      claimAmount: 'Can$655.31',
      amountPaid: 'Can$0',
      accountBalance: 'Can$655.31',
    },
    {
      key: '12',
      reportedDate: '2023-09-27',
      claimDate: '2023-08-18',
      creditor: 'PITNEY BOWES SAP-LEASE ACCOUNTS',
      debtor: 'BROOKFIELD GLOBAL INTEGRATED',
      claimAmount: 'Can$2,634.20',
      amountPaid: 'Can$0',
      accountBalance: 'Can$2,634.20',
    },
    {
      key: '13',
      reportedDate: '2023-08-24',
      claimDate: '2023-05-25',
      creditor: 'STANLEY BLACK & DECKER CANADA',
      debtor: 'BGIS INTEGRATED FACILITY MANAGEMENT SE',
      claimAmount: 'Can$5,376.90',
      amountPaid: 'Can$5,376.90',
      accountBalance: 'Can$0',
    },
    {
      key: '14',
      reportedDate: '2023-04-05',
      claimDate: '2021-09-08',
      creditor: 'SUPERSAVE FENCE RENTALS INC.',
      debtor: 'BROOKFIELD GLOBAL INTEGRATED SOLUTIONS',
      claimAmount: 'Can$3,311.85',
      amountPaid: 'Can$0',
      accountBalance: 'Can$3,311.85',
    },
    {
      key: '15',
      reportedDate: '2023-01-11',
      claimDate: '2022-11-18',
      creditor: 'BRANDT TRACTOR LTD.',
      debtor: 'BGIS',
      claimAmount: 'Can$7,167.00',
      amountPaid: 'Can$7,167.00',
      accountBalance: 'Can$0',
    },
    {
      key: '16',
      reportedDate: '2022-06-24',
      claimDate: '2022-03-29',
      creditor: 'TOTAL FIRE PROTECTION SERVICES',
      debtor: 'BGIS WORKPLACE SOLUTIONS INC.',
      claimAmount: 'Can$573.00',
      amountPaid: 'Can$0',
      accountBalance: 'Can$573.00',
    },
    {
      key: '17',
      reportedDate: '2022-03-03',
      claimDate: '2022-01-27',
      creditor: 'INNOVAIR INDUSTRIAL (TANKS)',
      debtor: 'BROOKFIELD GLOBAL INTEGRATED S',
      claimAmount: 'Can$500.00',
      amountPaid: 'Can$0',
      accountBalance: 'Can$500.00',
    },
    {
      key: '18',
      reportedDate: '2021-01-30',
      claimDate: '2020-12-04',
      creditor: 'GFL ENVIRONMENTAL INC.',
      debtor: 'BGIS C/O AIT',
      claimAmount: 'Can$1,786.00',
      amountPaid: 'Can$0',
      accountBalance: 'Can$1,786.00',
    },
    {
      key: '19',
      reportedDate: '2020-09-17',
      claimDate: '2020-08-28',
      creditor: 'WASTE CONNECTIONS AB',
      debtor: 'BGIS GLOBAL INTEGRAT',
      claimAmount: 'Can$1,507.00',
      amountPaid: 'Can$1,507.00',
      accountBalance: 'Can$0',
    },
  ].sort((a, b) => new Date(b.reportedDate) - new Date(a.reportedDate));

  const columns = [
    {
      title: 'Reported Date',
      dataIndex: 'reportedDate',
      key: 'reportedDate',
    },
    {
      title: 'Claim Date',
      dataIndex: 'claimDate',
      key: 'claimDate',
    },
    {
      title: 'Creditor',
      dataIndex: 'creditor',
      key: 'creditor',
    },
    {
      title: 'Debtor',
      dataIndex: 'debtor',
      key: 'debtor',
    },
    {
      title: 'Claim Amount',
      dataIndex: 'claimAmount',
      key: 'claimAmount',
    },
    {
      title: 'Amount Paid',
      dataIndex: 'amountPaid',
      key: 'amountPaid',
    },
    {
      title: 'Account Balanced',
      dataIndex: 'accountBalance',
      key: 'accountBalance',
    },
  ];

  return <Table columns={columns} dataSource={data} style={{width: '100%'}} />;
};

export default CollectionDetailsTable;
