// ant components
import {message as messageApi} from 'antd';

// engagementService components
import DeleteEngagementServiceModal from '../../components/DeleteEngagementServiceModal/DeleteEngagementServiceModal';

// engagementService events
import showDeleteEngagementServiceModalEvent from '../../events/showDeleteModal.event.engagementService';

// engagementService services
import deleteEngagementServiceService from '../../services/delete.service.engagementService';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class DeleteEngagementServiceContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static INITIAL_STATE = {
    engagementService: null,
    loading: false,
    visible: false,
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    this.props.subscribe(
      showDeleteEngagementServiceModalEvent.subscribe(this.show)
    );
  }

  show = ({engagementService}) => {
    this.setState({
      ...this.constructor.INITIAL_STATE,
      visible: true,
      engagementService,
    });
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  delete = async () => {
    const {engagementService, loading} = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      await deleteEngagementServiceService({
        engagementId: engagementService.engagement_id,
        engagementServiceId: engagementService.id,
      });
      messageApi.success('Service closed');
      this.setState({loading: false, visible: false});
    } catch (error) {
      messageApi.error(error.message);
      this.setState({loading: false});
    }
  };

  render() {
    const {engagementService, loading, visible} = this.state;
    return (
      <DeleteEngagementServiceModal
        loading={loading}
        onClose={this.hide}
        onDelete={this.delete}
        service={engagementService?.name || '-'}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(DeleteEngagementServiceContainer);
