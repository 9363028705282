// ant components
import {Button} from 'antd';

// ant icons
import {PrinterOutlined} from '@ant-design/icons';

// company containers
import CompanySideMenuContainer from '../../../company/containers/CompanySideMenuContainer/CompanySideMenuContainer';

// company lib
import generateSubPages from '../../../company/lib/generateSubPages.lib.company';

// company routes
import suppliersDashboardRoute from '../../../company/pages/SuppliersDashboardPage/route';

// firmographic components
import FirmographicTables from '../FirmographicTables/FirmographicTables';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// print components
import Print from '../../../print/components/Print/Print';
import PrintLayout from '../../../print/components/PrintLayout/PrintLayout';
import PrintSection from '../../../print/components/PrintSection/PrintSection';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FirmographicsLayout = () => {
  const {company, subPage} = useSelector((state) => ({
    company: state.company.company,
    subPage: state.layout.subPage,
  }));

  const printButton = ({print, generatingAnswers}) => (
    <Button
      icon={<PrinterOutlined />}
      onClick={print}
      loading={generatingAnswers}
    >
      {generatingAnswers ? 'Preparing Report' : 'Print Report'}
    </Button>
  );

  return (
    <Print
      title="Firmographics Report"
      subtitle={company.CompanyName}
      trigger={({print}) => (
        <PageLayout
          alerts={company?.alerts || []}
          previousPageLabel="Back to Dashboard"
          previousPageUrl={suppliersDashboardRoute(company.CompanyId)}
          sideContent={({goToSection}) => (
            <CompanySideMenuContainer goToSection={goToSection} />
          )}
          subPage={subPage}
          subPages={generateSubPages(company)}
          sectionName="Firmographics"
          sections={[
            {
              section: 'Firmographics',
              component: <FirmographicTables company={company} />,
              moreButton: printButton({print}),
            },
          ].filter((section) => !!section)}
        />
      )}
    >
      <PrintLayout>
        <PrintSection title="Rating">
          <FirmographicTables company={company} printView />
        </PrintSection>
      </PrintLayout>
    </Print>
  );
};

export default FirmographicsLayout;
