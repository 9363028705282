import React from 'react';
import {Table, Badge} from 'antd';

const QuarterlyIndexValueAndPaymentHistory = () => {
  const data = [
    {
      key: '1',
      quarter: 'Q4 2024',
      paymentIndex: 30,
      creditIndex: 26,
      references: 39,
      currentOwing: 'Can$150.5K',
      period1: 'Can$151.2K',
      period2: 'Can$89.4K',
      period3: 'Can$6.3K',
      totalOwing: 'Can$397.5K',
    },
    {
      key: '2',
      quarter: 'Q3 2024',
      paymentIndex: 35,
      creditIndex: 24,
      references: 55,
      currentOwing: 'Can$82.9K',
      period1: 'Can$103.3K',
      period2: 'Can$35.7K',
      period3: 'Can$31.2K',
      totalOwing: 'Can$253.2K',
    },
    {
      key: '3',
      quarter: 'Q2 2024',
      paymentIndex: 15,
      creditIndex: 22,
      references: 64,
      currentOwing: 'Can$1.2M',
      period1: 'Can$131.1K',
      period2: 'Can$70.7K',
      period3: 'Can$116.9K',
      totalOwing: 'Can$1.5M',
    },
    {
      key: '4',
      quarter: 'Q1 2024',
      paymentIndex: 7,
      creditIndex: 22,
      references: 66,
      currentOwing: 'Can$2.4M',
      period1: 'Can$199.5K',
      period2: 'Can$112.3K',
      period3: 'Can$52.9K',
      totalOwing: 'Can$2.7M',
    },
    {
      key: '5',
      quarter: 'Q4 2023',
      paymentIndex: 34,
      creditIndex: 30,
      references: 42,
      currentOwing: 'Can$395K',
      period1: 'Can$258.7K',
      period2: 'Can$301.8K',
      period3: 'Can$59.8K',
      totalOwing: 'Can$1M',
    },
    {
      key: '6',
      quarter: 'Q3 2023',
      paymentIndex: 19,
      creditIndex: 30,
      references: 52,
      currentOwing: 'Can$323.5K',
      period1: 'Can$138.2K',
      period2: 'Can$30.5K',
      period3: 'Can$35.3K',
      totalOwing: 'Can$527.5K',
    },
    {
      key: '7',
      quarter: 'Q2 2023',
      paymentIndex: 29,
      creditIndex: 37,
      references: 45,
      currentOwing: 'Can$329K',
      period1: 'Can$87K',
      period2: 'Can$51.9K',
      period3: 'Can$105.1K',
      totalOwing: 'Can$573K',
    },
    {
      key: '8',
      quarter: 'Q1 2023',
      paymentIndex: 41,
      creditIndex: 37,
      references: 52,
      currentOwing: 'Can$59.8K',
      period1: 'Can$277.2K',
      period2: 'Can$77.4K',
      period3: 'Can$44.1K',
      totalOwing: 'Can$458.5K',
    },
    {
      key: '9',
      quarter: 'Q4 2022',
      paymentIndex: 21,
      creditIndex: 31,
      references: 52,
      currentOwing: 'Can$379.8K',
      period1: 'Can$313.5K',
      period2: 'Can$23.2K',
      period3: 'Can$34.8K',
      totalOwing: 'Can$751.2K',
    },
    {
      key: '10',
      quarter: 'Q3 2022',
      paymentIndex: 38,
      creditIndex: 37,
      references: 52,
      currentOwing: 'Can$62.7K',
      period1: 'Can$119.5K',
      period2: 'Can$36.4K',
      period3: 'Can$30.3K',
      totalOwing: 'Can$248.9K',
    },
  ];

  const columns = [
    {title: 'Quarter', dataIndex: 'quarter', key: 'quarter'},
    {
      title: 'Payment Index',
      dataIndex: 'paymentIndex',
      key: 'paymentIndex',
      render: (value) => (
        <Badge color={value > 30 ? 'green' : 'yellow'} text={value} />
      ),
    },
    {title: 'Credit Index', dataIndex: 'creditIndex', key: 'creditIndex'},
    {title: 'No. of References', dataIndex: 'references', key: 'references'},
    {title: 'Current Owing', dataIndex: 'currentOwing', key: 'currentOwing'},
    {title: 'Period 1', dataIndex: 'period1', key: 'period1'},
    {title: 'Period 2', dataIndex: 'period2', key: 'period2'},
    {title: 'Period 3', dataIndex: 'period3', key: 'period3'},
    {title: 'Total Owing', dataIndex: 'totalOwing', key: 'totalOwing'},
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default QuarterlyIndexValueAndPaymentHistory;
