// date formatters
import dateFormatter from '../../../../date/formatters/date.formatter.date';

// date lib
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import parse from 'date-fns/parse';

const showDateCreated = (file) => {
  const createdTimestamp = file?.created || 0;
  if (!createdTimestamp) return 'Unknown';
  try {
    const date = parse(createdTimestamp, 't', new Date());
    const distanceDate = formatDistanceToNow(date, {addSuffix: true});
    const actualDate = dateFormatter({
      date,
      displayDateFormat: 'yyyy/MM/dd h:mm:ss a',
    });
    return ['minute', 'minutes', 'hour', 'hours', 'day'].some((timeValue) =>
      distanceDate.includes(` ${timeValue} `)
    )
      ? distanceDate
      : actualDate;
  } catch (error) {
    return 'Unknown';
  }
};

export default showDateCreated;
