// engagementService api
import createEngagementServiceApi from '../api/create.api.engagementService';

// engagementService events
import engagementServiceCreatedEvent from '../events/created.event.engagementService';

const createEngagementServiceService = async ({
  companyId,
  engagementId,
  name,
}) => {
  // if (!name?.trim?.()?.length) throw new Error('Name is required');
  if (!companyId) throw new Error('Company is required');
  if (!engagementId) throw new Error('Engagement is required');

  const query = {
    company_id: companyId,
    engagement_id: engagementId,
  };
  if (!!name?.trim?.()?.length) query.name = name;

  const engagement = await createEngagementServiceApi(engagementId, query);

  const engagementService = {...engagement.service};

  engagementServiceCreatedEvent.publish({engagementService});

  return engagementService;
};

export default createEngagementServiceService;
