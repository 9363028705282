// company containers
import ModernSlaveryContainer from '../../containers/ModernSlaveryContainer/ModernSlaveryContainer';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const NewsPage = () => (
  <SubPage page={page}>
    <ModernSlaveryContainer />
  </SubPage>
);

export default NewsPage;
