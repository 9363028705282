// ant components
import {Table} from 'antd';

// react
import React from 'react';

const ModernSlaveryStatementsList = ({urls}) => {
  const columns = [
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
  ];

  const data = urls.map((url, index) => ({
    key: index,
    url,
  }));

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      showHeader={false}
    />
  );
};

export default ModernSlaveryStatementsList;
