import styled from 'styled-components';

// layout colors
import whiteColor from '../../../../layout/colors/white.color.layout';

// constants
const SIZE = '40px';

const ScoreTagContainer = styled.div`
  min-width: ${SIZE};
  padding: 0 8px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  position: relative;
  overflow: hidden;
  color: ${whiteColor};
`;

export default ScoreTagContainer;
