// ant components
import {LoadingOutlined} from '@ant-design/icons';

// local components
import Container from './components/Container';

// propTypes
// import PropTypes from 'prop-types';

// react
import React from 'react';

const SmallLoader = () => (
  <Container>
    <LoadingOutlined spin />
  </Container>
);

// SmallLoader.propTypes = {

// }

export default SmallLoader;
