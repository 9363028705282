import React, {useEffect} from 'react';
import {Table} from 'antd';
import Highcharts from 'highcharts';
import {v4} from 'uuid';

const TradePaymentsByCreditExtended = () => {
  const id = v4();
  const data = [
    {
      key: '1',
      creditExtended: 'Over $1M',
      tradeLines: '-',
      totalAmount: '-',
      pastDue: '-',
      withinTerms: '-',
    },
    {
      key: '2',
      creditExtended: '$100K - $1M',
      tradeLines: '-',
      totalAmount: '-',
      pastDue: '-',
      withinTerms: '-',
    },
    {
      key: '3',
      creditExtended: '$50K - $100K',
      tradeLines: '-',
      totalAmount: '-',
      pastDue: '-',
      withinTerms: '-',
    },
    {
      key: '4',
      creditExtended: '$25K - $50K',
      tradeLines: '-',
      totalAmount: '-',
      pastDue: '-',
      withinTerms: '-',
    },
    {
      key: '5',
      creditExtended: '$5K - $25K',
      tradeLines: '-',
      totalAmount: '-',
      pastDue: '-',
      withinTerms: '-',
    },
    {
      key: '6',
      creditExtended: '$1K - $5K',
      tradeLines: '-',
      totalAmount: '-',
      pastDue: '-',
      withinTerms: '-',
    },
    {
      key: '7',
      creditExtended: 'Under $1K',
      tradeLines: 9,
      totalAmount: 'Can$63',
      pastDue: 'Can$2.7K',
      withinTerms: '100%',
    },
  ];

  const columns = [
    {
      title: 'Credit Extended',
      dataIndex: 'creditExtended',
      key: 'creditExtended',
    },
    {title: 'Trade Lines', dataIndex: 'tradeLines', key: 'tradeLines'},
    {title: 'Total Amount', dataIndex: 'totalAmount', key: 'totalAmount'},
    {title: 'Past Due', dataIndex: 'pastDue', key: 'pastDue'},
    {title: 'Within Terms', dataIndex: 'withinTerms', key: 'withinTerms'},
  ];

  useEffect(() => {
    const chartOptions = {
      chart: {type: 'bar'},
      title: {text: 'Within Terms (%)'},
      xAxis: {
        categories: [
          'Over $1M',
          '$100K - $1M',
          '$50K - $100K',
          '$25K - $50K',
          '$5K - $25K',
          '$1K - $5K',
          'Under $1K',
        ],
      },
      yAxis: {min: 0, title: {text: 'Percentage'}},
      series: [
        {
          name: 'Within Terms (%)',
          data: [0, 0, 0, 0, 0, 0, 100],
          color: '#007BFF',
        },
      ],
    };
    Highcharts.chart(id, chartOptions);
  }, [id]);

  return (
    <div style={{display: 'flex', gap: '16px', flexWrap: 'wrap'}}>
      <div style={{flex: 1}}>
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
      <div style={{flex: 1}}>
        <div id={id} />
      </div>
    </div>
  );
};

export default TradePaymentsByCreditExtended;
