// local columns
import completenessColumn from '../columns/completeness.column';
import complianceColumn from '../columns/compliance.column';
import nameColumn from '../columns/name.column';

const generateColumns = (withCompliance = false) =>
  [nameColumn, withCompliance && complianceColumn, completenessColumn].filter(
    (column) => !!column
  );

export default generateColumns;
