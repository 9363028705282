import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

const DataEntry = styled.div`
  text-align: center;
  color: ${({transparent}) => (transparent ? blackColor : whiteColor)};
`;

export default DataEntry;
