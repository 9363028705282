import React from 'react';
import {Table} from 'antd';

const MostRecentFilings = () => {
  const data = [
    {
      key: '1',
      legalFiling: 'Collection',
      dateFiled: '11/13/2024',
      filingType: 'Collection',
      caseNumber: '9830005',
      status: 'Active',
      releasedDate: '-',
    },
    {
      key: '2',
      legalFiling: 'Collection',
      dateFiled: '10/25/2024',
      filingType: 'Collection',
      caseNumber: '9738655',
      status: 'Active',
      releasedDate: '-',
    },
    {
      key: '3',
      legalFiling: 'Collection',
      dateFiled: '10/10/2024',
      filingType: 'Collection',
      caseNumber: '9686958',
      status: 'Active',
      releasedDate: '-',
    },
    {
      key: '4',
      legalFiling: 'Legal',
      dateFiled: '10/03/2024',
      filingType: 'Legal Suit',
      caseNumber: '031607244',
      status: 'NA',
      releasedDate: '-',
    },
    {
      key: '5',
      legalFiling: 'Liens',
      dateFiled: '09/16/2024',
      filingType: 'Liens',
      caseNumber: '509175774',
      status: 'NA',
      releasedDate: '-',
    },
  ];

  const columns = [
    {title: 'Legal Filing', dataIndex: 'legalFiling', key: 'legalFiling'},
    {title: 'Date Filed', dataIndex: 'dateFiled', key: 'dateFiled'},
    {title: 'Filing Type', dataIndex: 'filingType', key: 'filingType'},
    {title: 'Case Number', dataIndex: 'caseNumber', key: 'caseNumber'},
    {title: 'Status', dataIndex: 'status', key: 'status'},
    {title: 'Released Date', dataIndex: 'releasedDate', key: 'releasedDate'},
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default MostRecentFilings;
