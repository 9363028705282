import React from 'react';
import {Table} from 'antd';

const LiensTable = ({printView = false}) => {
  const data = [
    {
      key: '509175765',
      reportedDate: '09/27/2024',
      lienNumber: '509175765',
      value: 'Can$49857',
      cautionFiling: '-',
      collateral: 'CONSUMER GOODS',
      dateRegistered: '09/16/2024',
      dateAmended: '-',
    },
    {
      key: '509175774',
      reportedDate: '09/27/2024',
      lienNumber: '509175774',
      value: 'Can$49857',
      cautionFiling: '-',
      collateral: 'CONSUMER GOODS',
      dateRegistered: '09/16/2024',
      dateAmended: '-',
    },
    {
      key: '508850811',
      reportedDate: '09/13/2024',
      lienNumber: '508850811',
      value: 'Can$50185',
      cautionFiling: '-',
      collateral: 'CONSUMER GOODS',
      dateRegistered: '09/04/2024',
      dateAmended: '-',
    },
    {
      key: '508260078',
      reportedDate: '08/30/2024',
      lienNumber: '508260078',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '08/15/2024',
      dateAmended: '-',
    },
    {
      key: '508124943',
      reportedDate: '08/30/2024',
      lienNumber: '508124943',
      value: 'Can$49908',
      cautionFiling: '-',
      collateral: 'CONSUMER GOODS',
      dateRegistered: '08/12/2024',
      dateAmended: '-',
    },
    {
      key: '508124934',
      reportedDate: '08/30/2024',
      lienNumber: '508124934',
      value: 'Can$49908',
      cautionFiling: '-',
      collateral: 'CONSUMER GOODS',
      dateRegistered: '08/12/2024',
      dateAmended: '-',
    },
    {
      key: '507159063',
      reportedDate: '08/13/2024',
      lienNumber: '507159063',
      value: 'Can$4669',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '07/10/2024',
      dateAmended: '-',
    },
    {
      key: '506652381',
      reportedDate: '08/01/2024',
      lienNumber: '506652381',
      value: 'Can$73224',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '06/24/2024',
      dateAmended: '-',
    },
    {
      key: '506653587',
      reportedDate: '08/01/2024',
      lienNumber: '506653587',
      value: 'Can$40680',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '06/24/2024',
      dateAmended: '-',
    },
    {
      key: '506683539',
      reportedDate: '08/01/2024',
      lienNumber: '506683539',
      value: 'Can$75000',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '06/25/2024',
      dateAmended: '-',
    },
    {
      key: '506351907',
      reportedDate: '07/10/2024',
      lienNumber: '506351907',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '06/13/2024',
      dateAmended: '09/18/2024',
    },
    {
      key: '759560544',
      reportedDate: '05/31/2024',
      lienNumber: '759560544',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '01/24/2020',
      dateAmended: '02/16/2021',
    },
    {
      key: '794951667',
      reportedDate: '05/31/2024',
      lienNumber: '794951667',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '07/05/2023',
      dateAmended: '-',
    },
    {
      key: '775718577',
      reportedDate: '05/31/2024',
      lienNumber: '775718577',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '08/24/2021',
      dateAmended: '-',
    },
    {
      key: '501254919',
      reportedDate: '05/31/2024',
      lienNumber: '501254919',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/15/2023',
      dateAmended: '12/18/2023',
    },
    {
      key: '774222786',
      reportedDate: '05/31/2024',
      lienNumber: '774222786',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '07/08/2021',
      dateAmended: '-',
    },
    {
      key: '775701873',
      reportedDate: '05/31/2024',
      lienNumber: '775701873',
      value: 'Can$176520',
      cautionFiling: '-',
      collateral: 'INVENTORY',
      dateRegistered: '08/24/2021',
      dateAmended: '-',
    },
    {
      key: '778375206',
      reportedDate: '05/31/2024',
      lienNumber: '778375206',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '11/19/2021',
      dateAmended: '-',
    },
    {
      key: '790535034',
      reportedDate: '05/31/2024',
      lienNumber: '790535034',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/06/2023',
      dateAmended: '01/11/2024',
    },
    {
      key: '786907548',
      reportedDate: '05/31/2024',
      lienNumber: '786907548',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '09/21/2022',
      dateAmended: '-',
    },
    {
      key: '791277714',
      reportedDate: '05/31/2024',
      lienNumber: '791277714',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '03/07/2023',
      dateAmended: '01/11/2024',
    },
    {
      key: '779287365',
      reportedDate: '05/31/2024',
      lienNumber: '779287365',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '12/24/2021',
      dateAmended: '-',
    },
    {
      key: '776500065',
      reportedDate: '05/31/2024',
      lienNumber: '776500065',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '09/17/2021',
      dateAmended: '09/12/2023',
    },
    {
      key: '765638748',
      reportedDate: '05/31/2024',
      lienNumber: '765638748',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '09/10/2020',
      dateAmended: '-',
    },
    {
      key: '501448932',
      reportedDate: '05/31/2024',
      lienNumber: '501448932',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/22/2023',
      dateAmended: '-',
    },
    {
      key: '791277732',
      reportedDate: '05/31/2024',
      lienNumber: '791277732',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '03/07/2023',
      dateAmended: '01/11/2024',
    },
    {
      key: '761193621',
      reportedDate: '05/31/2024',
      lienNumber: '761193621',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '03/24/2020',
      dateAmended: '03/25/2020',
    },
    {
      key: '502949241',
      reportedDate: '05/31/2024',
      lienNumber: '502949241',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/23/2024',
      dateAmended: '-',
    },
    {
      key: '777709431',
      reportedDate: '05/31/2024',
      lienNumber: '777709431',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '10/28/2021',
      dateAmended: '-',
    },
    {
      key: '500967414',
      reportedDate: '05/31/2024',
      lienNumber: '500967414',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/05/2023',
      dateAmended: '12/07/2023',
    },
    {
      key: '786507525',
      reportedDate: '05/31/2024',
      lienNumber: '786507525',
      value: 'Can$15000',
      cautionFiling: '-',
      collateral: 'CONSUMER GOODS',
      dateRegistered: '09/07/2022',
      dateAmended: '04/24/2023',
    },
    {
      key: '501255396',
      reportedDate: '05/31/2024',
      lienNumber: '501255396',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/15/2023',
      dateAmended: '-',
    },
    {
      key: '768882933',
      reportedDate: '05/31/2024',
      lienNumber: '768882933',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '12/29/2020',
      dateAmended: '09/12/2023',
    },
    {
      key: '797861835',
      reportedDate: '05/31/2024',
      lienNumber: '797861835',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '10/06/2023',
      dateAmended: '-',
    },
    {
      key: '501666066',
      reportedDate: '05/31/2024',
      lienNumber: '501666066',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '01/03/2024',
      dateAmended: '-',
    },
    {
      key: '780859944',
      reportedDate: '05/31/2024',
      lienNumber: '780859944',
      value: 'Can$76749',
      cautionFiling: '-',
      collateral: 'INVENTORY',
      dateRegistered: '03/04/2022',
      dateAmended: '-',
    },
    {
      key: '502476606',
      reportedDate: '05/31/2024',
      lienNumber: '502476606',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '02/05/2024',
      dateAmended: '-',
    },
    {
      key: '780859836',
      reportedDate: '05/31/2024',
      lienNumber: '780859836',
      value: 'Can$73500',
      cautionFiling: '-',
      collateral: 'INVENTORY',
      dateRegistered: '03/04/2022',
      dateAmended: '-',
    },
    {
      key: '792484173',
      reportedDate: '05/31/2024',
      lienNumber: '792484173',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '04/19/2023',
      dateAmended: '-',
    },
    {
      key: '792470817',
      reportedDate: '05/31/2024',
      lienNumber: '792470817',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '04/18/2023',
      dateAmended: '-',
    },
    {
      key: '783784755',
      reportedDate: '05/31/2024',
      lienNumber: '783784755',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '06/08/2022',
      dateAmended: '04/11/2024',
    },
    {
      key: '501547806',
      reportedDate: '05/31/2024',
      lienNumber: '501547806',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/28/2023',
      dateAmended: '-',
    },
    {
      key: '778640364',
      reportedDate: '05/31/2024',
      lienNumber: '778640364',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '11/30/2021',
      dateAmended: '-',
    },
    {
      key: '500149035',
      reportedDate: '05/31/2024',
      lienNumber: '500149035',
      value: 'Can$78000',
      cautionFiling: '-',
      collateral: 'CONSUMER GOODS, EQUIPMENT',
      dateRegistered: '11/08/2023',
      dateAmended: '11/21/2023',
    },
    {
      key: '776871225',
      reportedDate: '05/31/2024',
      lienNumber: '776871225',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '09/29/2021',
      dateAmended: '11/20/2023',
    },
    {
      key: '792471213',
      reportedDate: '05/31/2024',
      lienNumber: '792471213',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '04/18/2023',
      dateAmended: '-',
    },
    {
      key: '786584061',
      reportedDate: '05/31/2024',
      lienNumber: '786584061',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '09/09/2022',
      dateAmended: '11/07/2022',
    },
    {
      key: '501548499',
      reportedDate: '05/31/2024',
      lienNumber: '501548499',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/28/2023',
      dateAmended: '-',
    },
    {
      key: '792468459',
      reportedDate: '05/31/2024',
      lienNumber: '792468459',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '04/18/2023',
      dateAmended: '-',
    },
    {
      key: '775804743',
      reportedDate: '05/31/2024',
      lienNumber: '775804743',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '08/26/2021',
      dateAmended: '-',
    },
    {
      key: '796916178',
      reportedDate: '05/31/2024',
      lienNumber: '796916178',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '09/06/2023',
      dateAmended: '-',
    },
    {
      key: '794764593',
      reportedDate: '05/31/2024',
      lienNumber: '794764593',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '06/28/2023',
      dateAmended: '-',
    },
    {
      key: '500679099',
      reportedDate: '05/31/2024',
      lienNumber: '500679099',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '11/27/2023',
      dateAmended: '-',
    },
    {
      key: '502008246',
      reportedDate: '05/31/2024',
      lienNumber: '502008246',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '01/17/2024',
      dateAmended: '-',
    },
    {
      key: '501440679',
      reportedDate: '05/31/2024',
      lienNumber: '501440679',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/22/2023',
      dateAmended: '-',
    },
    {
      key: '780572907',
      reportedDate: '05/31/2024',
      lienNumber: '780572907',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/23/2022',
      dateAmended: '01/06/2023',
    },
    {
      key: '502179507',
      reportedDate: '05/31/2024',
      lienNumber: '502179507',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '01/24/2024',
      dateAmended: '-',
    },
    {
      key: '790253424',
      reportedDate: '05/31/2024',
      lienNumber: '790253424',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '01/26/2023',
      dateAmended: '-',
    },
    {
      key: '788331564',
      reportedDate: '05/31/2024',
      lienNumber: '788331564',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '11/09/2022',
      dateAmended: '03/31/2023',
    },
    {
      key: '790247835',
      reportedDate: '05/31/2024',
      lienNumber: '790247835',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '01/26/2023',
      dateAmended: '-',
    },
    {
      key: '784600344',
      reportedDate: '05/31/2024',
      lienNumber: '784600344',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '07/05/2022',
      dateAmended: '-',
    },
    {
      key: '777443445',
      reportedDate: '05/31/2024',
      lienNumber: '777443445',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '10/19/2021',
      dateAmended: '06/22/2022',
    },
    {
      key: '501493095',
      reportedDate: '05/31/2024',
      lienNumber: '501493095',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/27/2023',
      dateAmended: '-',
    },
    {
      key: '790535052',
      reportedDate: '05/31/2024',
      lienNumber: '790535052',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/06/2023',
      dateAmended: '01/11/2024',
    },
    {
      key: '768679506',
      reportedDate: '05/31/2024',
      lienNumber: '768679506',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/18/2020',
      dateAmended: '03/05/2021',
    },
    {
      key: '502358751',
      reportedDate: '05/31/2024',
      lienNumber: '502358751',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '01/31/2024',
      dateAmended: '-',
    },
    {
      key: '768651912',
      reportedDate: '05/31/2024',
      lienNumber: '768651912',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '12/17/2020',
      dateAmended: '05/31/2022',
    },
    {
      key: '778372452',
      reportedDate: '05/31/2024',
      lienNumber: '778372452',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '11/19/2021',
      dateAmended: '-',
    },
    {
      key: '796508271',
      reportedDate: '05/31/2024',
      lienNumber: '796508271',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '08/23/2023',
      dateAmended: '06/26/2024',
    },
    {
      key: '796518027',
      reportedDate: '05/31/2024',
      lienNumber: '796518027',
      value: 'Can$5254',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '08/24/2023',
      dateAmended: '12/11/2023',
    },
    {
      key: '769683681',
      reportedDate: '05/31/2024',
      lienNumber: '769683681',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '02/04/2021',
      dateAmended: '12/15/2022',
    },
    {
      key: '788717691',
      reportedDate: '05/31/2024',
      lienNumber: '788717691',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '11/23/2022',
      dateAmended: '-',
    },
    {
      key: '501260184',
      reportedDate: '05/31/2024',
      lienNumber: '501260184',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/15/2023',
      dateAmended: '-',
    },
    {
      key: '500177628',
      reportedDate: '05/31/2024',
      lienNumber: '500177628',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '11/09/2023',
      dateAmended: '-',
    },
    {
      key: '794966031',
      reportedDate: '05/31/2024',
      lienNumber: '794966031',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '07/05/2023',
      dateAmended: '-',
    },
    {
      key: '764950905',
      reportedDate: '05/31/2024',
      lienNumber: '764950905',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '08/20/2020',
      dateAmended: '05/31/2022',
    },
    {
      key: '501407325',
      reportedDate: '05/31/2024',
      lienNumber: '501407325',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/21/2023',
      dateAmended: '-',
    },
    {
      key: '798643962',
      reportedDate: '05/31/2024',
      lienNumber: '798643962',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '11/01/2023',
      dateAmended: '-',
    },
    {
      key: '759975237',
      reportedDate: '05/31/2024',
      lienNumber: '759975237',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/07/2020',
      dateAmended: '-',
    },
    {
      key: '759560535',
      reportedDate: '05/31/2024',
      lienNumber: '759560535',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '01/24/2020',
      dateAmended: '12/01/2021',
    },
    {
      key: '761334336',
      reportedDate: '05/31/2024',
      lienNumber: '761334336',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT',
      dateRegistered: '03/31/2020',
      dateAmended: '-',
    },
    {
      key: '769035348',
      reportedDate: '05/31/2024',
      lienNumber: '769035348',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '01/06/2021',
      dateAmended: '04/26/2021',
    },
    {
      key: '501409854',
      reportedDate: '05/31/2024',
      lienNumber: '501409854',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/21/2023',
      dateAmended: '-',
    },
    {
      key: '780572988',
      reportedDate: '05/31/2024',
      lienNumber: '780572988',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/23/2022',
      dateAmended: '01/06/2023',
    },
    {
      key: '768882942',
      reportedDate: '05/31/2024',
      lienNumber: '768882942',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '12/29/2020',
      dateAmended: '01/28/2022',
    },
    {
      key: '786907539',
      reportedDate: '05/31/2024',
      lienNumber: '786907539',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '09/21/2022',
      dateAmended: '-',
    },
    {
      key: '504822078',
      reportedDate: '05/31/2024',
      lienNumber: '504822078',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '04/26/2024',
      dateAmended: '-',
    },
    {
      key: '769809753',
      reportedDate: '05/31/2024',
      lienNumber: '769809753',
      value: '-',
      cautionFiling: '-',
      collateral: 'OTHER',
      dateRegistered: '02/10/2021',
      dateAmended: '-',
    },
    {
      key: '770124222',
      reportedDate: '05/31/2024',
      lienNumber: '770124222',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/25/2021',
      dateAmended: '04/23/2021',
    },
    {
      key: '500735223',
      reportedDate: '05/31/2024',
      lienNumber: '500735223',
      value: 'Can$78000',
      cautionFiling: '-',
      collateral: 'CONSUMER GOODS, EQUIPMENT',
      dateRegistered: '11/28/2023',
      dateAmended: '-',
    },
    {
      key: '501442443',
      reportedDate: '05/31/2024',
      lienNumber: '501442443',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/22/2023',
      dateAmended: '-',
    },
    {
      key: '797035563',
      reportedDate: '05/31/2024',
      lienNumber: '797035563',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '09/11/2023',
      dateAmended: '-',
    },
    {
      key: '783870165',
      reportedDate: '05/31/2024',
      lienNumber: '783870165',
      value: '-',
      cautionFiling: '-',
      collateral: 'CONSUMER GOODS',
      dateRegistered: '06/10/2022',
      dateAmended: '01/26/2023',
    },
    {
      key: '760193865',
      reportedDate: '05/31/2024',
      lienNumber: '760193865',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '02/18/2020',
      dateAmended: '-',
    },
    {
      key: '501406101',
      reportedDate: '05/31/2024',
      lienNumber: '501406101',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/21/2023',
      dateAmended: '-',
    },
    {
      key: '761294052',
      reportedDate: '05/31/2024',
      lienNumber: '761294052',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '03/30/2020',
      dateAmended: '-',
    },
    {
      key: '501837174',
      reportedDate: '05/31/2024',
      lienNumber: '501837174',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '01/10/2024',
      dateAmended: '-',
    },
    {
      key: '790680636',
      reportedDate: '05/31/2024',
      lienNumber: '790680636',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/10/2023',
      dateAmended: '-',
    },
    {
      key: '501160491',
      reportedDate: '05/31/2024',
      lienNumber: '501160491',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/12/2023',
      dateAmended: '-',
    },
    {
      key: '791277705',
      reportedDate: '05/31/2024',
      lienNumber: '791277705',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '03/07/2023',
      dateAmended: '01/11/2024',
    },
    {
      key: '501260445',
      reportedDate: '05/31/2024',
      lienNumber: '501260445',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/15/2023',
      dateAmended: '-',
    },
    {
      key: '778375062',
      reportedDate: '05/31/2024',
      lienNumber: '778375062',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '11/19/2021',
      dateAmended: '-',
    },
    {
      key: '796508289',
      reportedDate: '05/31/2024',
      lienNumber: '796508289',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '08/23/2023',
      dateAmended: '06/26/2024',
    },
    {
      key: '504520137',
      reportedDate: '05/31/2024',
      lienNumber: '504520137',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '04/17/2024',
      dateAmended: '-',
    },
    {
      key: '501438204',
      reportedDate: '05/31/2024',
      lienNumber: '501438204',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/22/2023',
      dateAmended: '-',
    },
    {
      key: '774433368',
      reportedDate: '05/31/2024',
      lienNumber: '774433368',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '07/15/2021',
      dateAmended: '-',
    },
    {
      key: '502020891',
      reportedDate: '05/31/2024',
      lienNumber: '502020891',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '01/17/2024',
      dateAmended: '-',
    },
    {
      key: '501438591',
      reportedDate: '05/31/2024',
      lienNumber: '501438591',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/22/2023',
      dateAmended: '-',
    },
    {
      key: '759560517',
      reportedDate: '05/31/2024',
      lienNumber: '759560517',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '01/24/2020',
      dateAmended: '09/22/2020',
    },
    {
      key: '761608017',
      reportedDate: '05/31/2024',
      lienNumber: '761608017',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '04/27/2020',
      dateAmended: '05/31/2022',
    },
    {
      key: '502143588',
      reportedDate: '05/31/2024',
      lienNumber: '502143588',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '01/23/2024',
      dateAmended: '-',
    },
    {
      key: '768727494',
      reportedDate: '05/31/2024',
      lienNumber: '768727494',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '12/21/2020',
      dateAmended: '-',
    },
    {
      key: '789306633',
      reportedDate: '05/31/2024',
      lienNumber: '789306633',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '12/14/2022',
      dateAmended: '-',
    },
    {
      key: '790249356',
      reportedDate: '05/31/2024',
      lienNumber: '790249356',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '01/26/2023',
      dateAmended: '-',
    },
    {
      key: '770433291',
      reportedDate: '05/31/2024',
      lienNumber: '770433291',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '03/09/2021',
      dateAmended: '03/11/2022',
    },
    {
      key: '501406704',
      reportedDate: '05/31/2024',
      lienNumber: '501406704',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/21/2023',
      dateAmended: '-',
    },
    {
      key: '797882445',
      reportedDate: '05/31/2024',
      lienNumber: '797882445',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '10/06/2023',
      dateAmended: '07/26/2024',
    },
    {
      key: '780120585',
      reportedDate: '05/31/2024',
      lienNumber: '780120585',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '02/02/2022',
      dateAmended: '02/16/2024',
    },
    {
      key: '768882996',
      reportedDate: '05/31/2024',
      lienNumber: '768882996',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '12/29/2020',
      dateAmended: '01/28/2022',
    },
    {
      key: '502754661',
      reportedDate: '05/31/2024',
      lienNumber: '502754661',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '02/15/2024',
      dateAmended: '-',
    },
    {
      key: '500983551',
      reportedDate: '05/31/2024',
      lienNumber: '500983551',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '12/06/2023',
      dateAmended: '-',
    },
    {
      key: '788373225',
      reportedDate: '05/31/2024',
      lienNumber: '788373225',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '11/10/2022',
      dateAmended: '-',
    },
    {
      key: '785074959',
      reportedDate: '05/31/2024',
      lienNumber: '785074959',
      value: 'Can$42000',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '07/20/2022',
      dateAmended: '06/20/2023',
    },
    {
      key: '502171947',
      reportedDate: '05/31/2024',
      lienNumber: '502171947',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '01/24/2024',
      dateAmended: '-',
    },
    {
      key: '790251039',
      reportedDate: '05/31/2024',
      lienNumber: '790251039',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '01/26/2023',
      dateAmended: '-',
    },
    {
      key: '788338611',
      reportedDate: '05/31/2024',
      lienNumber: '788338611',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '11/09/2022',
      dateAmended: '-',
    },
    {
      key: '502949232',
      reportedDate: '05/31/2024',
      lienNumber: '502949232',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/23/2024',
      dateAmended: '-',
    },
    {
      key: '781681284',
      reportedDate: '05/31/2024',
      lienNumber: '781681284',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '04/01/2022',
      dateAmended: '-',
    },
    {
      key: '778374837',
      reportedDate: '05/31/2024',
      lienNumber: '778374837',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '11/19/2021',
      dateAmended: '-',
    },
    {
      key: '759619971',
      reportedDate: '05/31/2024',
      lienNumber: '759619971',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '01/27/2020',
      dateAmended: '-',
    },
    {
      key: '768727476',
      reportedDate: '05/31/2024',
      lienNumber: '768727476',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '12/21/2020',
      dateAmended: '-',
    },
    {
      key: '768882969',
      reportedDate: '05/31/2024',
      lienNumber: '768882969',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '12/29/2020',
      dateAmended: '01/28/2022',
    },
    {
      key: '769683699',
      reportedDate: '05/31/2024',
      lienNumber: '769683699',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '02/04/2021',
      dateAmended: '12/15/2022',
    },
    {
      key: '792728154',
      reportedDate: '05/31/2024',
      lienNumber: '792728154',
      value: 'Can$1171444',
      cautionFiling: 'Y',
      collateral: 'EQUIPMENT',
      dateRegistered: '04/26/2023',
      dateAmended: '05/19/2023',
    },
    {
      key: '780813999',
      reportedDate: '05/31/2024',
      lienNumber: '780813999',
      value: 'Can$76749',
      cautionFiling: '-',
      collateral: 'INVENTORY',
      dateRegistered: '03/03/2022',
      dateAmended: '-',
    },
    {
      key: '790249302',
      reportedDate: '05/31/2024',
      lienNumber: '790249302',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '01/26/2023',
      dateAmended: '-',
    },
    {
      key: '773296263',
      reportedDate: '05/31/2024',
      lienNumber: '773296263',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '06/09/2021',
      dateAmended: '-',
    },
    {
      key: '501255162',
      reportedDate: '05/31/2024',
      lienNumber: '501255162',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/15/2023',
      dateAmended: '12/18/2023',
    },
    {
      key: '766054026',
      reportedDate: '05/31/2024',
      lienNumber: '766054026',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '09/23/2020',
      dateAmended: '-',
    },
    {
      key: '782698248',
      reportedDate: '05/31/2024',
      lienNumber: '782698248',
      value: 'Can$71319',
      cautionFiling: '-',
      collateral: 'INVENTORY',
      dateRegistered: '05/05/2022',
      dateAmended: '05/02/2023',
    },
    {
      key: '760447593',
      reportedDate: '05/31/2024',
      lienNumber: '760447593',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '02/27/2020',
      dateAmended: '12/05/2022',
    },
    {
      key: '760193883',
      reportedDate: '05/31/2024',
      lienNumber: '760193883',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '02/18/2020',
      dateAmended: '-',
    },
    {
      key: '501260292',
      reportedDate: '05/31/2024',
      lienNumber: '501260292',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/15/2023',
      dateAmended: '-',
    },
    {
      key: '768882978',
      reportedDate: '05/31/2024',
      lienNumber: '768882978',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '12/29/2020',
      dateAmended: '01/28/2022',
    },
    {
      key: '501502293',
      reportedDate: '05/31/2024',
      lienNumber: '501502293',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/27/2023',
      dateAmended: '-',
    },
    {
      key: '500872995',
      reportedDate: '05/31/2024',
      lienNumber: '500872995',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '12/01/2023',
      dateAmended: '01/11/2024',
    },
    {
      key: '759560526',
      reportedDate: '05/31/2024',
      lienNumber: '759560526',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '01/24/2020',
      dateAmended: '03/16/2022',
    },
    {
      key: '505290033',
      reportedDate: '05/31/2024',
      lienNumber: '505290033',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '05/10/2024',
      dateAmended: '-',
    },
    {
      key: '501493761',
      reportedDate: '05/31/2024',
      lienNumber: '501493761',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/27/2023',
      dateAmended: '-',
    },
    {
      key: '502358697',
      reportedDate: '05/31/2024',
      lienNumber: '502358697',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '01/31/2024',
      dateAmended: '-',
    },
    {
      key: '793901889',
      reportedDate: '05/31/2024',
      lienNumber: '793901889',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '06/01/2023',
      dateAmended: '04/02/2024',
    },
    {
      key: '793674954',
      reportedDate: '05/31/2024',
      lienNumber: '793674954',
      value: 'Can$25000',
      cautionFiling: '-',
      collateral: 'CONSUMER GOODS',
      dateRegistered: '05/26/2023',
      dateAmended: '09/26/2023',
    },
    {
      key: '780572808',
      reportedDate: '05/31/2024',
      lienNumber: '780572808',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/23/2022',
      dateAmended: '01/06/2023',
    },
    {
      key: '788197977',
      reportedDate: '05/31/2024',
      lienNumber: '788197977',
      value: 'Can$15000',
      cautionFiling: '-',
      collateral: 'CONSUMER GOODS',
      dateRegistered: '11/04/2022',
      dateAmended: '04/24/2023',
    },
    {
      key: '780572385',
      reportedDate: '05/31/2024',
      lienNumber: '780572385',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/23/2022',
      dateAmended: '01/06/2023',
    },
    {
      key: '782617986',
      reportedDate: '05/31/2024',
      lienNumber: '782617986',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '05/03/2022',
      dateAmended: '09/25/2023',
    },
    {
      key: '776499921',
      reportedDate: '05/31/2024',
      lienNumber: '776499921',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '09/17/2021',
      dateAmended: '05/31/2022',
    },
    {
      key: '500203359',
      reportedDate: '05/31/2024',
      lienNumber: '500203359',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '11/10/2023',
      dateAmended: '-',
    },
    {
      key: '501502212',
      reportedDate: '05/31/2024',
      lienNumber: '501502212',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/27/2023',
      dateAmended: '-',
    },
    {
      key: '772024581',
      reportedDate: '05/31/2024',
      lienNumber: '772024581',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '04/29/2021',
      dateAmended: '-',
    },
    {
      key: '780572646',
      reportedDate: '05/31/2024',
      lienNumber: '780572646',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/23/2022',
      dateAmended: '01/06/2023',
    },
    {
      key: '501347376',
      reportedDate: '05/31/2024',
      lienNumber: '501347376',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/19/2023',
      dateAmended: '-',
    },
    {
      key: '502949286',
      reportedDate: '05/31/2024',
      lienNumber: '502949286',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/23/2024',
      dateAmended: '-',
    },
    {
      key: '767957157',
      reportedDate: '05/31/2024',
      lienNumber: '767957157',
      value: 'Can$112841',
      cautionFiling: '-',
      collateral: 'OTHER',
      dateRegistered: '11/24/2020',
      dateAmended: '05/24/2024',
    },
    {
      key: '780896637',
      reportedDate: '05/31/2024',
      lienNumber: '780896637',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '03/07/2022',
      dateAmended: '08/01/2023',
    },
    {
      key: '503023068',
      reportedDate: '05/31/2024',
      lienNumber: '503023068',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '02/27/2024',
      dateAmended: '-',
    },
    {
      key: '502179372',
      reportedDate: '05/31/2024',
      lienNumber: '502179372',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '01/24/2024',
      dateAmended: '-',
    },
    {
      key: '500965542',
      reportedDate: '05/31/2024',
      lienNumber: '500965542',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/05/2023',
      dateAmended: '-',
    },
    {
      key: '501420375',
      reportedDate: '05/31/2024',
      lienNumber: '501420375',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/21/2023',
      dateAmended: '-',
    },
    {
      key: '768882915',
      reportedDate: '05/31/2024',
      lienNumber: '768882915',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '12/29/2020',
      dateAmended: '09/12/2023',
    },
    {
      key: '502949268',
      reportedDate: '05/31/2024',
      lienNumber: '502949268',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/23/2024',
      dateAmended: '-',
    },
    {
      key: '501347709',
      reportedDate: '05/31/2024',
      lienNumber: '501347709',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/19/2023',
      dateAmended: '-',
    },
    {
      key: '761191893',
      reportedDate: '05/31/2024',
      lienNumber: '761191893',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '03/24/2020',
      dateAmended: '01/28/2021',
    },
    {
      key: '791277741',
      reportedDate: '05/31/2024',
      lienNumber: '791277741',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '03/07/2023',
      dateAmended: '01/11/2024',
    },
    {
      key: '781686819',
      reportedDate: '05/31/2024',
      lienNumber: '781686819',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '04/01/2022',
      dateAmended: '-',
    },
    {
      key: '770450895',
      reportedDate: '05/31/2024',
      lienNumber: '770450895',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '03/10/2021',
      dateAmended: '-',
    },
    {
      key: '500735385',
      reportedDate: '05/31/2024',
      lienNumber: '500735385',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '11/28/2023',
      dateAmended: '-',
    },
    {
      key: '780142392',
      reportedDate: '05/31/2024',
      lienNumber: '780142392',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '02/02/2022',
      dateAmended: '01/09/2023',
    },
    {
      key: '502837497',
      reportedDate: '05/31/2024',
      lienNumber: '502837497',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/20/2024',
      dateAmended: '07/25/2024',
    },
    {
      key: '760447584',
      reportedDate: '05/31/2024',
      lienNumber: '760447584',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '02/27/2020',
      dateAmended: '12/05/2022',
    },
    {
      key: '797458806',
      reportedDate: '05/31/2024',
      lienNumber: '797458806',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '09/25/2023',
      dateAmended: '07/26/2024',
    },
    {
      key: '501347232',
      reportedDate: '05/31/2024',
      lienNumber: '501347232',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/19/2023',
      dateAmended: '-',
    },
    {
      key: '792079938',
      reportedDate: '05/31/2024',
      lienNumber: '792079938',
      value: '-',
      cautionFiling: '-',
      collateral: 'CONSUMER GOODS',
      dateRegistered: '04/04/2023',
      dateAmended: '04/04/2023',
    },
    {
      key: '780572484',
      reportedDate: '05/31/2024',
      lienNumber: '780572484',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/23/2022',
      dateAmended: '01/06/2023',
    },
    {
      key: '764570997',
      reportedDate: '05/31/2024',
      lienNumber: '764570997',
      value: 'Can$187206',
      cautionFiling: '-',
      collateral: 'INVENTORY',
      dateRegistered: '08/10/2020',
      dateAmended: '-',
    },
    {
      key: '502174224',
      reportedDate: '05/31/2024',
      lienNumber: '502174224',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '01/24/2024',
      dateAmended: '-',
    },
    {
      key: '762614505',
      reportedDate: '05/31/2024',
      lienNumber: '762614505',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '06/11/2020',
      dateAmended: '12/03/2020',
    },
    {
      key: '774224064',
      reportedDate: '05/31/2024',
      lienNumber: '774224064',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '07/08/2021',
      dateAmended: '-',
    },
    {
      key: '769758084',
      reportedDate: '05/31/2024',
      lienNumber: '769758084',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/09/2021',
      dateAmended: '04/25/2023',
    },
    {
      key: '504120798',
      reportedDate: '05/31/2024',
      lienNumber: '504120798',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '04/03/2024',
      dateAmended: '-',
    },
    {
      key: '503912088',
      reportedDate: '05/31/2024',
      lienNumber: '503912088',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '03/28/2024',
      dateAmended: '-',
    },
    {
      key: '771024357',
      reportedDate: '05/31/2024',
      lienNumber: '771024357',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '03/30/2021',
      dateAmended: '-',
    },
    {
      key: '502476624',
      reportedDate: '05/31/2024',
      lienNumber: '502476624',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '02/05/2024',
      dateAmended: '-',
    },
    {
      key: '790499106',
      reportedDate: '05/31/2024',
      lienNumber: '790499106',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '02/03/2023',
      dateAmended: '-',
    },
    {
      key: '790253244',
      reportedDate: '05/31/2024',
      lienNumber: '790253244',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '01/26/2023',
      dateAmended: '-',
    },
    {
      key: '761525901',
      reportedDate: '05/31/2024',
      lienNumber: '761525901',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '04/20/2020',
      dateAmended: '12/03/2020',
    },
    {
      key: '792607365',
      reportedDate: '05/31/2024',
      lienNumber: '792607365',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '04/24/2023',
      dateAmended: '08/15/2024',
    },
    {
      key: '501136938',
      reportedDate: '05/31/2024',
      lienNumber: '501136938',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '12/12/2023',
      dateAmended: '-',
    },
    {
      key: '502949214',
      reportedDate: '05/31/2024',
      lienNumber: '502949214',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/23/2024',
      dateAmended: '-',
    },
    {
      key: '760174857',
      reportedDate: '05/31/2024',
      lienNumber: '760174857',
      value: '-',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '02/18/2020',
      dateAmended: '01/15/2021',
    },
    {
      key: '500299263',
      reportedDate: '05/31/2024',
      lienNumber: '500299263',
      value: '-',
      cautionFiling: '-',
      collateral: 'EQUIPMENT',
      dateRegistered: '11/14/2023',
      dateAmended: '-',
    },
  ];

  const columns = [
    {
      title: 'Reported Date',
      dataIndex: 'reportedDate',
      key: 'reportedDate',
    },
    {
      title: 'Lien Number',
      dataIndex: 'lienNumber',
      key: 'lienNumber',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Caution Filing',
      dataIndex: 'cautionFiling',
      key: 'cautionFiling',
    },
    {
      title: 'Collateral',
      dataIndex: 'collateral',
      key: 'collateral',
    },
    {
      title: 'Date Registered',
      dataIndex: 'dateRegistered',
      key: 'dateRegistered',
    },
    {
      title: 'Date Amended',
      dataIndex: 'dateAmended',
      key: 'dateAmended',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={printView ? false : {pageSize: 10}}
    />
  );
};

export default LiensTable;
