const getEngagementFiles = ({files, engagement, service}) => {
  const filter = !!service
    ? (file) => file.engagement_service_id === service?.id
    : !!engagement
    ? (file) => file.engagement_id === engagement?.id
    : null;

  return !!filter ? [...files].filter(filter) : [...files];
};

export default getEngagementFiles;
