import React from 'react';
import {Table, Badge} from 'antd';

const QuarterlyIndexPaymentHistory = () => {
  const getBadgeStatus = (index) => {
    if (index <= 20) return 'success'; // Green
    if (index <= 50) return 'warning'; // Yellow
    return 'error'; // Red
  };

  const data = [
    {
      key: '1',
      period: 'Q4 2024',
      paymentIndex: 25,
      creditIndex: 34,
      references: 240,
      currentOwing: 'Can$489.2K',
      period1: 'Can$217.4K',
      period2: 'Can$136.1K',
      period3: 'Can$59.9K',
      totalOwing: 'Can$902.8K',
    },
    {
      key: '2',
      period: 'Q3 2024',
      paymentIndex: 20,
      creditIndex: 20,
      references: 34,
      currentOwing: 'Can$475.2K',
      period1: 'Can$120.7K',
      period2: 'Can$16.6K',
      period3: 'Can$6.8K',
      totalOwing: 'Can$619.3K',
    },
    {
      key: '3',
      period: 'Q2 2024',
      paymentIndex: 31,
      creditIndex: 30,
      references: 27,
      currentOwing: 'Can$398.6K',
      period1: 'Can$62.7K',
      period2: 'Can$1.6K',
      period3: 'Can$18.4K',
      totalOwing: 'Can$481.3K',
    },
    {
      key: '4',
      period: 'Q1 2024',
      paymentIndex: 42,
      creditIndex: 21,
      references: 26,
      currentOwing: 'Can$382.6K',
      period1: 'Can$62.5K',
      period2: 'Can$35.4K',
      period3: 'Can$21.6K',
      totalOwing: 'Can$502.1K',
    },
    {
      key: '5',
      period: 'Q4 2023',
      paymentIndex: 46,
      creditIndex: 7,
      references: 18,
      currentOwing: 'Can$100.3K',
      period1: 'Can$119.6K',
      period2: 'Can$67.1K',
      period3: 'Can$88.9K',
      totalOwing: 'Can$376K',
    },
    {
      key: '6',
      period: 'Q3 2023',
      paymentIndex: 56,
      creditIndex: 23,
      references: 21,
      currentOwing: 'Can$79.7K',
      period1: 'Can$234.7K',
      period2: 'Can$131.1K',
      period3: 'Can$186.7K',
      totalOwing: 'Can$632.2K',
    },
    {
      key: '7',
      period: 'Q2 2023',
      paymentIndex: 29,
      creditIndex: 25,
      references: 21,
      currentOwing: 'Can$116.7K',
      period1: 'Can$97.4K',
      period2: 'Can$13.2K',
      period3: 'Can$33.3K',
      totalOwing: 'Can$260.6K',
    },
    {
      key: '8',
      period: 'Q1 2023',
      paymentIndex: 60,
      creditIndex: 31,
      references: 19,
      currentOwing: 'Can$44.1K',
      period1: 'Can$49.7K',
      period2: 'Can$12.6K',
      period3: 'Can$96K',
      totalOwing: 'Can$202.4K',
    },
    {
      key: '9',
      period: 'Q4 2022',
      paymentIndex: 59,
      creditIndex: 31,
      references: 19,
      currentOwing: 'Can$124.6K',
      period1: 'Can$106.7K',
      period2: 'Can$139K',
      period3: 'Can$216.2K',
      totalOwing: 'Can$586.6K',
    },
    {
      key: '10',
      period: 'Q3 2022',
      paymentIndex: 54,
      creditIndex: 32,
      references: 15,
      currentOwing: 'Can$69.1K',
      period1: 'Can$120.9K',
      period2: 'Can$98.9K',
      period3: 'Can$111.1K',
      totalOwing: 'Can$400K',
    },
  ];

  const columns = [
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: 'Payment Index',
      dataIndex: 'paymentIndex',
      key: 'paymentIndex',
      render: (index) => <Badge status={getBadgeStatus(index)} text={index} />,
    },
    {
      title: 'Credit Index',
      dataIndex: 'creditIndex',
      key: 'creditIndex',
    },
    {
      title: 'No. of References',
      dataIndex: 'references',
      key: 'references',
    },
    {
      title: 'Current Owing',
      dataIndex: 'currentOwing',
      key: 'currentOwing',
    },
    {
      title: 'Period 1',
      dataIndex: 'period1',
      key: 'period1',
    },
    {
      title: 'Period 2',
      dataIndex: 'period2',
      key: 'period2',
    },
    {
      title: 'Period 3',
      dataIndex: 'period3',
      key: 'period3',
    },
    {
      title: 'Total Owing',
      dataIndex: 'totalOwing',
      key: 'totalOwing',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default QuarterlyIndexPaymentHistory;
