// ant components
import {Col as Column, Row, Table} from 'antd';

// layout components
import Card from '../../../layout/components/Card/Card';
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import getColumns from './lib/getColumns.lib';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

const CBInsightsFinancialSection = () => {
  const {company} = useSelector((state) => state.company);

  const columns = getColumns({
    organizationId: company?.cbInsights?.organization?.organization?.orgId,
  });

  return (
    <Fragment>
      <Row gutter={[20, 20]}>
        {!!company?.cbInsights?.deals?.deals && (
          <Column span={24}>
            <StatementBlock title="Funding">
              <Card noPadding>
                <Table
                  columns={columns.funding}
                  dataSource={[
                    ...(company.cbInsights.deals.deals || []),
                  ].reverse()}
                  pagination={false}
                  rowKey="section"
                  size="small"
                />
              </Card>
            </StatementBlock>
          </Column>
        )}
        {!!company?.cbInsights?.organization?.organization?.orgKPIs?.investors
          ?.length && (
          <Column span={12}>
            <StatementBlock title="Investors">
              <Card noPadding>
                <Table
                  columns={columns.investors}
                  dataSource={[
                    ...(company?.cbInsights?.organization?.organization?.orgKPIs
                      ?.investors || []),
                  ]}
                  pagination={false}
                  rowKey="section"
                  size="small"
                  locale={{emptyText: 'No external investors'}}
                />
              </Card>
            </StatementBlock>
          </Column>
        )}
        {!!company?.cbInsights?.organization?.organization?.orgKPIs?.competitors
          ?.length && (
          <Column span={12}>
            <StatementBlock title="Competitors">
              <Card noPadding>
                <Table
                  columns={columns.competitors}
                  dataSource={[
                    ...(company?.cbInsights?.organization?.organization?.orgKPIs
                      ?.competitors || []),
                  ]}
                  pagination={false}
                  rowKey="section"
                  size="small"
                />
              </Card>
            </StatementBlock>
          </Column>
        )}
        {!!company?.cbInsights?.businessRelationships?.businessRelationships
          ?.length && (
          <Column span={12}>
            <StatementBlock title="Business Relationships">
              <Card noPadding>
                <Table
                  columns={columns.businessRelationships}
                  dataSource={[
                    ...(company?.cbInsights?.businessRelationships
                      ?.businessRelationships || []),
                  ]}
                  pagination={false}
                  rowKey="section"
                  size="small"
                />
              </Card>
            </StatementBlock>
          </Column>
        )}
        {!!company?.cbInsights?.people?.people && (
          <Column span={12}>
            <StatementBlock title="People">
              <Card noPadding>
                <Table
                  columns={columns.people}
                  dataSource={[...(company?.cbInsights?.people?.people || [])]}
                  pagination={false}
                  rowKey="section"
                  size="small"
                />
              </Card>
            </StatementBlock>
          </Column>
        )}
      </Row>
    </Fragment>
  );
};

export default CBInsightsFinancialSection;
