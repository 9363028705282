import React from 'react';
import {Table} from 'antd';

const legalDetailsData = [
  {
    reportedDate: '11/20/2024',
    claimDate: '10/03/2024',
    legalItemType: 'Legal Suit',
    legalItemDetails: 'Writ of Summons',
    claimNumber: '031607244',
    amount: 'Can$1,952',
  },
  {
    reportedDate: '11/20/2024',
    claimDate: '08/31/2023',
    legalItemType: 'Legal Suit',
    legalItemDetails: 'Statement of Claim',
    claimNumber: 'SCS223742',
    amount: 'Can$0',
  },
  {
    reportedDate: '11/19/2024',
    claimDate: '05/31/2024',
    legalItemType: 'Legal Suit',
    legalItemDetails: 'Statement of Claim',
    claimNumber: 'SU242-24',
    amount: 'Can$200,000',
  },
  {
    reportedDate: '08/02/2024',
    claimDate: '03/08/2024',
    legalItemType: 'Legal Suit',
    legalItemDetails: 'Statement of Claim',
    claimNumber: 'SU84746-24',
    amount: 'Can$1,000,000',
  },
  {
    reportedDate: '06/24/2024',
    claimDate: '05/01/2024',
    legalItemType: 'Other Legal Info',
    legalItemDetails: 'Legal Action Dismissed',
    claimNumber: '2390304821',
    amount: 'Can$0',
  },
  {
    reportedDate: '05/16/2024',
    claimDate: '05/06/2024',
    legalItemType: 'Legal Suit',
    legalItemDetails: 'Writ of Summons',
    claimNumber: '036137248',
    amount: 'Can$177,488',
  },
  {
    reportedDate: '05/10/2024',
    claimDate: '04/30/2024',
    legalItemType: 'Legal Suit',
    legalItemDetails: 'Writ of Summons',
    claimNumber: '012332168',
    amount: 'Can$7,845,562',
  },
  {
    reportedDate: '04/16/2024',
    claimDate: '04/10/2024',
    legalItemType: 'Legal Suit',
    legalItemDetails: 'Writ of Summons',
    claimNumber: '004523241',
    amount: 'Can$903,883',
  },
  {
    reportedDate: '12/14/2023',
    claimDate: '05/18/2023',
    legalItemType: 'Other Legal Info',
    legalItemDetails: 'Declar. - Settled out of Court',
    claimNumber: '014199201',
    amount: 'Can$138,914',
  },
  {
    reportedDate: '10/25/2023',
    claimDate: '10/12/2023',
    legalItemType: 'Legal Suit',
    legalItemDetails: 'Writ of Summons',
    claimNumber: '013136237',
    amount: 'Can$615,920',
  },
  {
    reportedDate: '09/27/2023',
    claimDate: '09/18/2023',
    legalItemType: 'Legal Suit',
    legalItemDetails: 'Writ of Summons',
    claimNumber: '126920233',
    amount: 'Can$1,259,539',
  },
  {
    reportedDate: '05/26/2023',
    claimDate: '05/10/2023',
    legalItemType: 'Other Legal Info',
    legalItemDetails: 'Notice of Discontinuance',
    claimNumber: '005706219',
    amount: 'Can$0',
  },
  {
    reportedDate: '03/24/2023',
    claimDate: '03/09/2023',
    legalItemType: 'Legal Suit',
    legalItemDetails: 'Writ of Summons',
    claimNumber: '018589225',
    amount: 'Can$138,432',
  },
];

const columns = [
  {
    title: 'Reported Date',
    dataIndex: 'reportedDate',
    key: 'reportedDate',
  },
  {
    title: 'Claim Date',
    dataIndex: 'claimDate',
    key: 'claimDate',
  },
  {
    title: 'Legal Item Type',
    dataIndex: 'legalItemType',
    key: 'legalItemType',
  },
  {
    title: 'Legal Item Details',
    dataIndex: 'legalItemDetails',
    key: 'legalItemDetails',
  },
  {
    title: 'Claim Number',
    dataIndex: 'claimNumber',
    key: 'claimNumber',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
];

const LegalDetails = () => {
  return (
    <Table columns={columns} dataSource={legalDetailsData} pagination={false} />
  );
};

export default LegalDetails;
