import React from 'react';
import {Table} from 'antd';

const CollectionDetails = () => {
  const data = [
    {
      key: '1',
      reportedDate: '01/21/2022',
      claimDate: '08/31/2021',
      creditor: 'GROUPE CARREAUX CERAGRES INC',
      debtor: 'MALVERN CONTRACT INTERIORS LTD. ATTN: VI',
      claimAmount: 'Can$17.4K',
      amountPaid: 'Can$17.4K',
      accountBalanced: 'Can$0',
    },
  ];

  const columns = [
    {title: 'Reported Date', dataIndex: 'reportedDate', key: 'reportedDate'},
    {title: 'Claim Date', dataIndex: 'claimDate', key: 'claimDate'},
    {title: 'Creditor', dataIndex: 'creditor', key: 'creditor'},
    {title: 'Debtor', dataIndex: 'debtor', key: 'debtor'},
    {title: 'Claim Amount', dataIndex: 'claimAmount', key: 'claimAmount'},
    {title: 'Amount Paid', dataIndex: 'amountPaid', key: 'amountPaid'},
    {
      title: 'Account Balanced',
      dataIndex: 'accountBalanced',
      key: 'accountBalanced',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default CollectionDetails;
