// chart components
import ComplianceBreakdownChart from '../../../chart/components/ComplianceBreakdownChart/ComplianceBreakdownChart';

// compliance containers
import ComplianceAndQuestionnairesDetailContainer from '../../../compliance/containers/ComplianceAndQuestionnairesDetailContainer/ComplianceAndQuestionnairesDetailContainer';

// engagement components
import FullScreenFileUploadCard from '../FullScreenFileUploadCard/FullScreenFileUploadCard';

// engagement containers
import EngagementFileManagerContainer from '../../containers/EngagementFileManagerContainer/EngagementFileManagerContainer';

// local components
import Content from './components/Content';
import Chart from './components/Chart';
import Row from './components/Row';

// local handlers
import complianceClickHandler from './handlers/complianceClick.handler';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const DueDiligenceDataCard = ({
  analyzingFiles,
  compliance,
  complianceChartTitle,
  domReferences,
  isCompanyLevel,
  questionnaires,
  showDetails,
  textProps,
}) => {
  return !showDetails ? (
    <EngagementFileManagerContainer>
      {({onUpload, uploading}) => (
        <FullScreenFileUploadCard
          analyzingFiles={analyzingFiles}
          domReferences={domReferences}
          onUpload={onUpload}
          {...textProps}
          uploading={uploading}
        />
      )}
    </EngagementFileManagerContainer>
  ) : (
    <Row>
      <Content>
        <ComplianceAndQuestionnairesDetailContainer
          compliance={compliance}
          isCompanyLevel={isCompanyLevel}
          questionnaires={questionnaires}
        />
      </Content>
      <Chart>
        <ComplianceBreakdownChart
          compliance={compliance.rating}
          data={compliance.frameworks}
          onSectionClick={complianceClickHandler()}
          title={
            !!complianceChartTitle
              ? `${complianceChartTitle}<br/>Compliance Rating`
              : 'Compliance Rating'
          }
        />
      </Chart>
    </Row>
  );
};

DueDiligenceDataCard.propTypes = {
  analyzingFiles: PropTypes.bool,
  compliance: PropTypes.object,
  complianceChartTitle: PropTypes.string,
  domReferences: PropTypes.object,
  isCompanyLevel: PropTypes.bool,
  questionnaires: PropTypes.array,
  showDetails: PropTypes.bool,
  textProps: PropTypes.object,
};

export default DueDiligenceDataCard;
