// date-fns
import {format, parse, parseISO, isDate} from 'date-fns';

const dateDateFormatter = ({
  date = new Date(),
  dateFormat = 'yyyyMMdd',
  displayDateFormat = 'yyyy-MM-dd',
  isIso = false,
} = {}) =>
  format(
    isDate(date)
      ? date
      : isIso
      ? parseISO(date)
      : parse(date, dateFormat, new Date()),
    displayDateFormat
  );

export default dateDateFormatter;
