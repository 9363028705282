// ant components
import {Button} from 'antd';

// ant icons
import {PrinterOutlined} from '@ant-design/icons';

// company containers
import CompanySideMenuContainer from '../../../company/containers/CompanySideMenuContainer/CompanySideMenuContainer';

// company lib
import generateSubPages from '../../../company/lib/generateSubPages.lib.company';

// company routes
import suppliersDashboardRoute from '../../../company/pages/SuppliersDashboardPage/route';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// modernSlavery components
import ModernSlaveryStatementsList from '../../components/ModernSlaveryStatementsList/ModernSlaveryStatementsList';
import ModernSlaverySummary from '../../components/ModernSlaverySummary/ModernSlaverySummary';

// print components
import Print from '../../../print/components/Print/Print';
import PrintLayout from '../../../print/components/PrintLayout/PrintLayout';
import PrintSection from '../../../print/components/PrintSection/PrintSection';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const ModernSlaveryLayout = () => {
  const {company, subPage} = useSelector((state) => ({
    company: state.company.company,
    subPage: state.layout.subPage,
  }));

  return (
    <Print
      title="Modern Slavery"
      subtitle={company.CompanyName}
      trigger={({print}) => (
        <PageLayout
          previousPageLabel="Back to Dashboard"
          previousPageUrl={suppliersDashboardRoute(company.CompanyId)}
          sideContent={({goToSection}) => (
            <CompanySideMenuContainer goToSection={goToSection} />
          )}
          subPage={subPage}
          subPages={generateSubPages(company)}
          sectionName="Modern Slavery"
          sections={
            !company?.modernSlavery
              ? [
                  {
                    section: 'Summary',
                    component: <div>No Modern Slavery Data found</div>,
                  },
                ]
              : [
                  {
                    section: 'Summary',
                    component: <ModernSlaverySummary />,
                    moreButton: (
                      <Button icon={<PrinterOutlined />} onClick={print}>
                        Print Report
                      </Button>
                    ),
                  },
                  {
                    section: 'Statements',
                    component: (
                      <ModernSlaveryStatementsList
                        urls={company?.modernSlavery?.statement_urls || []}
                      />
                    ),
                  },
                ].filter((section) => !!section)
          }
        />
      )}
    >
      {!!company?.modernSlavery && (
        <PrintLayout>
          <PrintSection title="Summary">
            <ModernSlaverySummary />
          </PrintSection>
          <PrintSection title="Statements">
            <ModernSlaveryStatementsList
              urls={company?.modernSlavery?.statement_urls || []}
            />
          </PrintSection>
        </PrintLayout>
      )}
    </Print>
  );
};

export default ModernSlaveryLayout;
