// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// file api
import getFileVersionByIdApi from '../../api/getVersionById.api.file';

// file events
import fileAnalyzedEvent from '../../events/analyzed.event.file';
import fileCheckedEvent from '../../events/checked.event.file';
import fileCreatedEvent from '../../events/created.event.file';
import filesListedEvent from '../../events/listed.event.file';
import fileUploadedEvent from '../../events/uploaded.event.file';

// file redux actions
import {setGeneralFileData as setGeneralFileDataAction} from '../../redux/reducer.redux.file';

// lib
import wait from '../../../lib/wait';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class FileStatusCheckContainer extends Component {
  static propTypes = {
    analyzing: PropTypes.array,
    dispatch: PropTypes.func,
    processingFiles: PropTypes.array,
    subscribe: PropTypes.func,
    uploading: PropTypes.array,
  };

  componentDidMount() {
    this.props.subscribe(
      fileCreatedEvent.subscribe(this.fileCreated),
      filesListedEvent.subscribe(this.filesListed)
    );
  }

  filesListed = ({files}) => {
    const toUpload = [...files].filter((file) => !file.version.is_uploaded);
    const toAnalyzeUpload = [...files].filter(
      (file) => file.version.is_uploaded && !file.version.is_parsed
    );

    if (!!toUpload.length)
      [...files].forEach((file) => {
        if (this.props.processingFiles.some(({id}) => file.id === id)) return;
        const processingFiles = [...this.props.processingFiles, file];
        const uploading = [...this.props.uploading, file.id];
        this.props.dispatch(
          setGeneralFileDataAction({processingFiles, uploading})
        );
        this.checkUploadStatus({file});
      });

    if (!!toAnalyzeUpload.length)
      [...files].forEach((file) => {
        if (this.props.processingFiles.some(({id}) => file.id === id)) return;
        const processingFiles = [...this.props.processingFiles, file];
        const analyzing = [...this.props.analyzing, file.id];
        this.props.dispatch(
          setGeneralFileDataAction({processingFiles, analyzing})
        );
        this.checkAnalyzeStatus({file});
      });
  };

  fileCreated = async ({file}) => {
    const processingFiles = [...this.props.processingFiles, file];
    const uploading = [...this.props.uploading, file.id];
    this.props.dispatch(setGeneralFileDataAction({processingFiles, uploading}));
    this.checkUploadStatus({file});
  };

  checkUploadStatus = async ({file}) => {
    if (file.version.is_uploaded) {
      fileUploadedEvent.publish({file});
      const uploading = [...this.props.uploading].filter(
        (id) => id !== file.id
      );
      const analyzing = [...this.props.analyzing, file.id];
      this.props.dispatch(setGeneralFileDataAction({uploading, analyzing}));
      this.checkAnalyzeStatus({file});
      return;
    }
    const updatedFile = await this.getUpdatedFile({file});
    this.checkUploadStatus({file: updatedFile});
  };

  checkAnalyzeStatus = async ({file}) => {
    if (file.version.is_parsed) {
      fileAnalyzedEvent.publish({file});
      const analyzing = [...this.props.analyzing].filter(
        (id) => id !== file.id
      );
      const processingFiles = [...this.props.processingFiles].filter(
        (f) => f.id !== file.id
      );
      this.props.dispatch(
        setGeneralFileDataAction({analyzing, processingFiles})
      );
      return;
    }
    fileCheckedEvent.publish({file});
    const updatedFile = await this.getUpdatedFile({file});
    this.checkAnalyzeStatus({file: updatedFile});
  };

  getUpdatedFile = async ({file}) => {
    await wait(10000);
    try {
      const fileId = file.id.replace('file_', '');
      const fileVersionId = (file?.version?.id || '').replace(
        'file_version_',
        ''
      );
      const updatedFile = await getFileVersionByIdApi({
        fileId,
        fileVersionId,
      });
      return updatedFile;
    } catch (error) {
      return file;
    }
  };

  render() {
    return null;
  }
}

export default connect((state) => ({
  analyzing: state.file.analyzing,
  processingFiles: state.file.processingFiles,
  uploading: state.file.uploading,
}))(subscriptionHOC(FileStatusCheckContainer));
