import styled from 'styled-components';

// colors
import grey600Color from '../../../../layout/colors/grey600.color.layout';

const Content = styled.div`
  width: 100%;
  color: ${grey600Color};
`;

export default Content;
