// ant icons
import {LoadingOutlined} from '@ant-design/icons';

// local components
import Container from './components/Container';

// react
import React from 'react';

const AnalyzingIndicator = () => (
  <Container>
    <LoadingOutlined spin />
    Analyzing Files
  </Container>
);

export default AnalyzingIndicator;
