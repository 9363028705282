import React from 'react';
import {Table} from 'antd';

const MostRecentFilings = () => {
  const data = [
    {
      key: '1',
      filing: 'Liens',
      dateFiled: '06/03/2024',
      filingType: 'Liens',
      caseNumber: '505959444',
      status: '-',
      releasedDate: '-',
    },
    {
      key: '2',
      filing: 'Bankruptcy',
      dateFiled: '10/31/2023',
      filingType: 'Bankruptcy',
      caseNumber: '-',
      status: 'Active',
      releasedDate: '-',
    },
    {
      key: '3',
      filing: 'Liens',
      dateFiled: '10/11/2022',
      filingType: 'Liens',
      caseNumber: '787429215',
      status: '-',
      releasedDate: '-',
    },
    {
      key: '4',
      filing: 'Collection',
      dateFiled: '08/31/2021',
      filingType: 'Collection',
      caseNumber: '21106286',
      status: 'Released',
      releasedDate: '01/13/2022',
    },
    {
      key: '5',
      filing: 'Liens',
      dateFiled: '04/06/2021',
      filingType: 'Liens',
      caseNumber: '771215355',
      status: '-',
      releasedDate: '-',
    },
  ];

  const columns = [
    {title: 'Legal Filing', dataIndex: 'filing', key: 'filing'},
    {title: 'Date Filed', dataIndex: 'dateFiled', key: 'dateFiled'},
    {title: 'Filing Type', dataIndex: 'filingType', key: 'filingType'},
    {title: 'Case Number', dataIndex: 'caseNumber', key: 'caseNumber'},
    {title: 'Status', dataIndex: 'status', key: 'status'},
    {title: 'Released Date', dataIndex: 'releasedDate', key: 'releasedDate'},
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default MostRecentFilings;
