// ant components
import {message as messageApi} from 'antd';

// engagement events
import createEngagementEvent from '../../events/create.event.engagement';

// engagement routes
import engagementRoute from '../../pages/EngagementPage/route';

// engagement redux actions
import {setGeneralEngagementData as setGeneralEngagementDataAction} from '../../redux/reducer.redux.engagement';

// engagement services
import createEngagementService from '../../services/create.service.engagement';

// event HOC
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// navigation components
import Redirect from '../../../navigation/components/Redirect/Redirect';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// supplier lib
import getCompanyId from '../../../supplier/lib/getCompanyId.lib.supplier';

class EngagementCreatorContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    creatingEngagement: PropTypes.bool,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    redirect: null,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(createEngagementEvent.subscribe(this.save));
  }

  save = async () => {
    const {company, creatingEngagement, dispatch} = this.props;

    if (creatingEngagement) return;

    dispatch(setGeneralEngagementDataAction({creatingEngagement: true}));

    try {
      const companyId = getCompanyId(company);
      const engagement = await createEngagementService({companyId});
      const redirect = engagementRoute({
        companyId,
        engagementId: engagement.id,
      });
      this.setState({redirect});
    } catch (error) {
      messageApi.error(error.message);
    }

    dispatch(setGeneralEngagementDataAction({creatingEngagement: false}));
  };

  render() {
    const {redirect} = this.state;
    return <Redirect url={redirect} />;
  }
}

export default connect((state) => ({
  company: state.company.company,
  creatingEngagement: state.engagement.creatingEngagement,
}))(subscriptionHOC(EngagementCreatorContainer));
