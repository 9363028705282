// ant components
import {message as messageApi} from 'antd';

// engagement components
import UpdateEngagementModal from '../../components/UpdateEngagementModal/UpdateEngagementModal';

// engagement events
import showUpdateEngagementModalEvent from '../../events/showUpdateModal.event.engagement';

// engagement services
import updateEngagementService from '../../services/update.service.engagement';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class UpdateEngagementContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    companyId: '',
    name: '',
    engagement: null,
    saving: false,
    visible: false,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.mounted = true;
    this.props.subscribe(showUpdateEngagementModalEvent.subscribe(this.show));
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  show = ({companyId, engagement}) => {
    this.setState({
      ...this.constructor.DEFAULT_STATE,
      companyId,
      engagement,
      name: engagement.name,
      visible: true,
    });
  };

  hide = () => {
    if (this.state.saving) return;
    this.setState({visible: false});
  };

  change = (key) => (e) => {
    if (this.state.saving) return;
    const value = !!e?.target ? e.target.value : e;
    this.setState({[key]: value});
  };

  save = async () => {
    const {companyId, name, engagement, saving} = this.state;

    if (saving) return;

    this.setState({saving: true});

    try {
      await updateEngagementService({
        companyId,
        engagementId: engagement.id,
        name,
        services: [...(engagement.services || [])],
      });
      if (!this.mounted) return;
      this.setState({saving: false, visible: false});
    } catch (error) {
      if (!this.mounted) return;
      messageApi.error(error.message);
      this.setState({saving: false});
    }
  };

  render() {
    const {visible, name, saving} = this.state;
    return (
      <UpdateEngagementModal
        name={name}
        onChange={this.change}
        onClose={this.hide}
        onSave={this.save}
        saving={saving}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(UpdateEngagementContainer);
