import React from 'react';
import {Table, Badge} from 'antd';

const IndustryTradeSummary = () => {
  const data = [
    {
      key: '1',
      category: '90 Day',
      piScore: 36,
      creditReferences: 56,
      highCredit: 'Can$1.1M',
      currentOwing: 'Can$133.9K',
      period1: 'Can$108.7K',
      period2: 'Can$138.7K',
      period3: 'Can$16.4K',
      totalOwing: 'Can$397.6K',
    },
    {
      key: '2',
      category: '13 Month',
      piScore: 23,
      creditReferences: 137,
      highCredit: 'Can$3.7M',
      currentOwing: 'Can$1.1M',
      period1: 'Can$257K',
      period2: 'Can$311.8K',
      period3: 'Can$119.6K',
      totalOwing: 'Can$1.8M',
    },
    {
      key: '3',
      category: 'All References',
      piScore: 24,
      creditReferences: 164,
      highCredit: 'Can$3.9M',
      currentOwing: 'Can$1.2M',
      period1: 'Can$269.8K',
      period2: 'Can$323.2K',
      period3: 'Can$158.8K',
      totalOwing: 'Can$1.9M',
    },
  ];

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'PI Score',
      dataIndex: 'piScore',
      key: 'piScore',
      render: (value) => (
        <Badge color={value > 30 ? 'green' : 'yellow'} text={value} />
      ),
    },
    {
      title: 'Credit References',
      dataIndex: 'creditReferences',
      key: 'creditReferences',
    },
    {
      title: 'High Credit',
      dataIndex: 'highCredit',
      key: 'highCredit',
    },
    {
      title: 'Current Owing',
      dataIndex: 'currentOwing',
      key: 'currentOwing',
    },
    {
      title: 'Period 1',
      dataIndex: 'period1',
      key: 'period1',
    },
    {
      title: 'Period 2',
      dataIndex: 'period2',
      key: 'period2',
    },
    {
      title: 'Period 3',
      dataIndex: 'period3',
      key: 'period3',
    },
    {
      title: 'Total Owing',
      dataIndex: 'totalOwing',
      key: 'totalOwing',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default IndustryTradeSummary;
