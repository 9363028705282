// company route
import companyRoute from '../../../company/pages/CompanyPage/route';

// engagement components
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';

// engagement events
import engagementDeletedEvent from '../../events/deleted.event.engagement';
import engagementUpdatedEvent from '../../events/updated.event.engagement';

// engagement redux actions
import {
  setGeneralEngagementData as setGeneralEngagementDataAction,
  setupEngagement as setupEngagementAction,
} from '../../redux/reducer.redux.engagement';

// engagement routes
import engagementsRoute from '../../pages/EngagementsPage/route';

// engagement services
import loadEngagementService from '../../services/loadEngagement.service.engagement';

// engagementService events
import engagementServiceCreatedEvent from '../../../engagementService/events/created.event.engagementService';
import engagementServiceDeletedEvent from '../../../engagementService/events/deleted.event.engagementService';
import engagementServiceUpdatedEvent from '../../../engagementService/events/updated.event.engagementService';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// react router
import {Navigate, Outlet} from 'react-router-dom';

// supplier lib
import getCompanyId from '../../../supplier/lib/getCompanyId.lib.supplier';

class EngagementContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    dispatch: PropTypes.func,
    engagement: PropTypes.object,
    engagementId: PropTypes.string,
    engagements: PropTypes.array,
    service: PropTypes.object,
    subscribe: PropTypes.func,
  };

  state = {
    loading: true,
    redirect: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.loadEngagement();
    this.props.subscribe(
      engagementDeletedEvent.subscribe(this.engagementDeleted),
      engagementUpdatedEvent.subscribe(this.engagementUpdated),
      engagementServiceCreatedEvent.subscribe(this.engagementServiceCreated),
      engagementServiceDeletedEvent.subscribe(this.engagementServiceDeleted),
      engagementServiceUpdatedEvent.subscribe(this.engagementServiceUpdated)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.engagementId !== prevProps.engagementId) {
      this.loadEngagement();
    }
  }

  componentWillUnmount() {
    const {dispatch} = this.props;
    this.mounted = false;
    dispatch(
      setGeneralEngagementDataAction({
        engagement: null,
        engagementFiles: [],
      })
    );
  }

  loadEngagement = async () => {
    const {company, dispatch, engagementId, engagements} = this.props;

    const companyId = getCompanyId(company);

    this.setState({loading: true});

    try {
      const {engagement, engagementFiles} = await loadEngagementService({
        company,
        engagementId,
        engagements,
      });
      if (!this.mounted) return;
      dispatch(
        setupEngagementAction({
          engagement,
          engagementFiles,
        })
      );
      this.setState({loading: false});
    } catch (error) {
      this.setState({
        redirect: companyRoute(companyId),
      });
    }
  };

  engagementUpdated = ({engagement}) => {
    const {engagement: loadedEngagement, dispatch} = this.props;
    if (engagement?.id !== loadedEngagement?.id) return;
    dispatch(
      setGeneralEngagementDataAction({
        engagement,
      })
    );
  };

  engagementDeleted = ({engagement}) => {
    const {company, engagement: loadedEngagement} = this.props;
    if (engagement?.id !== loadedEngagement?.id) return;
    const companyId = getCompanyId(company);
    this.setState({redirect: engagementsRoute({companyId})});
  };

  engagementServiceCreated = ({engagementService}) => {
    const {engagement, engagements, dispatch} = this.props;
    if (engagementService?.engagement_id !== engagement?.id) return;
    const services = [...(engagement?.services || []), engagementService];
    const updatedEngagement = {
      ...engagement,
      services,
    };
    const updatedEngagements = [...engagements].map((item) =>
      item.id === engagement.id ? updatedEngagement : item
    );
    dispatch(
      setGeneralEngagementDataAction({
        engagement: updatedEngagement,
        engagements: updatedEngagements,
      })
    );
  };

  engagementServiceUpdated = ({engagementService}) => {
    const {engagement, engagements, dispatch, service} = this.props;
    if (engagementService?.engagement_id !== engagement?.id) return;
    const services = [...(engagement?.services || [])].map((service) =>
      service.id === engagementService.id ? engagementService : service
    );
    const updatedEngagement = {
      ...engagement,
      services,
    };
    const updatedEngagements = [...engagements].map((item) =>
      item.id === engagement.id ? updatedEngagement : item
    );
    const updatedService =
      service?.id === engagementService.id ? engagementService : service;
    dispatch(
      setGeneralEngagementDataAction({
        engagement: updatedEngagement,
        engagements: updatedEngagements,
        service: updatedService,
      })
    );
  };

  engagementServiceDeleted = ({engagementService}) => {
    const {dispatch, engagement, engagements} = this.props;
    if (engagementService?.engagement_id !== engagement?.id) return;
    const updatedEngagement = {
      ...engagement,
      services: [...(engagement?.services || [])].filter(
        ({id}) => id !== engagementService.id
      ),
    };
    const updatedEngagements = [...engagements].map((item) =>
      item.id === engagement.id ? updatedEngagement : item
    );
    dispatch(
      setGeneralEngagementDataAction({
        engagement: updatedEngagement,
        engagements: updatedEngagements,
      })
    );
  };

  render() {
    const {engagement} = this.props;
    const {loading, redirect} = this.state;
    return redirect ? (
      <Navigate to={redirect} replace />
    ) : loading || !engagement ? (
      <ScreenLoader>Loading the Engagement</ScreenLoader>
    ) : (
      <Outlet />
    );
  }
}

export default connect((state) => ({
  company: state.company.company,
  engagement: state.engagement.engagement,
  engagements: state.engagement.engagements,
  service: state.engagement.service,
}))(subscriptionHOC(EngagementContainer));
