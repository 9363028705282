// file api
import updateFileApi from '../api/update.api.file';

// file events
import fileDeletedEvent from '../events/deleted.event.file';

const deleteFileService = async ({file}) => {
  const fileId = file.id.replace('file_', '');
  await updateFileApi({fileId, file: {is_deleted: true}});
  fileDeletedEvent.publish({file});
  return file;
};

export default deleteFileService;
