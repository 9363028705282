import styled, {css} from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';
import grey400Color from '../../../../layout/colors/grey400.color.layout';

const Breadcrumb = styled.div`
  color: ${blackColor};
  cursor: ${({onClick}) => (onClick ? 'pointer' : 'default')};
  display: inline-block;
  font-weight: ${({bold}) => (bold ? 700 : 400)};
  text-decoration: none !important;

  border-radius: 5px;
  padding: 0px 8px;
  margin-inline: -8px;

  & > span {
    margin-left: 6px;
    font-size: 12px;
    transform: translateY(-2px);
  }

  ${({disableHover}) =>
    !disableHover
      ? css`
          cursor: pointer;
          &:hover {
            background: ${grey400Color};
          }
        `
      : ''}
`;

export default Breadcrumb;
