// ant components
import {Table} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const WebsiteTechnologiesTable = ({firmographics}) => {
  if (!firmographics || !firmographics.technologies) return null;

  const {technologies} = firmographics;

  const data = technologies
    .map((tech) => ({
      key: tech.category.toLowerCase().replace(/\s+/g, '_'),
      field: tech.category,
      value: tech.technologies.join(', '),
    }))
    .filter(Boolean); // Removes empty values

  return (
    <StatementBlock title="Website Technologies">
      <Table
        columns={[
          {
            dataIndex: 'field',
            key: 'field',
            render: (text) => <strong>{text}</strong>,
          },
          {
            dataIndex: 'value',
            key: 'value',
          },
        ]}
        dataSource={data}
        pagination={false}
        size="small"
        rowKey="key"
        showHeader={false} // Hides the table header
      />
    </StatementBlock>
  );
};

WebsiteTechnologiesTable.propTypes = {
  firmographics: PropTypes.object,
};

export default WebsiteTechnologiesTable;
