// risk constants
import SCORE_LEVELS from '../constants/scoreLevels.constant.risk';

const getScoreColor = (score) => {
  const minLevel = [...SCORE_LEVELS][0];
  const maxLevel = [...SCORE_LEVELS].slice(-1)?.[0];
  return score >= maxLevel[0]
    ? maxLevel[1]
    : [...SCORE_LEVELS].find(([maxScore]) => score < maxScore)?.[1] ||
        minLevel[1];
};

export default getScoreColor;
