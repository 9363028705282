// ant icons
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';

// engagement containers
import DueDiligenceDataContainer from '../../../engagement/containers/DueDiligenceDataContainer/DueDiligenceDataContainer';

// engagement events
import showEngagementDeleteModalEvent from '../../../engagement/events/showDeleteModal.event.engagement';
import showEngagementUpdateModalEvent from '../../../engagement/events/showUpdateModal.event.engagement';

// engagement routes
import engagementDetailRoute from '../../../engagement/pages/EngagementDetailPage/route';

// engagement redux actions
import {setGeneralEngagementData as setGeneralEngagementDataAction} from '../../../engagement/redux/reducer.redux.engagement';

// engagementService events
import engagementServiceDeletedEvent from '../../events/deleted.event.engagementService';
import showEngagementServiceDeleteModalEvent from '../../events/showDeleteModal.event.engagementService';
import showEngagementServiceUpdateModalEvent from '../../events/showUpdateModal.event.engagementService';

// engagementService lib
import generateEngagementServiceActionCardText from '../../lib/generateActionCardText.lib.engagementService';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// object lib
import areSameObjectIds from '../../../object/lib/areSameIds.lib.object';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// react redux
import {connect} from 'react-redux';

// react router
import {Navigate} from 'react-router-dom';

// supplier lib
import getCompanyId from '../../../supplier/lib/getCompanyId.lib.supplier';

// tour containers
import WalkthroughContainer from '../../../tour/containers/WalkthroughContainer/WalkthroughContainer';

class EngagementServiceDetailContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    dispatch: PropTypes.func,
    engagement: PropTypes.object,
    engagementFiles: PropTypes.array,
    service: PropTypes.object,
    serviceId: PropTypes.string,
    subscribe: PropTypes.func,
  };

  state = {
    redirect: null,
  };

  componentDidMount() {
    this.initialize();
    this.props.subscribe(
      engagementServiceDeletedEvent.subscribe(this.serviceDeleted)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.serviceId !== prevProps.serviceId) {
      this.initialize();
    }
  }

  initialize = () => {
    const {company, dispatch, engagement, serviceId} = this.props;
    const services = [...(engagement?.services || [])];
    const service = [...services].find(({id}) =>
      areSameObjectIds(id, serviceId)
    );

    const companyId = getCompanyId(company);

    if (!service)
      return this.setState({
        redirect: engagementDetailRoute({
          companyId,
          engagementId: engagement.id,
        }),
      });

    dispatch(setGeneralEngagementDataAction({service, file: null}));
  };

  serviceDeleted = ({engagementService}) => {
    const {company, engagement, service} = this.props;
    if (service?.id !== engagementService?.id) return;
    const companyId = getCompanyId(company);
    this.setState({
      redirect: engagementDetailRoute({
        companyId,
        engagementId: engagement.id,
      }),
    });
  };

  breadcrumbs = () => {
    const {company, engagement, service} = this.props;
    const companyId = getCompanyId(company);
    return [
      {
        key: engagement.id,
        label: engagement.name,
        url: engagementDetailRoute({
          companyId,
          engagementId: engagement.id,
        }),
      },
      {
        key: service.id,
        label: service.name,
      },
    ];
  };

  actions = () => {
    return [
      {
        key: 'editService',
        label: 'Rename Service',
        icon: <EditOutlined />,
        onClick: this.showServiceEditorModal,
      },
      {
        key: 'deleteService',
        label: 'Close Service',
        icon: <DeleteOutlined />,
        danger: true,
        onClick: this.showServiceDeleteModal,
      },
    ];
  };

  complianceChartTitle = () => {
    const {service} = this.props;
    return service?.name;
  };

  text = () => {
    const {engagement, engagementFiles, service} = this.props;
    return generateEngagementServiceActionCardText({
      engagement,
      engagementFiles,
      service,
    });
  };

  showEngagementEditorModal = () => {
    const {company, engagement} = this.props;
    showEngagementUpdateModalEvent.publish({
      companyId: getCompanyId(company),
      engagement,
    });
  };

  showEngagementDeleteModal = () => {
    const {engagement} = this.props;
    showEngagementDeleteModalEvent.publish({engagement});
  };

  showServiceEditorModal = () => {
    const {company, engagement, service} = this.props;
    showEngagementServiceUpdateModalEvent.publish({
      companyId: getCompanyId(company),
      engagementId: engagement.id,
      engagementService: service,
    });
  };

  showServiceDeleteModal = () => {
    const {service} = this.props;
    showEngagementServiceDeleteModalEvent.publish({engagementService: service});
  };

  tourSteps = () => [
    {
      step: 'uploadFile',
      title: 'Upload File',
      description:
        'Drag a file into this area or click on the "Upload File" button to select a file.',
    },
    {
      step: 'actions',
      title: 'Actions',
      description:
        'The actions menu allows you to perform additional actions like: Create, rename and delete engagement or services',
    },
    {
      step: 'breadcrumbs',
      title: 'Switching between Engagements and Services',
      description:
        'Once multiple engagements or services are created, they will be switchable from the breadcrumbs.',
    },
  ];

  render() {
    const {service} = this.props;
    const {redirect} = this.state;

    return (
      <Fragment>
        {!!service && (
          <WalkthroughContainer
            autoStart
            steps={this.tourSteps()}
            walkthroughName="initial-engagement-file-upload"
          >
            {({domReferences, onStartTour}) => (
              <DueDiligenceDataContainer
                key={service?.id}
                complianceChartTitle={this.complianceChartTitle()}
                domReferences={domReferences}
                layoutProps={{
                  breadcrumbs: this.breadcrumbs(),
                  actions: this.actions(),
                }}
                onInitialFileUploadTour={onStartTour}
                textProps={this.text()}
              />
            )}
          </WalkthroughContainer>
        )}
        {redirect && <Navigate to={redirect} />}
      </Fragment>
    );
  }
}

export default connect((state) => ({
  company: state.company.company,
  engagement: state.engagement.engagement,
  engagementFiles: state.engagement.engagementFiles,
  service: state.engagement.service,
}))(subscriptionHOC(EngagementServiceDetailContainer));
