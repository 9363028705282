// ant components
import {Button, InputNumber, Modal, Select, Row as Gutter, Col} from 'antd';

// ant icons
import {PrinterOutlined, DownloadOutlined} from '@ant-design/icons';

// compliance components
import ComplianceFindingCard from '../ComplianceFindingCard/ComplianceFindingCard';

// layout components
import Column from '../../../layout/components/Grid/Column/Column';
import Row from '../../../layout/components/Grid/Row/Row';

// local components
import ComplianceFindingsContainer from './components/ComplianceFindingsContainer';
import Label from './components/Label';
import ScoreTagBackground from './components/ScoreTagBackground';
import ScoreTagContainer from './components/ScoreTagContainer';
import ScoreValue from './components/ScoreValue';
import ScoreValueContainer from './components/ScoreValueContainer';
import SelectContainer from './components/SelectContainer';
import SubTitle from './components/SubTitle';
import Title from './components/Title';
import TitleLabel from './components/TitleLabel';

// print components
import Print from '../../../print/components/Print/Print';
import PrintLayout from '../../../print/components/PrintLayout/PrintLayout';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

// risk lib
import getScoreColor from '../../../risk/lib/getScoreColor.lib.risk';

const ControlVarianceListModal = ({
  compliances,
  frameworks,
  onBack,
  onClose,
  onConfirm,
  onCsvDownload,
  onInsert,
  onSelect,
  results,
  selectedCompliance,
  selectedCoverageLevel,
  selectedFrameworks,
  showResults,
  visible,
}) => {
  const resultsDom = showResults && !!results.length && (
    <Row>
      <Column>
        {results.map((framework) => (
          <Row margin key={framework.framework}>
            <Column>
              <TitleLabel>{framework.framework}</TitleLabel>
              {framework.areas.map((area) => (
                <div key={area.area}>
                  <Title>
                    <SelectContainer>
                      <ScoreValueContainer>
                        <ScoreTagContainer>
                          <ScoreTagBackground
                            color={getScoreColor(area.compliance)}
                          />
                          <ScoreValue>{area.compliance}%</ScoreValue>
                        </ScoreTagContainer>
                        {area.area}
                      </ScoreValueContainer>
                    </SelectContainer>
                    <SubTitle>Coverage: {area.completeness}%</SubTitle>
                  </Title>
                  <ComplianceFindingsContainer>
                    {area.findings.map((finding) => (
                      <ComplianceFindingCard
                        key={finding.id}
                        completeness={finding.completeness}
                        compliance={finding.compliance}
                        controlDescription={finding.controlDescription}
                        controlId={finding.controlId}
                        explanation={finding.explanation}
                        // files={finding.files}
                        isPassed={finding.isPassed}
                        isTested={finding.isTested}
                        reference={finding.reference}
                        response={finding.response}
                      />
                    ))}
                  </ComplianceFindingsContainer>
                </div>
              ))}
            </Column>
          </Row>
        ))}
      </Column>
    </Row>
  );

  const renderActions = ({print}) => (
    <Row margin>
      <Column>
        <Gutter gutter={[20, 20]}>
          <Col>
            <Button icon={<PrinterOutlined />} onClick={print}>
              Print
            </Button>
          </Col>
          <Col>
            <Button icon={<DownloadOutlined />} onClick={onCsvDownload}>
              Download CSV
            </Button>
          </Col>
        </Gutter>
      </Column>
    </Row>
  );

  return (
    <Modal
      title="Controls Variance List"
      centered
      open={visible}
      onOk={onConfirm}
      okText="Generate Results"
      onCancel={onClose}
      width="800px"
    >
      <Fragment>
        <Row margin />
        <Row margin>
          <Column>
            <Label>Compliance Framework</Label>
            <Select
              mode="multiple"
              value={selectedFrameworks}
              onSelect={onSelect('selectedFrameworks')}
              onDeselect={onSelect('selectedFrameworks')}
              style={{width: '100%'}}
              placeholder="Select Framework"
              options={frameworks}
              optionFilterProp="label"
            />
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <Label>Results equal to or less than</Label>
            <Select
              value={selectedCompliance}
              onChange={onInsert('selectedCompliance')}
              style={{width: '100%'}}
              placeholder="Select Compliance Level"
              options={compliances}
              optionFilterProp="label"
            />
          </Column>
          <Column size={1 / 2}>
            <Label>Coverage Equal to or less than</Label>
            <InputNumber
              value={selectedCoverageLevel}
              onChange={onInsert('selectedCoverageLevel')}
              style={{width: '100%'}}
              suffix="%"
              min={0}
              max={100}
            />
          </Column>
        </Row>
        {showResults &&
          (!results.length ? (
            <Row margin>
              <Column>No Results Found</Column>
            </Row>
          ) : (
            <Print
              title="Controls Variance List"
              trigger={({print}) => (
                <Row>
                  <Column>
                    {renderActions({print})}
                    <Row margin>
                      <Column>{resultsDom}</Column>
                    </Row>
                    {renderActions({print})}
                  </Column>
                </Row>
              )}
            >
              <PrintLayout>{resultsDom}</PrintLayout>
            </Print>
          ))}
      </Fragment>
    </Modal>
  );
};

ControlVarianceListModal.propTypes = {
  compliances: PropTypes.array,
  frameworks: PropTypes.array,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onInsert: PropTypes.func,
  onSelect: PropTypes.func,
  results: PropTypes.array,
  selectedCompliance: PropTypes.string,
  selectedCoverageLevel: PropTypes.string,
  selectedFrameworks: PropTypes.array,
  showResults: PropTypes.bool,
  visible: PropTypes.bool,
};

export default ControlVarianceListModal;
