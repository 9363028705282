// engagement containers
import CreateEngagementContainer from '../../containers/CreateEngagementContainer/CreateEngagementContainer';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const CreateEngagementPage = () => {
  return (
    <SubPage page={page}>
      <CreateEngagementContainer />
    </SubPage>
  );
};

export default CreateEngagementPage;
