// ant components
import {Col as Column, Row} from 'antd';

// chart components
import HaloIntrinsicScoreChartCard from '../../../../chart/components/HaloIntrinsicScoreChartCard/HaloIntrinsicScoreChartCard';
import RiskAreaBreakdownChartCard from '../../../../chart/components/RiskAreaBreakdownChartCard/RiskAreaBreakdownChartCard';

// financial components
import FinancialMetricChartCard from '../../../../financial/components/FinancialMetricChartCard/FinancialMetricChartCard';
import FinancialsSummaryCard from '../../../../financial/components/FinancialsSummaryCard/FinancialsSummaryCard';
import FinancialRiskScoreCard from '../../../../financial/components/FinancialRiskScoreCard/FinancialRiskScoreCard';

// layout components
import StatementBlock from '../../../../layout/components/StatementBlock/StatementBlock';

// local components
import BusinessRelationships from './components/BusinessRelationships';
import HighCreditUtilization from './components/HighCreditUtilization';
import IndustryBreakdown from './components/IndustryBreakdown';
import IndustryTradeSummary from './components/IndustryTradeSummary';
import LegalDetails from './components/LegalDetails';
import LegalSummaries from './components/LegalSummaries';
import MostRecentFilings from './components/MostRecentFilings';
import PeopleTable from './components/PeopleTable';
import QuarterlyIndexValueAndPaymentHistory from './components/QuarterlyIndexValueAndPaymentHistory';
import TradePaymentInformation from './components/TradePaymentInformation';
import TradePaymentsByCreditExtended from './components/TradePaymentsByCreditExtended';

// local lib
import extractRiskAreaBreakdownData from './lib/extractRiskAreaBreakdownData.lib';
import extractFinancialSummaryData from './lib/extractFinancialSummaryData.lib';

// number lib
import integer from '../../../../number/lib/integer.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Financials = ({company, printView = false}) => {
  return (
    <Row gutter={[20, 20]}>
      <Column span={8}>
        <Row gutter={[20, 20]}>
          <Column span={24}>
            <HaloIntrinsicScoreChartCard
              score={company?.HaloScores?.Financial_Area?.Value}
              title="Financial Area Score"
              height="320px"
            />
          </Column>
          <Column span={24}>
            <FinancialMetricChartCard
              score={integer(
                company?.originalHaloScores?.HaloScores?.Financial_Financial
                  ?.Value
              )}
            />
          </Column>
        </Row>
      </Column>
      <Column span={16}>
        <RiskAreaBreakdownChartCard
          data={extractRiskAreaBreakdownData({company})}
          title="Financial Area Ratings"
          height="560px"
        />
      </Column>
      <Column span={24}>
        <FinancialsSummaryCard {...extractFinancialSummaryData({company})} />
      </Column>
      <Column span={24}>
        <FinancialRiskScoreCard
          internationalScore={company.financials.internationalScore}
          riskScore={company.financials.riskScore}
        />
      </Column>
      <Column span={24}>
        <StatementBlock title="Quarterly Index Value and Payment History">
          <QuarterlyIndexValueAndPaymentHistory />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Industry Trade Summary">
          <IndustryTradeSummary />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Trade Payment Information">
          <TradePaymentInformation />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="High Credit Utilization">
          <HighCreditUtilization />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Industry Breakdown">
          <IndustryBreakdown />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Trade Payments by Credit Extended">
          <TradePaymentsByCreditExtended />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Legal Summaries">
          <LegalSummaries />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Most Recent Filings">
          <MostRecentFilings />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Legal Details">
          <LegalDetails />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Business Relationships">
          <BusinessRelationships />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="People">
          <PeopleTable />
        </StatementBlock>
      </Column>
    </Row>
  );
};

Financials.propTypes = {
  company: PropTypes.object,
};

export default Financials;
