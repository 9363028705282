// ant components
import {Button, Dropdown} from 'antd';

// ant icons
import {
  DownOutlined,
  MoreOutlined,
  FolderOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';

// engagement containers
import EngagementFileManagerContainer from '../../containers/EngagementFileManagerContainer/EngagementFileManagerContainer';

// engagement routes
import engagementsRoute from '../../pages/EngagementsPage/route';

// file components
import FileManager from '../../../file/components/FileManager/FileManager';

// layout components
import Card from '../../../layout/components/Card/Card';

// local components
import Breadcrumb from './components/Breadcrumb';
import Breadcrumbs from './components/Breadcrumbs';
import Container from './components/Container';
import Content from './components/Content';
import ContentContainer from './components/ContentContainer';
import FilesContainer from './components/FilesContainer';
import FilesContent from './components/FilesContent';
import Header from './components/Header';
import HeaderActions from './components/HeaderActions';

// local lib
import generateActions from './lib/generateActions.lib';
import generateBreadcrumbItems from './lib/generateBreadcrumbItems.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment, useState} from 'react';

// react redux
import {useSelector} from 'react-redux';

// react router
import {Link, useNavigate} from 'react-router-dom';

// supplier lib
import getCompanyId from '../../../supplier/lib/getCompanyId.lib.supplier';

const DueDiligenceLayout = ({
  actions = [],
  breadcrumbs = [],
  children,
  domReferences = {},
  excludeDefaultActions = false,
  hideActions = false,
  hideDropdown = false,
  hideFiles = false,
  isCompanyLevel,
}) => {
  const navigate = useNavigate();
  const {
    company,
    compliance,
    creatingEngagement,
    creatingEngagementService,
    engagement,
    engagementFiles,
    engagements,
  } = useSelector((state) => ({
    company: state.company.company,
    compliance: state.engagement.compliance,
    creatingEngagement: state.engagement.creatingEngagement,
    creatingEngagementService: state.engagement.creatingEngagementService,
    engagement: state.engagement.engagement,
    engagementFiles: state.engagement.engagementFiles,
    engagements: state.engagement.engagements,
  }));

  const [filesVisible, setFilesVisible] = useState(false);

  const companyId = getCompanyId(company);
  const showFileManager =
    !isCompanyLevel &&
    (!!compliance?.frameworks?.length || !!engagementFiles?.length) &&
    !hideFiles;

  const displayDropDown =
    !hideDropdown &&
    (engagements.length > 1 || engagement?.services?.length > 1);

  const breadcrumbItems = displayDropDown
    ? generateBreadcrumbItems({companyId, engagements, navigate})
    : [];

  const menuActions = generateActions({
    actions,
    companyId,
    creatingEngagement,
    creatingEngagementService,
    engagementId: engagement?.id,
    excludeDefaultActions,
    hideActions,
    navigate,
  });

  return (
    <Container>
      <Header>
        <Breadcrumbs>
          {!breadcrumbs.length && !!breadcrumbItems.length ? (
            <Dropdown trigger={['click']} menu={{items: breadcrumbItems}}>
              <Breadcrumb bold>
                Due Diligence Center <DownOutlined />
              </Breadcrumb>
            </Dropdown>
          ) : (
            <Link to={engagementsRoute({companyId})}>
              <Breadcrumb bold>Due Diligence Center</Breadcrumb>
            </Link>
          )}
          {breadcrumbs.map((breadcrumb, index) => {
            const isLastItem = index === breadcrumbs.length - 1;
            const showDropDown = isLastItem && displayDropDown;
            const isUrl = !showDropDown && !!breadcrumb?.url;
            const breadcrumbDom = (
              <Breadcrumb
                ref={domReferences.breadcrumbs}
                onClick={breadcrumb?.onClick}
              >
                {breadcrumb.label}
                {showDropDown && <DownOutlined />}
              </Breadcrumb>
            );
            const breadcrumbDomWithLink = isUrl ? (
              <Link to={breadcrumb.url}>{breadcrumbDom}</Link>
            ) : (
              breadcrumbDom
            );
            return (
              <Fragment key={breadcrumb.key}>
                <div>/</div>
                {showDropDown ? (
                  <Dropdown trigger={['click']} menu={{items: breadcrumbItems}}>
                    {breadcrumbDomWithLink}
                  </Dropdown>
                ) : (
                  breadcrumbDomWithLink
                )}
              </Fragment>
            );
          })}
        </Breadcrumbs>
        <HeaderActions>
          {!!menuActions.length && (
            <Dropdown
              trigger={['click']}
              menu={{
                items: menuActions,
              }}
            >
              <Button
                ref={domReferences.actions}
                icon={<MoreOutlined />}
                iconPosition="start"
                type="primary"
              />
            </Dropdown>
          )}
          {showFileManager && (
            <Button
              icon={filesVisible ? <FolderOpenOutlined /> : <FolderOutlined />}
              iconPosition="start"
              onClick={() => setFilesVisible(!filesVisible)}
            >
              File Manager
            </Button>
          )}
        </HeaderActions>
      </Header>
      <Content>
        <ContentContainer>{children}</ContentContainer>
        {showFileManager && filesVisible && (
          <FilesContainer>
            <Card noPadding>
              <FilesContent>
                <EngagementFileManagerContainer>
                  {({
                    downloading,
                    fileActions,
                    files,
                    onDelete,
                    onDownload,
                    onSearch,
                    onUpload,
                    removing,
                    search,
                    uploading,
                  }) => (
                    <FileManager
                      downloading={downloading}
                      fileActions={fileActions}
                      files={files}
                      onDelete={onDelete}
                      onDownload={onDownload}
                      onSearch={onSearch}
                      onUpload={onUpload}
                      removing={removing}
                      search={search}
                      uploading={uploading}
                    />
                  )}
                </EngagementFileManagerContainer>
              </FilesContent>
            </Card>
          </FilesContainer>
        )}
      </Content>
    </Container>
  );
};

DueDiligenceLayout.propTypes = {
  actions: PropTypes.array,
  breadcrumbs: PropTypes.array,
  children: PropTypes.node,
  domReferences: PropTypes.object,
  excludeDefaultActions: PropTypes.bool,
  hideActions: PropTypes.bool,
  hideDropdown: PropTypes.bool,
  hideFiles: PropTypes.bool,
  isCompanyLevel: PropTypes.bool,
};

export default DueDiligenceLayout;
