// engagement lib
import getEngagementFiles from './getFiles.lib.engagement';

const generateActionCardText = ({engagement, engagementFiles}) => {
  const files = getEngagementFiles({
    files: engagementFiles,
    engagement,
  });

  return {
    subtitle: !!files.length
      ? 'Your files are being analyzed. This could take several minutes. Feel free to leave the page and come back later. You can view the status in the File Manager. Upload additional files if needed.'
      : 'Upload a file for analysis against all your compliance frameworks and questionnaires.',
    title: 'Step 2: Upload File to Engagement',
  };
};

export default generateActionCardText;
