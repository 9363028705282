// company routes
import baseRoute from '../../../company/pages/CompanyPage/route';

// route lib
import parseRoute from '../../../route/lib/parseRoute.lib.route';

const route = ({companyId = ':id'} = {}, {query, hash} = {}) =>
  parseRoute({
    path: `${baseRoute(companyId)}/engagements`,
    query,
    hash,
  });

export default route;
