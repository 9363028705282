// math lib
import multiply from '../../math/lib/multiply.lib.math';

// number lib
import integer from '../../number/lib/integer.lib.number';

// risk lib
import getScoreColor from '../../risk/lib/getScoreColor.lib.risk';

const prepareComplianceData = (complianceData) => {
  const rating = integer(multiply(complianceData?.score || 0, 100));
  const frameworks = [...(complianceData?.frameworks || [])].map(
    (framework) => {
      const frameworkType = framework.type;
      const frameworkScore = integer(multiply(framework.score || 0, 100));
      const frameworkCompleteness = integer(
        multiply(framework.coverage || 0, 100)
      );
      const frameworkShortDescription = null;
      const areas = [...(framework.areas || [])].map((area) => {
        const findings = [...(area.controls || [])].map((finding) => {
          const findingScore =
            [...(finding?.scores || [])].sort(
              ({created: createdA}, {created: createdB}) => createdB - createdA
            )?.[0] || null;
          const findingFiles = [...(finding?.scores || [])]
            .map((score) => ({
              fileId: score?.file_id,
              fileName: score?.file_name,
              fileVersionId: score?.file_version_id,
            }))
            .filter((file) =>
              Object.values(file).every((value) => value !== null)
            );
          return {
            completeness: integer(multiply(findingScore?.coverage || 0, 100)),
            compliance: integer(multiply(findingScore?.score || 0, 100)),
            controlDescription: finding?.description,
            controlId: finding?.name,
            explanation: findingScore?.explanation,
            files: findingFiles,
            isPassed: findingScore?.is_passed || false,
            isTested: findingScore?.is_tested || false,
            reference: findingScore?.reference,
            response: findingScore?.response,
          };
        });
        return {
          area: area.name,
          findings,
          compliance: integer(multiply(area.score || 0, 100)),
          completeness: integer(multiply(area.coverage || 0, 100)),
        };
      });
      const frameworkData = {
        framework: frameworkType,
        areas,
        name: frameworkType,
        standard: frameworkType,
        y: frameworkScore,
        score: frameworkScore,
        completeness: frameworkCompleteness,
        color: getScoreColor(frameworkScore),
        shortDescription: frameworkShortDescription,
      };
      return {
        ...frameworkData,
        compliance: {
          ...frameworkData,
        },
      };
    }
  );

  return {
    rating,
    frameworks,
  };
};

export default prepareComplianceData;
