import React, {useEffect} from 'react';
import {Table} from 'antd';
import Highcharts from 'highcharts';
import {v4} from 'uuid';

const HighCreditUtilizationWithChart = () => {
  const id = v4();

  const tableData = [
    {
      key: '1',
      band: '0 - 25 %',
      balance: 'Can$604,866',
      highCredit: 'Can$9,472,762',
      percentage: '6.39%',
    },
    {
      key: '2',
      band: '25 - 50 %',
      balance: 'Can$340,062',
      highCredit: 'Can$910,822',
      percentage: '37.34%',
    },
    {
      key: '3',
      band: '50 - 75 %',
      balance: 'Can$8,286,869',
      highCredit: 'Can$12,116,502',
      percentage: '68.39%',
    },
    {
      key: '4',
      band: '75 % +',
      balance: 'Can$6,816,664',
      highCredit: 'Can$7,850,648',
      percentage: '86.83%',
    },
    {
      key: '5',
      band: 'Total',
      balance: 'Can$16,048,461',
      highCredit: 'Can$30,350,734',
      percentage: '52.88%',
    },
  ];

  const columns = [
    {
      title: 'Band',
      dataIndex: 'band',
      key: 'band',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: 'High Credit',
      dataIndex: 'highCredit',
      key: 'highCredit',
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
    },
  ];

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: 'bar',
      },
      title: {
        text: 'Comparison Chart',
      },
      xAxis: {
        categories: ['0 - 25 %', '25 - 50 %', '50 - 75 %', '75 % +'],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Amount',
        },
      },
      series: [
        {
          name: 'High Credit',
          data: [9472762, 910822, 12116502, 7850648],
          color: '#FFA500',
        },
        {
          name: 'Balance',
          data: [604866, 340062, 8286869, 6816664],
          color: '#007BFF',
        },
      ],
    };
    Highcharts.chart(id, chartOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{display: 'flex', gap: '16px', flexWrap: 'wrap'}}>
      <div style={{flex: 1}}>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <div style={{flex: 1}}>
        <div id={id} />
      </div>
    </div>
  );
};

export default HighCreditUtilizationWithChart;
