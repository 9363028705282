// company redux actions
import {setGeneralCompanyData as setGeneralCompanyDataAction} from '../../../company/redux/reducer.redux.company';

// data services
import loadAndAttachAsyncJsonToObjectService from '../../../data/services/loadAndAttachAsyncJsonToObject.service.data';

// financial components
import ModernSlaveryLayout from '../../components/ModernSlaveryLayout/ModernSlaveryLayout';

// layout components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// modernSlavery api
import getModernSlaveryByIdApi from '../../../modernSlavery/api/getById.api.modernSlavery';

// propType
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// supplier lib
import getCompanyId from '../../../supplier/lib/getCompanyId.lib.supplier';

class ModernSlaveryContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    dispatch: PropTypes.object,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const {company, dispatch} = this.props;

    if (company?.loadedModernSlaveryPage)
      return this.setState({loading: false});

    try {
      const companyId = getCompanyId(company);
      const companyWithLoadedData = await loadAndAttachAsyncJsonToObjectService(
        {
          company,
          dataObject: {
            modernSlavery: getModernSlaveryByIdApi(companyId),
          },
        }
      );

      const combinedCompany = {
        ...company,
        ...companyWithLoadedData,
        loadedModernSlaveryPage: true,
      };

      dispatch(
        setGeneralCompanyDataAction({loading: false, company: combinedCompany})
      );
      this.setState({loading: false});
    } catch (error) {
      this.setState({loading: false});
    }
  };

  render() {
    const {loading} = this.state;
    return loading ? <FullScreenLoader /> : <ModernSlaveryLayout />;
  }
}

export default connect((state) => ({company: state.company.company}))(
  ModernSlaveryContainer
);
