// auth constants
// import DEMO_SUBDOMAINS from '../../auth/constants/demoSubdomains.constant.auth.js';

// company pages
// import pageForCarbonEmissionsPage from '../pages/CarbonEmissionsPage/page';
import pageForFinancialsPage from '../pages/FinancialsPage/page';
import pageForSuppliersDashboardPage from '../pages/SuppliersDashboardPage/page';
import pageForWeatherPage from '../pages/WeatherPage/page';

// company routes
// import routeForCarbonEmissionsPage from '../pages/CarbonEmissionsPage/route';
import routeForFinancialsPage from '../pages/FinancialsPage/route';
import routeForSuppliersDashboardPage from '../pages/SuppliersDashboardPage/route';
import routeForWeatherPage from '../pages/WeatherPage/route';

// compliance pages
// import pageForCompliancePage from '../../compliance/pages/CompliancePage/page';

// compliance routes
// import routeForCompliancePage from '../../compliance/pages/CompliancePage/route';

// dueDiligence pages
// import pageForDueDiligencePage from '../pages/DueDiligencePage/page';

// dueDiligence routes
// import {dueDiligenceRoute as routeForDueDiligencePage} from '../pages/DueDiligencePage/route';

// engagement pages
import routeForEngagementsPage from '../../engagement/pages/EngagementsPage/route';

// engagement routes
import pageForEngagementPage from '../../engagement/pages/EngagementPage/page';
import pageForEngagementsPage from '../../engagement/pages/EngagementsPage/page';
import pageForEngagementsListPage from '../../engagement/pages/EngagementsListPage/page';

// cyberSecurity pages
// import pageForCyberSecurityPage from '../../cyberSecurity/pages/CyberSecurityPage/page';

// cyberSecurity routes
// import routeForCyberSecurityPage from '../../cyberSecurity/pages/CyberSecurityPage/route';

// esg pages
import pageForEsgPage from '../../esg/pages/EsgPage/page';

// esg routes
import routeForEsgPage from '../../esg/pages/EsgPage/route';

// firmographic pages
import pageForFirmographicsPage from '../../firmographic/pages/FirmographicsPage/page';

// firmographic routes
import routeForFirmographicsPage from '../../firmographic/pages/FirmographicsPage/route';

// geopolitical pages
import pageForGeopoliticalPage from '../../geopolitical/pages/GeopoliticalPage/page';

// geopolitical routes
import routeForGeopoliticalPage from '../../geopolitical/pages/GeopoliticalPage/route';

// meltwater pages
import pageForMeltwaterNewsPage from '../../meltwater/pages/MeltwaterNewsPage/page';

// meltwater routes
import routeForMeltwaterNewsPage from '../../meltwater/pages/MeltwaterNewsPage/route';

// modernSlavery pages
import pageForModernSlaveryPage from '../../modernSlavery/pages/ModernSlaveryPage/page';

// modernSlavery routes
import routeForModernSlaveryPage from '../../modernSlavery/pages/ModernSlaveryPage/route';

// news pages
// import pageForNewsPage from '../../news/pages/NewsPage/page';

// news routes
// import routeForNewsPage from '../../news/pages/NewsPage/route';

// tenant pages
import pageForCustomDataPage from '../../tenant/pages/CustomDataPage/page';

// tenant routes
import routeForCustomDataPage from '../../tenant/pages/CustomDataPage/route';

// sanction pages
import pageForSanctionsPage from '../../sanction/pages/SanctionsPage/page';

// sanction routes
import routeForSanctionsPage from '../../sanction/pages/SanctionsPage/route';

const generateSubPages = (company) => {
  // const currentSubdomain = window.location.hostname.split('.')[0];
  // const isDemoSubdomain = DEMO_SUBDOMAINS.includes(currentSubdomain);
  return [
    {
      page: pageForSuppliersDashboardPage,
      url: routeForSuppliersDashboardPage(company.CompanyId),
      title: company.CompanyName,
    },
    {
      page: pageForSuppliersDashboardPage,
      url: routeForSuppliersDashboardPage(company.CompanyId),
      label: 'Dashboard',
    },
    // isDemoSubdomain && {
    //   page: pageForDueDiligencePage,
    //   url: routeForDueDiligencePage(company.CompanyId),
    //   label: 'Due Diligence',
    // },
    {
      page: pageForEngagementsPage,
      url: routeForEngagementsPage({companyId: company.CompanyId}),
      label: 'Due Diligence',
      highlightPages: [
        pageForEngagementPage,
        pageForEngagementsPage,
        pageForEngagementsListPage,
      ],
    },
    // {
    //   page: pageForCyberSecurityPage,
    //   url: routeForCyberSecurityPage(company.CompanyId),
    //   label: 'Cyber Security',
    // },
    // {
    //   page: pageForCompliancePage,
    //   url: routeForCompliancePage(company.CompanyId),
    //   label: 'Compliance',
    // },
    !!company.firmographic && {
      page: pageForFirmographicsPage,
      url: routeForFirmographicsPage(company.CompanyId),
      label: 'Firmographics',
    },
    {
      page: pageForFinancialsPage,
      url: routeForFinancialsPage(company.CompanyId),
      label: 'Financials',
    },
    {
      page: pageForSanctionsPage,
      url: routeForSanctionsPage(company.CompanyId),
      label: 'Sanctions',
    },
    // {
    //   page: pageForNewsPage,
    //   url: routeForNewsPage(company.CompanyId),
    //   label: 'Activities',
    // },
    {
      page: pageForModernSlaveryPage,
      url: routeForModernSlaveryPage(company.CompanyId),
      label: 'Modern Slavery',
    },
    {
      page: pageForMeltwaterNewsPage,
      url: routeForMeltwaterNewsPage(company.CompanyId),
      label: 'News',
    },
    {
      page: pageForEsgPage,
      url: routeForEsgPage(company.CompanyId),
      label: 'ESG',
    },
    {
      page: pageForGeopoliticalPage,
      url: routeForGeopoliticalPage(company.CompanyId),
      label: 'Geopolitical',
    },
    // {
    //   page: pageForCarbonEmissionsPage,
    //   url: routeForCarbonEmissionsPage(company.CompanyId),
    //   label: 'Carbon Emissions (Beta)',
    // },
    {
      page: pageForWeatherPage,
      url: routeForWeatherPage(company.CompanyId),
      label: 'Natural Disasters (Beta)',
    },
    {
      page: pageForCustomDataPage,
      url: routeForCustomDataPage(company.CompanyId),
      label: 'My Company Data',
    },
  ].filter((subPage) => !!subPage);
};

export default generateSubPages;
