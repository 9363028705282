import styled from 'styled-components';

const Breadcrumbs = styled.div`
  display: flex;
  gap: 16px;
  font-size: 20px;
  user-select: none;
`;

export default Breadcrumbs;
