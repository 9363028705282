// firmographic containers
import FirmographicsContainer from '../../containers/FirmographicsContainer/FirmographicsContainer';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const FirmographicsPage = () => (
  <SubPage page={page}>
    <FirmographicsContainer />
  </SubPage>
);

export default FirmographicsPage;
