// ant components
import {Tag} from 'antd';

// local components
import DataEntry from './DataEntry';
import DataEntryBackground from './DataEntryBackground';
import DataEntryValue from './DataEntryValue';
import Label from './Label';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// risk lib
import getScoreColor from '../../../../risk/lib/getScoreColor.lib.risk';

const Column = ({dataKey, entry, entryIndex}) => {
  const value = entry[dataKey];
  const color = dataKey === 'label' ? null : getScoreColor(value);
  return dataKey === 'label' ? (
    <Label>
      {value}
      {entry.isCustom && <Tag color="#f50">Custom</Tag>}
    </Label>
  ) : (
    <DataEntry transparent={!value}>
      <DataEntryBackground
        color={!value ? 'transparent' : color}
        opacity={(value || 0) / 100}
      />
      <DataEntryValue>{!value ? '-' : `${value}%`}</DataEntryValue>
    </DataEntry>
  );
};

Column.propTypes = {
  dataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default Column;
