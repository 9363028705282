// compliance containers
import ComplianceFileDetailContainer from '../../containers/ComplianceFileDetailContainer/ComplianceFileDetailContainer';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

// react router
import {useParams} from 'react-router-dom';

const ComplianceFileDetailPage = () => {
  const {fileVersionId} = useParams();
  return (
    <SubPage page={page}>
      <ComplianceFileDetailContainer fileVersionId={fileVersionId} />
    </SubPage>
  );
};

export default ComplianceFileDetailPage;
