// ant components
import {Button} from 'antd';

// ant icons
import {PrinterOutlined} from '@ant-design/icons';

// company components
import LinkedCompaniesList from '../../../company/components/LinkedCompaniesList/LinkedCompaniesList';

// company containers
import CompanySideMenuContainer from '../../../company/containers/CompanySideMenuContainer/CompanySideMenuContainer';

// company lib
import generateSubPages from '../../../company/lib/generateSubPages.lib.company';

// company routes
import suppliersDashboardRoute from '../../../company/pages/SuppliersDashboardPage/route';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// member components
import MembersList from '../../../members/components/MembersList/MembersList';

// news components
import SanctionNewsList from '../SanctionNewsList/SanctionNewsList';

// print components
import Print from '../../../print/components/Print/Print';
import PrintLayout from '../../../print/components/PrintLayout/PrintLayout';
import PrintSection from '../../../print/components/PrintSection/PrintSection';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

// sanctions components
import SanctionAmlCategories from '../SanctionAmlCategories/SanctionAmlCategories';
import SanctionSourcesTable from '../SanctionSourcesTable/SanctionSourcesTable';

const SanctionsLayout = () => {
  const {company, subPage} = useSelector((state) => ({
    company: state.company.company,
    subPage: state.layout.subPage,
  }));

  return (
    <Print
      title="Sanctions Report"
      subtitle={company.CompanyName}
      trigger={({print}) => (
        <PageLayout
          alerts={company?.alerts || []}
          previousPageLabel="Back to Dashboard"
          previousPageUrl={suppliersDashboardRoute(company.CompanyId)}
          sideContent={({goToSection}) => (
            <CompanySideMenuContainer goToSection={goToSection} />
          )}
          subPage={subPage}
          subPages={generateSubPages(company)}
          sectionName="Sanctions"
          sections={[
            {
              section: 'Linked Members',
              component: (
                <MembersList
                  members={
                    company?.sanctions?.linkedIndividuals ||
                    company?.memberCheck?.linkedIndividuals ||
                    []
                  }
                />
              ),
              moreButton: (
                <Button icon={<PrinterOutlined />} onClick={print}>
                  Print Report
                </Button>
              ),
            },
            {
              section: 'Linked Companies',
              component: (
                <LinkedCompaniesList
                  companies={
                    company?.sanctions?.linkedCompanies ||
                    company?.memberCheck?.linkedCompanies ||
                    []
                  }
                />
              ),
            },
            {
              section: 'News',
              component: (
                <SanctionNewsList
                  news={
                    company?.sanctions?.mediaSources ||
                    company?.memberCheck?.mediaSources ||
                    []
                  }
                />
              ),
            },
            {
              section: 'Sources',
              component: (
                <SanctionSourcesTable
                  data={company?.sanctions?.sources || []}
                />
              ),
            },
            {
              section: 'AML Categories',
              component: (
                <SanctionAmlCategories
                  data={company?.sanctions?.amlResults || []}
                />
              ),
            },
          ].filter((section) => !!section)}
        />
      )}
    >
      <PrintLayout>
        <PrintSection title="Linked Members">
          <MembersList
            members={
              company?.sanctions?.linkedIndividuals ||
              company?.memberCheck?.linkedIndividuals ||
              []
            }
          />
        </PrintSection>
        <PrintSection title="Linked Companies">
          <LinkedCompaniesList
            companies={
              company?.sanctions?.linkedCompanies ||
              company?.memberCheck?.linkedCompanies ||
              []
            }
          />
        </PrintSection>
        <PrintSection title="News">
          <SanctionNewsList
            news={
              company?.sanctions?.mediaSources ||
              company?.memberCheck?.mediaSources ||
              []
            }
          />
        </PrintSection>
        <PrintSection title="Sources">
          <SanctionSourcesTable data={company?.sanctions?.sources || []} />
        </PrintSection>
        <PrintSection title="AML Categories">
          <SanctionAmlCategories
            data={company?.sanctions?.amlResults || {}}
            printView
          />
        </PrintSection>
      </PrintLayout>
    </Print>
  );
};

export default SanctionsLayout;
