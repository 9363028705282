// ant components
import {Table, Col as Column, Row} from 'antd';

// layout components
import StatementBlock from '../../../../layout/components/StatementBlock/StatementBlock';

// react
import React from 'react';

const financialData = {
  revenue: {
    2021: '$31.4 million',
    2022: '$31.4 million',
    2024: '$31.4 million',
  },
  valuation: {
    2021: '$200 million',
    2022: '$400 million',
    2024: '~$800 million',
  },
  funding: {
    2021: '$140 million (Series A, Insight Partners)',
    2022: '$35 million (Series B, Hiro Capital)',
    2024: '$35 million (Series B, Hiro Capital)',
  },
  profitability: {
    2021: 'Profitable',
    2022: 'Profitable',
    2024: 'Profitable',
  },
};

const insightsData = [
  {
    key: 'User Base',
    value:
      'Over 250,000 users from 2,500 organizations, including many Fortune 100 companies, in 2024.',
  },
  {
    key: 'Technology',
    value:
      'Known for accelerating software development via distributed processing.',
  },
  {
    key: 'Growth',
    value:
      'Doubled Annual Recurring Revenue (ARR) in recent years, highlighting strong subscription growth.',
  },
];

const FinancialTable = () => {
  const columns = [
    {title: 'Metric', dataIndex: 'metric', key: 'metric'},
    {title: '2021', dataIndex: '2021', key: '2021'},
    {title: '2022', dataIndex: '2022', key: '2022'},
    {title: '2024', dataIndex: '2024', key: '2024'},
  ];

  const dataSource = Object.keys(financialData).map((key) => ({
    key,
    metric: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize first letter
    ...financialData[key],
  }));

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      rowKey="metric"
    />
  );
};

const InsightsTable = () => {
  const columns = [
    {title: 'Insight', dataIndex: 'key', key: 'key'},
    {title: 'Details', dataIndex: 'value', key: 'value'},
  ];

  return (
    <Table
      dataSource={insightsData}
      columns={columns}
      pagination={false}
      rowKey="key"
    />
  );
};

const CompanyOverview = () => {
  return (
    <Row gutter={[20, 20]}>
      <Column span={24}>
        <StatementBlock title="Financial Overview">
          <FinancialTable />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Additional Insights">
          <InsightsTable />
        </StatementBlock>
      </Column>
    </Row>
  );
};

export default CompanyOverview;
