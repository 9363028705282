import styled from 'styled-components';

const Container = styled.div`
  width: 100%;

  & div.ant-upload {
    padding: 0px !important;
    border: none !important;
    border-radius: 0px !important;
  }

  & span.ant-upload {
    padding: 0px !important;
    border: none !important;
  }
`;

export default Container;
