import styled from 'styled-components';

// colors
import green400Color from '../../../../layout/colors/green400.color.layout';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 182px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-direction: column;

  & span {
    font-size: 100px;
    color: ${green400Color};
  }
`;

export default Container;
