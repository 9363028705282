import styled from 'styled-components';

const AnalyzingContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 4px;
`;

export default AnalyzingContainer;
