import React, {useEffect} from 'react';
import {Table} from 'antd';
import Highcharts from 'highcharts';
import {v4} from 'uuid';

const TradePaymentsByCreditExtended = () => {
  const id = v4();

  const tableData = [
    {
      key: '1',
      creditExtended: 'Over $1M',
      tradeLines: 4,
      totalAmount: 'Can$12M',
      pastDue: 'Can$2.6M',
      withinTerms: '100%',
    },
    {
      key: '2',
      creditExtended: '$100K - $1M',
      tradeLines: 6,
      totalAmount: 'Can$1.8M',
      pastDue: 'Can$878.3K',
      withinTerms: '100%',
    },
    {
      key: '3',
      creditExtended: '$50K - $100K',
      tradeLines: 6,
      totalAmount: 'Can$428.3K',
      pastDue: 'Can$73.3K',
      withinTerms: '100%',
    },
    {
      key: '4',
      creditExtended: '$25K - $50K',
      tradeLines: 23,
      totalAmount: 'Can$860K',
      pastDue: 'Can$128.5K',
      withinTerms: '100%',
    },
    {
      key: '5',
      creditExtended: '$5K - $25K',
      tradeLines: 69,
      totalAmount: 'Can$741.6K',
      pastDue: 'Can$734.5K',
      withinTerms: '100%',
    },
    {
      key: '6',
      creditExtended: '$1K - $5K',
      tradeLines: 88,
      totalAmount: 'Can$215.7K',
      pastDue: 'Can$288.9K',
      withinTerms: '100%',
    },
    {
      key: '7',
      creditExtended: 'Under $1K',
      tradeLines: 589,
      totalAmount: 'Can$515K',
      pastDue: 'Can$903.6K',
      withinTerms: '100%',
    },
  ];

  const columns = [
    {
      title: 'Credit Extended',
      dataIndex: 'creditExtended',
      key: 'creditExtended',
    },
    {title: 'Trade Lines', dataIndex: 'tradeLines', key: 'tradeLines'},
    {title: 'Total Amount', dataIndex: 'totalAmount', key: 'totalAmount'},
    {title: 'Past Due', dataIndex: 'pastDue', key: 'pastDue'},
    {title: 'Within Terms', dataIndex: 'withinTerms', key: 'withinTerms'},
  ];

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {type: 'bar'},
      title: {text: 'Within Terms (%)'},
      xAxis: {categories: tableData.map((item) => item.creditExtended)},
      yAxis: {min: 0, max: 100, title: {text: 'Percentage'}},
      series: [
        {
          name: 'Within Terms (%)',
          data: tableData.map(() => 100),
          color: '#4572A7',
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{display: 'flex', gap: '16px', flexWrap: 'wrap'}}>
      <div style={{flex: 1}}>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <div style={{flex: 1}}>
        <div id={id} />
      </div>
    </div>
  );
};

export default TradePaymentsByCreditExtended;
