import styled from 'styled-components';

// colors
import whiteColor from '../../../../layout/colors/white.color.layout';

const Container = styled.div`
  width: 100%;
  height: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: linear-gradient(135.12deg, #153443 16.73%, #63d57e 209.5%);
  background-size: 200% 200%;
  color: ${whiteColor};
  padding: 0px 20px 0px 10px;
  font-size: 13px;

  animation: Animation 2s ease infinite;

  & span {
    font-size: 14px;
  }

  @keyframes Animation {
    0% {
      background-position: 10% 0%;
    }
    50% {
      background-position: 91% 100%;
    }
    100% {
      background-position: 10% 0%;
    }
  }
`;

export default Container;
