// ant components
import {Input, Modal} from 'antd';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const UpdateEngagementModal = ({
  name,
  onChange,
  onClose,
  onSave,
  saving,
  visible,
}) => (
  <Modal
    title="Update Engagement"
    centered
    open={visible}
    onOk={onSave}
    onCancel={onClose}
    okText="Save"
    loading={saving}
    width={'400px'}
  >
    <Input
      value={name}
      onChange={onChange('name')}
      disabled={saving}
      placeholder="Engagement Label"
    />
  </Modal>
);

UpdateEngagementModal.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
  visible: PropTypes.bool,
};

export default UpdateEngagementModal;
