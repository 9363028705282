// engagement api
import getEngagementByIdApi from '../api/getById.api.engagement';

// file api
import listFilesApi from '../../file/api/list.api.file';

// file events
import filesListedEvent from '../../file/events/listed.event.file';

// object lib
import areSameObjectIds from '../../object/lib/areSameIds.lib.object';

// supplier lib
import getCompanyId from '../../supplier/lib/getCompanyId.lib.supplier';

const loadEngagementService = async ({
  company,
  engagementId,
  engagements = [],
}) => {
  const companyId = getCompanyId(company);
  const existingEngagement = engagements?.find(({id}) =>
    areSameObjectIds(id, engagementId)
  );

  const [engagement, {data: engagementFiles}] = await Promise.all([
    !!existingEngagement
      ? existingEngagement
      : getEngagementByIdApi(engagementId),
    listFilesApi({
      company_id: companyId,
      engagement_id: engagementId,
      is_deleted: false,
    }),
  ]);

  filesListedEvent.publish({files: engagementFiles});

  return {
    engagement,
    engagementFiles,
  };
};

export default loadEngagementService;
