// engagement containers
import EngagementDetailContainer from '../../containers/EngagementDetailContainer/EngagementDetailContainer';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const EngagementDetailPage = () => {
  return (
    <SubPage page={page}>
      <EngagementDetailContainer />
    </SubPage>
  );
};

export default EngagementDetailPage;
