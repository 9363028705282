import React from 'react';
import {Table} from 'antd';

const LegalSummariesTable = () => {
  const data = [
    {
      key: '1',
      legalType: 'Returned cheques',
      numberOnFile: 0,
      totalAmountOnFile: '-',
      dateOfLastItem: '-',
      commentsOnFile: 'No',
    },
    {
      key: '2',
      legalType: 'Collections',
      numberOnFile: 20,
      totalAmountOnFile: 'Can$34,204.8',
      dateOfLastItem: '10/22/2024',
      commentsOnFile: 'Yes',
    },
    {
      key: '3',
      legalType: 'Legal suits',
      numberOnFile: 5,
      totalAmountOnFile: 'Can$2,848,392',
      dateOfLastItem: '03/10/2023',
      commentsOnFile: 'Yes',
    },
    {
      key: '4',
      legalType: 'Judgments',
      numberOnFile: 0,
      totalAmountOnFile: '-',
      dateOfLastItem: '-',
      commentsOnFile: 'No',
    },
    {
      key: '5',
      legalType: 'Other legal info',
      numberOnFile: 3,
      totalAmountOnFile: 'Can$0',
      dateOfLastItem: '06/23/2023',
      commentsOnFile: 'Yes',
    },
    {
      key: '6',
      legalType: 'Liens',
      numberOnFile: 25,
      totalAmountOnFile: 'Can$114,740',
      dateOfLastItem: '09/13/2024',
      commentsOnFile: 'Yes',
    },
    {
      key: '7',
      legalType: 'Bankruptcy',
      numberOnFile: 0,
      totalAmountOnFile: '-',
      dateOfLastItem: '-',
      commentsOnFile: 'No',
    },
    {
      key: '8',
      legalType: 'Total Legal',
      numberOnFile: 30,
      totalAmountOnFile: 'Can$2,963,132',
      dateOfLastItem: '-',
      commentsOnFile: '-',
    },
  ];

  const columns = [
    {
      title: 'Legal Type',
      dataIndex: 'legalType',
      key: 'legalType',
    },
    {
      title: 'Number on File',
      dataIndex: 'numberOnFile',
      key: 'numberOnFile',
    },
    {
      title: 'Total Amount on File',
      dataIndex: 'totalAmountOnFile',
      key: 'totalAmountOnFile',
    },
    {
      title: 'Date of Last Item',
      dataIndex: 'dateOfLastItem',
      key: 'dateOfLastItem',
    },
    {
      title: 'Comments on File',
      dataIndex: 'commentsOnFile',
      key: 'commentsOnFile',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      style={{width: '100%'}}
    />
  );
};

export default LegalSummariesTable;
