import React from 'react';
import {Table} from 'antd';

const LiensDetails = () => {
  const data = [
    {
      key: '1',
      reportedDate: '07/15/2024',
      lienNumber: '505959444',
      value: 'Can$0',
      cautionFiling: '-',
      collateral: 'INVENTORY, EQUIPMENT, ACCOUNTS, OTHER',
      dateRegistered: '06/03/2024',
      dateAmended: '-',
    },
    {
      key: '2',
      reportedDate: '05/31/2024',
      lienNumber: '771215355',
      value: 'Can$0',
      cautionFiling: '-',
      collateral: 'CONSUMER GOODS, OTHER',
      dateRegistered: '04/06/2021',
      dateAmended: '07/23/2024',
    },
    {
      key: '3',
      reportedDate: '05/31/2024',
      lienNumber: '787429215',
      value: 'Can$0',
      cautionFiling: '-',
      collateral: 'EQUIPMENT, OTHER',
      dateRegistered: '10/11/2022',
      dateAmended: '06/20/2024',
    },
  ];

  const columns = [
    {title: 'Reported Date', dataIndex: 'reportedDate', key: 'reportedDate'},
    {title: 'Lien Number', dataIndex: 'lienNumber', key: 'lienNumber'},
    {title: 'Value', dataIndex: 'value', key: 'value'},
    {title: 'Caution Filing', dataIndex: 'cautionFiling', key: 'cautionFiling'},
    {title: 'Collateral', dataIndex: 'collateral', key: 'collateral'},
    {
      title: 'Date Registered',
      dataIndex: 'dateRegistered',
      key: 'dateRegistered',
    },
    {title: 'Date Amended', dataIndex: 'dateAmended', key: 'dateAmended'},
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default LiensDetails;
