// company containers
import CompanySideMenuContainer from '../../../company/containers/CompanySideMenuContainer/CompanySideMenuContainer';

// company lib
import generateSubPages from '../../../company/lib/generateSubPages.lib.company';

// company route
import companyRoute from '../../../company/pages/CompanyPage/route';

// engagement redux actions
import {setGeneralEngagementData as setGeneralEngagementDataAction} from '../../redux/reducer.redux.engagement';

// engagement events
import engagementCreatedEvent from '../../events/created.event.engagement';
import engagementDeletedEvent from '../../events/deleted.event.engagement';
import engagementUpdatedEvent from '../../events/updated.event.engagement';

// engagement pages
import pageForEngagementsPage from '../../pages/EngagementsPage/page';

// engagement services
import loadCompanyEngagementsService from '../../services/loadCompanyEngagements.service.engagement';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// layout components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// react router
import {Outlet} from 'react-router-dom';

// supplier lib
import getCompanyId from '../../../supplier/lib/getCompanyId.lib.supplier';

class EngagementsContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    dispatch: PropTypes.func,
    engagements: PropTypes.array,
    subscribe: PropTypes.func,
  };

  state = {
    loading: true,
    redirect: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.initialize();
    this.props.subscribe(
      engagementCreatedEvent.subscribe(this.engagementCreated),
      engagementDeletedEvent.subscribe(this.engagementDeleted),
      engagementUpdatedEvent.subscribe(this.engagementUpdated)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  initialize = async () => {
    const {company, dispatch} = this.props;

    dispatch(
      setGeneralEngagementDataAction({
        engagements: [],
      })
    );

    const companyId = getCompanyId(company);

    try {
      const {engagements} = await loadCompanyEngagementsService({
        companyId,
      });
      if (!this.mounted) return;
      dispatch(
        setGeneralEngagementDataAction({
          engagement: null,
          engagementFiles: [],
          engagements,
        })
      );
      this.setState({loading: false});
    } catch (error) {
      if (!this.mounted) return;
      this.setState({
        redirect: companyRoute(companyId),
      });
    }
  };

  engagementCreated = ({engagement}) => {
    const {engagements} = this.props;
    const updatedEngagements = [...engagements, engagement];
    this.props.dispatch(
      setGeneralEngagementDataAction({engagements: updatedEngagements})
    );
  };

  engagementDeleted = ({engagement}) => {
    const {engagements, dispatch} = this.props;
    const updatedEngagements = [...engagements].filter(
      (existingEngagement) => existingEngagement.id !== engagement.id
    );
    dispatch(setGeneralEngagementDataAction({engagements: updatedEngagements}));
  };

  engagementUpdated = ({engagement}) => {
    const {engagements, dispatch} = this.props;
    const updatedEngagements = [...engagements].map((existingEngagement) =>
      existingEngagement.id === engagement.id ? engagement : existingEngagement
    );
    dispatch(setGeneralEngagementDataAction({engagements: updatedEngagements}));
  };

  render() {
    const {company} = this.props;
    const {loading} = this.state;
    return loading ? (
      <FullScreenLoader />
    ) : (
      <PageLayout
        sideContent={() => <CompanySideMenuContainer />}
        subPage={pageForEngagementsPage}
        subPages={generateSubPages(company)}
        sections={[
          {
            section: '',
            component: <Outlet />,
          },
        ].filter((section) => !!section)}
      />
    );
  }
}

export default connect((state) => ({
  company: state.company.company,
  engagements: state.engagement.engagements,
}))(subscriptionHOC(EngagementsContainer));
