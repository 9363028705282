// ant components
import {Dropdown, Input, List, Upload} from 'antd';

// any icons
import {
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  InboxOutlined,
  LoadingOutlined,
  SearchOutlined,
} from '@ant-design/icons';

// local components
import AnalyzingContainer from './components/AnalyzingContainer';
import Container from './components/Container';
import FileName from './components/FileName';
import ListContainer from './components/ListContainer';
import UploadContent from './components/UploadContent';

// local lib
import showDateCreated from './lib/showDateCreated.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// react redux
import {useSelector} from 'react-redux';

// react router
import {Link} from 'react-router-dom';

const FileManager = ({
  downloading = [],
  fileActions = [],
  fileIcon = <InboxOutlined />,
  fileLabel = 'File',
  files,
  onDelete,
  onDownload,
  onSearch,
  onUpload,
  removing,
  search,
  text = 'Click or drag a file to upload',
  uploading,
}) => {
  const {uploadingFile, analyzingFile} = useSelector((state) => ({
    uploadingFile: state.file.uploading,
    analyzingFile: state.file.analyzing,
  }));

  return (
    <Container>
      <Upload.Dragger
        name="file"
        multiple={false}
        showUploadList={false}
        beforeUpload={onUpload}
      >
        {uploading ? (
          <UploadContent>
            <p className="ant-upload-drag-icon">
              <LoadingOutlined spin />
            </p>
            <p className="ant-upload-text">Uploading</p>
            <p className="ant-upload-hint">{fileLabel} is being uploaded</p>
          </UploadContent>
        ) : (
          <UploadContent>
            <p className="ant-upload-drag-icon">{fileIcon}</p>
            <p className="ant-upload-text">{fileLabel} upload</p>
            <p className="ant-upload-hint">{text}</p>
          </UploadContent>
        )}
      </Upload.Dragger>
      {!!onSearch && (
        <Input
          prefix={<SearchOutlined />}
          value={search}
          onChange={onSearch}
          placeholder="Search for files"
        />
      )}
      {!!files.length && (
        <ListContainer>
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={files}
            rowKey="id"
            renderItem={(item) => (
              <List.Item
                key={item.id}
                actions={
                  removing.includes(item.id) ||
                  analyzingFile.includes(item.id) ||
                  uploadingFile.includes(item.id)
                    ? []
                    : [
                        <Dropdown
                          menu={{
                            items: [
                              ...[...fileActions].map((fileAction) => {
                                const url = fileAction?.url?.(item) || null;
                                const fileActionProps = !!url
                                  ? {
                                      label: (
                                        <Link to={url}>{fileAction.label}</Link>
                                      ),
                                    }
                                  : {
                                      onClick: (event) => {
                                        fileAction?.onClick?.({event, item});
                                      },
                                    };
                                return {
                                  ...fileAction,
                                  ...fileActionProps,
                                };
                              }),
                              !!onDownload && {
                                key: 'download',
                                label: 'Download',
                                icon: <DownloadOutlined />,
                                onClick: () => onDownload(item),
                              },
                              {
                                key: 'delete',
                                label: 'Delete',
                                icon: <DeleteOutlined />,
                                danger: true,
                                onClick: onDelete(item),
                              },
                            ].filter((item) => !!item),
                          }}
                          key="actions"
                        >
                          <DownOutlined />
                        </Dropdown>,
                      ]
                }
              >
                <List.Item.Meta
                  title={<FileName>{item.name}</FileName>}
                  description={
                    <div>
                      <div>{showDateCreated(item)}</div>
                      {uploadingFile.includes(item.id) && (
                        <AnalyzingContainer>
                          <i className="mdi mdi-loading mdi-spin" /> Uploading
                        </AnalyzingContainer>
                      )}
                      {downloading.includes(item.id) && (
                        <AnalyzingContainer>
                          <i className="mdi mdi-loading mdi-spin" /> Downloading
                        </AnalyzingContainer>
                      )}
                      {removing.includes(item.id) && (
                        <AnalyzingContainer>
                          <i className="mdi mdi-loading mdi-spin" /> Deleting
                        </AnalyzingContainer>
                      )}
                      {analyzingFile.includes(item.id) && (
                        <AnalyzingContainer>
                          <i className="mdi mdi-loading mdi-spin" /> Analyzing
                        </AnalyzingContainer>
                      )}
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </ListContainer>
      )}
    </Container>
  );
};

FileManager.propTypes = {
  downloading: PropTypes.array,
  fileActions: PropTypes.array,
  fileIcon: PropTypes.node,
  fileLabel: PropTypes.string,
  files: PropTypes.array,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onSearch: PropTypes.func,
  onUpload: PropTypes.func,
  removing: PropTypes.array,
  search: PropTypes.string,
  text: PropTypes.string,
  uploading: PropTypes.bool,
};

export default FileManager;
