// ant components
import {message as messageApi} from 'antd';

// engagement components
import DeleteEngagementModal from '../../components/DeleteEngagementModal/DeleteEngagementModal';

// engagement events
import showDeleteEngagementModalEvent from '../../events/showDeleteModal.event.engagement';

// engagement services
import deleteEngagementService from '../../services/delete.service.engagement';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class DeleteEngagementContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static INITIAL_STATE = {
    engagement: null,
    loading: false,
    visible: false,
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showDeleteEngagementModalEvent.subscribe(this.show));
  }

  show = ({engagement}) => {
    this.setState({
      ...this.constructor.INITIAL_STATE,
      visible: true,
      engagement,
    });
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  delete = async () => {
    const {engagement, loading} = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      await deleteEngagementService({
        engagementId: engagement.id,
        services: [...(engagement?.services || [])],
      });
      messageApi.success('Engagement closed');
      this.setState({loading: false, visible: false});
    } catch (error) {
      messageApi.error(error.message);
      this.setState({loading: false});
    }
  };

  render() {
    const {engagement, loading, visible} = this.state;
    return (
      <DeleteEngagementModal
        loading={loading}
        onClose={this.hide}
        onDelete={this.delete}
        service={engagement?.name || '-'}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(DeleteEngagementContainer);
