// contract components
import ContractQuestionCard from '../ContractQuestionCard/ContractQuestionCard';

// local components
import QuestionnaireContainer from './components/QuestionnaireContainer';

// math lib
import multiply from '../../../math/lib/multiply.lib.math';

// number lib
import integer from '../../../number/lib/integer.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ContractQuestionnaireDetails = ({questionnaire}) => (
  <QuestionnaireContainer>
    {questionnaire.questions.map((question) => (
      <ContractQuestionCard
        key={question.id}
        answer={question?.answer?.response}
        coverage={integer(multiply(question?.answer?.coverage || 0, 100))}
        explanation={question?.answer?.explanation}
        question={question?.prompt}
        questionId={question?.name}
        reference={question?.answer?.reference}
        file={
          !!question?.answer?.file
            ? {
                fileId: question?.answer?.file_id,
                fileName: question?.answer?.file?.name,
                fileVersionId: question?.answer?.file_version_id,
              }
            : null
        }
      />
    ))}
  </QuestionnaireContainer>
);

ContractQuestionnaireDetails.propTypes = {
  questionnaire: PropTypes.object,
};

export default ContractQuestionnaireDetails;
