import styled, {css} from 'styled-components';

const Bar = styled.div`
  ${({width}) => css`
    width: ${width}%;
    max-width: ${width}%;
    min-width: ${width}%;
  `};
  height: 100%;
  background: ${({color}) => color};
`;

export default Bar;
