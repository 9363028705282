import React from 'react';
import {Table, Badge} from 'antd';

const IndustryTradeSummary = () => {
  const data = [
    {
      key: '1',
      category: '90 Day',
      piScore: 'NA',
      creditReferences: 0,
      highCredit: 'Can$0',
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
    {
      key: '2',
      category: '13 Month',
      piScore: 0,
      creditReferences: 1,
      highCredit: 'Can$2',
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
    {
      key: '3',
      category: 'All References',
      piScore: 0,
      creditReferences: 2,
      highCredit: 'Can$72',
      currentOwing: 'Can$70',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$70',
    },
  ];

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'PI Score',
      dataIndex: 'piScore',
      key: 'piScore',
      render: (value) =>
        value === 'NA' ? (
          <Badge color="gray" text="NA" />
        ) : (
          <Badge color="green" text={`${value}`} />
        ),
    },
    {
      title: 'Credit References',
      dataIndex: 'creditReferences',
      key: 'creditReferences',
    },
    {
      title: 'High Credit',
      dataIndex: 'highCredit',
      key: 'highCredit',
    },
    {
      title: 'Current Owing',
      dataIndex: 'currentOwing',
      key: 'currentOwing',
    },
    {
      title: 'Period 1',
      dataIndex: 'period1',
      key: 'period1',
    },
    {
      title: 'Period 2',
      dataIndex: 'period2',
      key: 'period2',
    },
    {
      title: 'Period 3',
      dataIndex: 'period3',
      key: 'period3',
    },
    {
      title: 'Total Owing',
      dataIndex: 'totalOwing',
      key: 'totalOwing',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default IndustryTradeSummary;
