// local constants
import DEFAULT_ENGAGEMENT_REDUX_STATE from '../constants/defaultState.constant.redux.engagement';

const setupEngagementAction = (state, {payload = {}} = {}) => {
  const {engagements, ...defaultEngagementState} = {
    ...DEFAULT_ENGAGEMENT_REDUX_STATE,
  };
  return {
    ...state,
    ...defaultEngagementState,
    ...payload,
  };
};

export default setupEngagementAction;
