import React from 'react';
import {Table} from 'antd';

const LegalSummaries = () => {
  const data = [
    {
      key: '1',
      type: 'Returned cheques',
      number: 0,
      amount: '-',
      date: '-',
      comments: 'No',
    },
    {
      key: '2',
      type: 'Collections',
      number: 1,
      amount: 'Can$0',
      date: '08/31/2021',
      comments: 'Yes',
    },
    {
      key: '3',
      type: 'Legal suits',
      number: 0,
      amount: '-',
      date: '-',
      comments: 'No',
    },
    {
      key: '4',
      type: 'Judgments',
      number: 0,
      amount: '-',
      date: '-',
      comments: 'No',
    },
    {
      key: '5',
      type: 'Other legal info',
      number: 0,
      amount: '-',
      date: '-',
      comments: 'No',
    },
    {
      key: '6',
      type: 'Liens',
      number: 3,
      amount: 'Can$0',
      date: '06/03/2024',
      comments: 'Yes',
    },
    {
      key: '7',
      type: 'Bankruptcy',
      number: 1,
      amount: 'Can$0',
      date: '10/31/2023',
      comments: 'Yes',
    },
    {
      key: '8',
      type: 'Total Legal',
      number: 4,
      amount: 'Can$0',
      date: '-',
      comments: '-',
    },
  ];

  const columns = [
    {title: 'Legal Type', dataIndex: 'type', key: 'type'},
    {title: 'Number on File', dataIndex: 'number', key: 'number'},
    {title: 'Total Amount on File', dataIndex: 'amount', key: 'amount'},
    {title: 'Date of Last Item', dataIndex: 'date', key: 'date'},
    {title: 'Comments on File', dataIndex: 'comments', key: 'comments'},
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default LegalSummaries;
