import styled from 'styled-components';

const AnalyzingContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 30px;
  z-index: 1;
  pointer-events: none;
`;

export default AnalyzingContainer;
