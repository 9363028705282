// engagementService api
import updateEngagementServiceApi from '../api/update.api.engagementService';

// engagementService events
import engagementServiceUpdatedEvent from '../events/updated.event.engagementService';

const updateEngagementServiceService = async ({
  engagementId,
  engagementServiceId,
  name,
}) => {
  if (!name?.trim?.()?.length) throw new Error('Name is required');

  const engagement = await updateEngagementServiceApi(
    engagementId,
    engagementServiceId,
    {
      name,
    }
  );

  const engagementService = {...engagement.service};

  engagementServiceUpdatedEvent.publish({engagementService});

  return engagementService;
};

export default updateEngagementServiceService;
