// layout components
import Card from '../../../layout/components/Card/Card';

// local components
import Content from './components/Content';
import SubTitle from './components/SubTitle';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ActionCard = ({children, subtitle, title, cardProps = {}}) => (
  <Card>
    <Content {...cardProps}>
      {!!title && <Title>{title}</Title>}
      {!!subtitle && <SubTitle>{subtitle}</SubTitle>}
      {children}
    </Content>
  </Card>
);

ActionCard.propTypes = {
  cardProps: PropTypes.object,
  children: PropTypes.node,
  subtitle: PropTypes.node,
  title: PropTypes.node,
};

export default ActionCard;
