// ant icons
import {LoadingOutlined} from '@ant-design/icons';

// layout components
import Container from './components/Container';

// react
import React from 'react';

const ScreenLoader = ({children}) => (
  <Container>
    <LoadingOutlined spin />
    {children}
  </Container>
);

export default ScreenLoader;
