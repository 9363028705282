// engagement components
// import DueDiligenceLayout from '../../components/DueDiligenceLayout/DueDiligenceLayout';
// import EngagementsList from '../../components/EngagementsList/EngagementsList';

// engagement containers
import DueDiligenceDataContainer from '../DueDiligenceDataContainer/DueDiligenceDataContainer';

// engagement redux actions
import {setupEngagement as setupEngagementAction} from '../../redux/reducer.redux.engagement';

// engagement routes
import createEngagementRoute from '../../pages/CreateEngagementPage/route';
import engagementRoute from '../../pages/EngagementPage/route';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// react router
import {Navigate} from 'react-router-dom';

// supplier lib
import getCompanyId from '../../../supplier/lib/getCompanyId.lib.supplier';

class DueDiligenceCenterContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    engagements: PropTypes.array,
  };

  state = {
    initialized: false,
    redirect: null,
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const {company, dispatch, engagements} = this.props;

    const companyId = getCompanyId(company);

    const [engagement, ...otherEngagements] = [...engagements];
    if (!engagement)
      return this.setState({
        redirect: createEngagementRoute({
          companyId,
        }),
      });
    if (!!engagement && !otherEngagements?.length)
      return this.setState({
        redirect: engagementRoute({
          companyId,
          engagementId: engagement.id,
        }),
      });

    dispatch(setupEngagementAction());

    this.setState({initialized: true});
  };

  render() {
    const {company} = this.props;
    const {initialized, redirect} = this.state;
    return !!redirect ? (
      <Navigate to={redirect} replace />
    ) : initialized ? (
      <DueDiligenceDataContainer
        complianceChartTitle={company?.CompanyName}
        layoutProps={{
          actions: [],
          breadcrumbs: [],
        }}
      />
    ) : null;
    // return !!redirect ? (
    //   <Navigate to={redirect} replace />
    // ) : (
    //   <DueDiligenceLayout hideFiles hideDropdown>
    //     <EngagementsList />
    //   </DueDiligenceLayout>
    // );
  }
}

export default connect((state) => ({
  company: state.company.company,
  engagements: state.engagement.engagements,
}))(DueDiligenceCenterContainer);
