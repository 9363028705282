// engagement api
import listEngagementsApi from '../api/list.api.engagement';

const loadCompanyEngagementsService = async ({companyId}) => {
  const {data: engagements} = await listEngagementsApi({
    is_active: true,
    company_id: companyId,
  });
  return {engagements};
};

export default loadCompanyEngagementsService;
