// layout color
import brightGreen300Color from '../../layout/colors/brightGreen300.color.layout';
import brightRed300Color from '../../layout/colors/brightRed300.color.layout';
import brightRed400Color from '../../layout/colors/brightRed400.color.layout';
import brightRed500Color from '../../layout/colors/brightRed500.color.layout';
import brightYellow500Color from '../../layout/colors/brightYellow500.color.layout';
import green300Color from '../../layout/colors/green300.color.layout';

const SCORE_LEVELS = [
  [30, brightRed500Color],
  [45, brightRed400Color],
  [60, brightRed300Color],
  [75, brightYellow500Color],
  [85, green300Color],
  [100, brightGreen300Color],
];

export default SCORE_LEVELS;
