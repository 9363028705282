// alert redux reducer
import alertReduxReducer from '../alert/redux/reducer.redux.alert';

// auth redux reducer
import authReduxReducer from '../auth/redux/reducer.redux.auth';

// collection redux reducer
import collectionReduxReducer from '../collection/redux/reducer.redux.collection';

// company redux reducer
import companyReduxReducer from '../company/redux/reducer.redux.company';

// compliance redux reducer
import complianceReduxReducer from '../compliance/redux/reducer.redux.compliance';

// dashboard redux reducer
import dashboardReduxReducer from '../dashboard/redux/reducer.redux.dashboard';

// engagement redux reducer
import engagementReduxReducer from '../engagement/redux/reducer.redux.engagement';

// file redux reducer
import fileReduxReducer from '../file/redux/reducer.redux.file';

// layout redux reducer
import layoutReduxReducer from '../layout/redux/reducer.redux.layout';

// dimensions redux reducer
import dimensionsReduxReducer from '../company/components/DueDiligenceSection/store/reducer.redux.dimensions';

// meltwater redux reducer
import newsReduxReducer from '../meltwater/redux/reducer.redux.meltwater';

// notification redux reducer
import notificationReduxReducer from '../notification/redux/reducer.redux.notification';

// redux
import {configureStore} from '@reduxjs/toolkit';

// supplier redux reducer
import supplierReduxReducer from '../supplier/redux/reducer.redux.supplier';

// tag redux reducer
import tagReduxReducer from '../tag/redux/reducer.redux.tag';

// tenant redux reducer
import tenantReduxReducer from '../tenant/redux/reducer.redux.tenant';

const reduxStore = configureStore({
  reducer: {
    alert: alertReduxReducer,
    auth: authReduxReducer,
    collection: collectionReduxReducer,
    company: companyReduxReducer,
    compliance: complianceReduxReducer,
    dashboard: dashboardReduxReducer,
    dimensions: dimensionsReduxReducer,
    engagement: engagementReduxReducer,
    file: fileReduxReducer,
    layout: layoutReduxReducer,
    news: newsReduxReducer,
    notification: notificationReduxReducer,
    supplier: supplierReduxReducer,
    tag: tagReduxReducer,
    tenant: tenantReduxReducer,
  },
});

export default reduxStore;
