// engagement routes
import engagementServiceDetailRoute from '../../../../engagementService/pages/EngagementServiceDetailPage/route';

const openEngagementServiceHandler =
  ({companyId, engagementId, navigate, serviceId}) =>
  () =>
    navigate(
      engagementServiceDetailRoute({companyId, engagementId, serviceId})
    );

export default openEngagementServiceHandler;
