// lib
import isObject from 'is-object';

const renderStringObjectOrArray = (data) =>
  Array.isArray(data)
    ? data.join(', ')
    : isObject(data)
    ? `<ul>${Object.entries(data)
        .map(([key, entry]) => `<li>${key}: ${entry}</li>`)
        .join('')}</ul>`
    : data;

const parseFindingResponse = (response) => {
  try {
    const cleanedResponse = response.split('NaN, ').join('null, ');
    const parsedResponse = JSON.parse(cleanedResponse);

    const responseLines = [
      !!parsedResponse?.Requested_Documentation
        ? {
            title: 'Requested Documentation',
            content: renderStringObjectOrArray(
              parsedResponse?.Requested_Documentation
            ),
          }
        : null,
      !!parsedResponse?.Response?.Test_Plan
        ? {
            title: 'Test Plan',
            content: renderStringObjectOrArray(
              parsedResponse?.Response?.Test_Plan
            ),
          }
        : null,
      !!parsedResponse?.Response?.Tested_Detailed_Plan
        ? {
            title: 'Is Test Plan Detailed',
            content: renderStringObjectOrArray(
              parsedResponse?.Response?.Tested_Detailed_Plan
            ),
          }
        : null,
      !!parsedResponse?.Response?.Risk_Rating
        ? {
            title: 'Risk Rating',
            content: renderStringObjectOrArray(
              parsedResponse?.Response?.Risk_Rating
            ),
          }
        : null,
      !!parsedResponse?.Response?.Risk_Mitigation_Steps
        ? {
            title: 'Risk Mitigation Steps',
            content: renderStringObjectOrArray(
              parsedResponse?.Response?.Risk_Mitigation_Steps
            ),
          }
        : null,
      !!parsedResponse?.Response?.Mapped_Frameworks
        ? {
            title: 'Mapped Frameworks',
            content: renderStringObjectOrArray(
              parsedResponse?.Response?.Mapped_Frameworks
            ),
          }
        : null,
    ].filter((entry) => !!entry);
    return !!responseLines.length
      ? responseLines
      : [{title: 'Response', content: response}];
  } catch (error) {
    return [{title: 'Response', content: response}];
  }
};

export default parseFindingResponse;
