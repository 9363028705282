// contract api
import listContractAnswersApi from '../../api/listAnswers.api.contract';

// contract components
import ContractAnswersModal from '../../components/ContractAnswersModal/ContractAnswersModal';

// contract events
import showContractAnswersModalEvent from '../../events/showContractAnswersModal.event.contract';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class ContractAnswersContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static INITIAL_STATE = {
    answers: [],
    engagementId: null,
    fileId: null,
    loading: false,
    questionnaire: null,
    questionnaires: [],
    visible: false,
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showContractAnswersModalEvent.subscribe(this.show));
  }

  show = async ({engagementId, fileId = null}) => {
    this.setState({
      ...this.constructor.INITIAL_STATE,
      engagementId,
      fileId,
      loading: true,
      visible: true,
    });

    try {
      const {data: questionnaires} = await listContractAnswersApi(
        engagementId,
        !!fileId
          ? {
              file_id: fileId,
            }
          : {}
      );
      const questionnaire = questionnaires?.[0] || null;
      const answers = [...(questionnaire?.questions || [])];
      this.setState({answers, questionnaire, questionnaires, loading: false});
    } catch (error) {
      this.setState({loading: false});
    }
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  questionnaireOptions = () =>
    [...this.state.questionnaires].map(({id, type}) => ({
      label: type,
      value: id,
    }));

  changeQuestionnaire = async (questionnaireId) => {
    const questionnaire = [...this.state.questionnaires].find(
      ({id}) => id === questionnaireId
    );
    const answers = [...(questionnaire?.questions || [])];
    this.setState({answers, questionnaire});
  };

  render() {
    const {answers, questionnaire, loading, visible} = this.state;
    return (
      <ContractAnswersModal
        answers={answers}
        loading={loading}
        onClose={this.hide}
        onQuestionnaire={this.changeQuestionnaire}
        questionnaire={questionnaire?.id || null}
        questionnaires={this.questionnaireOptions()}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(ContractAnswersContainer);
