// ant components
import {Button} from 'antd';

// engagement components
import ActionCard from '../ActionCard/ActionCard';

// local components
// import InputContainer from './components/InputContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const CreateEngagementCard = ({
  domReferences,
  name,
  onChange,
  onSave,
  saving,
}) => (
  <ActionCard
    title="Step 1. Create a New Engagement"
    subtitle="Create a new engagement with the selected supplier."
    cardProps={{ref: domReferences.welcome}}
  >
    {/* <InputContainer>
      <Input
        value={name}
        onChange={onChange('name')}
        disabled={saving}
        placeholder="Engagement Label"
      />
    </InputContainer> */}
    <Button
      ref={domReferences.start}
      onClick={onSave}
      loading={saving}
      // disabled={!name?.trim?.()?.length}
      type="primary"
    >
      Create Engagement
    </Button>
  </ActionCard>
);

CreateEngagementCard.propTypes = {
  domReferences: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
};

export default CreateEngagementCard;
