// engagement api
import createEngagementApi from '../api/create.api.engagement';

// engagement events
import engagementCreatedEvent from '../events/created.event.engagement';

const createEngagementService = async ({companyId, name = null}) => {
  // if (!name?.trim?.()?.length) throw new Error('Name is required');
  if (!companyId) throw new Error('Company is required');

  const query = {company_id: companyId};
  if (!!name?.trim?.()?.length) query.name = name;

  const engagement = await createEngagementApi(query);

  engagementCreatedEvent.publish({engagement});

  return engagement;
};

export default createEngagementService;
