// alert components
import AlertsSlider from '../../../alert/components/AlertsSlider/AlertsSlider';

// layout components
import SideMenuItems from '../SideMenuItems/SideMenuItems';
import SectionScroller from '../SectionScroller/SectionScroller';

// local constants
import subPageHeight from './constants/subPageHeight.constant';

// local components
import Container from './components/Container';
import Content from './components/Content/Content';
import ContentContainer from './components/ContentContainer';
// import NavigationLine from './components/NavigationLine';
import NavigationTitleTab from './components/NavigationTitleTab';
import NavigationTab from './components/NavigationTab';
import Side from './components/Side';
import SubNavigation from './components/SubNavigation';
import SubNavigationContent from './components/SubNavigationContent';

// local lib
import generateSectionKey from './lib/generateSectionKey.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useState} from 'react';

const PageLayout = ({
  alerts = [],
  chat = null,
  children = () => null,
  previousPageLabel,
  previousPageUrl,
  sectionName,
  sections = [],
  sideContent = null,
  subPage = null,
  subPages = [],
}) => {
  const [visibleSection, setVisibleSection] = useState(
    sections?.[0]?.section || null
  );

  const withSubPages = !!subPages.length;
  const withSideContent = !!sections.length || !!sideContent;

  const subNavigationDom = withSubPages ? (
    <SubNavigation withSideContent={withSideContent}>
      <SubNavigationContent>
        {subPages.map((page) =>
          page.title ? (
            <NavigationTitleTab to={page.url} key={`${page.title}${page.url}`}>
              {page.title}
            </NavigationTitleTab>
          ) : (
            <NavigationTab
              to={page.url}
              key={page.url}
              selected={
                subPage === page.page ||
                (page?.highlightPages || []).includes(subPage)
              }
            >
              {page.label}
            </NavigationTab>
          )
        )}
      </SubNavigationContent>
    </SubNavigation>
  ) : null;

  return !!sections.length ? (
    <SectionScroller
      offset={withSubPages ? subPageHeight : 0}
      onVisibilityChange={(section) => {
        if (visibleSection === section) return;
        setVisibleSection(section);
      }}
    >
      {({Section, goToSection}) => (
        <Container>
          <AlertsSlider alerts={alerts} />
          <Side withSubPages={withSubPages}>
            {!!sideContent && sideContent({goToSection})}
            <SideMenuItems
              onSection={goToSection}
              // previousPageLabel={previousPageLabel}
              // previousPageUrl={previousPageUrl}
              // sectionName={sectionName}
              sections={[...sections].map(({section}) => ({
                section: generateSectionKey(section),
                label: section,
              }))}
              selectedSection={visibleSection}
            />
          </Side>
          <ContentContainer
            withSubPages={withSubPages}
            withSideContent
            withChat={!!chat}
          >
            {subNavigationDom}
            {children({Content})}
            {sections.map((section, index) => (
              <Section
                section={generateSectionKey(section.section)}
                key={section.section}
              >
                <Content
                  contentStyle={section.contentStyle}
                  haloGpt={section.haloGpt}
                  modalContents={section.modalContents}
                  moreButton={section.moreButton}
                  primaryButton={section.primaryButton}
                  primaryScore={section.primaryScore}
                  secondaryGrade={section.secondaryGrade}
                  secondaryScore={section.secondaryScore}
                  tabs={section.tabs}
                  title={section.title || section.section}
                  titleTooltip={section.titleTooltip}
                  withBorder={!!index}
                >
                  {section.component}
                </Content>
              </Section>
            ))}
            {chat}
          </ContentContainer>
        </Container>
      )}
    </SectionScroller>
  ) : (
    <Container>
      <AlertsSlider alerts={alerts} />
      {!!sideContent && <Side>{sideContent()}</Side>}
      <ContentContainer
        withSubPages={withSubPages}
        withSideContent={withSideContent}
        withChat={!!chat}
      >
        {subNavigationDom}
        {children({Content})}
        {chat}
      </ContentContainer>
    </Container>
  );
};

PageLayout.propTypes = {
  alerts: PropTypes.array,
  chat: PropTypes.node,
  children: PropTypes.func,
  previousPageLabel: PropTypes.node,
  previousPageUrl: PropTypes.string,
  sectionName: PropTypes.node,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.object,
      section: PropTypes.string,
      tabs: PropTypes.array,
      title: PropTypes.node,
      titleTooltip: PropTypes.node,
    })
  ),
  sideContent: PropTypes.func,
  subPage: PropTypes.string,
  subPages: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default PageLayout;
