// ant components
import {Button, Upload} from 'antd';

// engagement components
import ActionCard from '../ActionCard/ActionCard';

// file components
import AnalyzingIndicator from '../../../file/components/AnalyzingIndicator/AnalyzingIndicator';

// local components
import AnalyzingContainer from './components/AnalyzingContainer';
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const FulScreenFileUploadCard = ({
  analyzingFiles,
  buttonContent = 'Upload File',
  domReferences,
  onUpload,
  subtitle,
  title,
  uploading,
}) => {
  return (
    <Container>
      <Upload.Dragger
        name="file"
        multiple={false}
        showUploadList={false}
        beforeUpload={onUpload}
        style={{padding: '0px'}}
      >
        <ActionCard
          cardProps={{ref: domReferences?.uploadFile}}
          title={title}
          subtitle={subtitle}
        >
          {analyzingFiles && (
            <AnalyzingContainer>
              <AnalyzingIndicator />
            </AnalyzingContainer>
          )}
          <Button type="primary" loading={uploading}>
            {buttonContent}
          </Button>
        </ActionCard>
      </Upload.Dragger>
    </Container>
  );
};

FulScreenFileUploadCard.propTypes = {
  analyzingFiles: PropTypes.bool,
  buttonContent: PropTypes.node,
  domReferences: PropTypes.object,
  onUpload: PropTypes.func,
  subtitle: PropTypes.node,
  title: PropTypes.node,
  uploading: PropTypes.bool,
};

export default FulScreenFileUploadCard;
