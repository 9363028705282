const DEFAULT_ENGAGEMENT_REDUX_STATE = {
  compliance: {
    rating: 0,
    frameworks: [],
  },
  creatingEngagement: false,
  creatingEngagementService: false,
  engagement: null,
  engagementFiles: [],
  engagements: [],
  file: null,
  questionnaires: [],
  service: null,
};

export default DEFAULT_ENGAGEMENT_REDUX_STATE;
