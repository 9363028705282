import React from 'react';
import {Table, Badge} from 'antd';

const QuarterlyIndexPaymentHistory = () => {
  const data = [
    {
      key: '1',
      quarter: 'Q4 2024',
      paymentIndex: '-',
      creditIndex: 10,
      references: 0,
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
    {
      key: '2',
      quarter: 'Q3 2024',
      paymentIndex: 0,
      creditIndex: 10,
      references: 1,
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
    {
      key: '3',
      quarter: 'Q2 2024',
      paymentIndex: '-',
      creditIndex: 10,
      references: 0,
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
    {
      key: '4',
      quarter: 'Q1 2024',
      paymentIndex: '-',
      creditIndex: 10,
      references: 0,
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
    {
      key: '5',
      quarter: 'Q4 2023',
      paymentIndex: '-',
      creditIndex: 10,
      references: 0,
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
    {
      key: '6',
      quarter: 'Q3 2023',
      paymentIndex: '-',
      creditIndex: 10,
      references: 0,
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
    {
      key: '7',
      quarter: 'Q2 2023',
      paymentIndex: '-',
      creditIndex: 10,
      references: 0,
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
    {
      key: '8',
      quarter: 'Q1 2023',
      paymentIndex: '-',
      creditIndex: 10,
      references: 0,
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
    {
      key: '9',
      quarter: 'Q4 2022',
      paymentIndex: '-',
      creditIndex: 10,
      references: 0,
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
    {
      key: '10',
      quarter: 'Q3 2022',
      paymentIndex: '-',
      creditIndex: 10,
      references: 0,
      currentOwing: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$0',
    },
  ];

  const columns = [
    {title: 'Quarter', dataIndex: 'quarter', key: 'quarter'},
    {
      title: 'Payment Index',
      dataIndex: 'paymentIndex',
      key: 'paymentIndex',
      render: (value) =>
        value === '-' ? (
          <Badge color="gray" text="-" />
        ) : (
          <Badge color="green" text={`${value}`} />
        ),
    },
    {title: 'Credit Index', dataIndex: 'creditIndex', key: 'creditIndex'},
    {title: 'No. of References', dataIndex: 'references', key: 'references'},
    {title: 'Current Owing', dataIndex: 'currentOwing', key: 'currentOwing'},
    {title: 'Period 1', dataIndex: 'period1', key: 'period1'},
    {title: 'Period 2', dataIndex: 'period2', key: 'period2'},
    {title: 'Period 3', dataIndex: 'period3', key: 'period3'},
    {title: 'Total Owing', dataIndex: 'totalOwing', key: 'totalOwing'},
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default QuarterlyIndexPaymentHistory;
