// local components
// import Content from './components/Content';

// react
import React from 'react';

// react markdown
import ReactMarkdown from 'react-markdown';

// redux
import {useSelector} from 'react-redux';

const ModernSlaverySummary = () => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  return (
    <ReactMarkdown>
      {company?.modernSlavery?.summary || 'No Modern Slavery Data found'}
    </ReactMarkdown>
  );
};

export default ModernSlaverySummary;
