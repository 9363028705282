// engagement api
import updateEngagementApi from '../api/update.api.engagement';

// engagement events
import engagementUpdatedEvent from '../events/updated.event.engagement';

const updateEngagementService = async ({engagementId, name, services = []}) => {
  if (!name?.trim?.()?.length) throw new Error('Name is required');

  const engagement = await updateEngagementApi(engagementId, {
    name,
  });

  const updatedEngagement = {
    ...engagement,
    services,
  };

  engagementUpdatedEvent.publish({engagement: updatedEngagement});

  return updatedEngagement;
};

export default updateEngagementService;
