// engagement routes
import engagementRoute from '../EngagementPage/route';

// route lib
import parseRoute from '../../../route/lib/parseRoute.lib.route';

const route = (
  {companyId = ':id', engagementId = ':engagementId'} = {},
  {query, hash} = {}
) =>
  parseRoute({
    path: engagementRoute({companyId, engagementId}),
    query,
    hash,
  });

export default route;
