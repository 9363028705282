// ant components
import {Table} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const IndustryBusinessClassificationTable = ({firmographics}) => {
  if (!firmographics) return null;

  const {
    main_business_category,
    main_industry,
    main_sector,
    naics_2022,
    nace_rev2,
    sic,
    isic_v4,
    sics,
    ibc_insurance,
  } = firmographics;

  const data = [
    main_business_category && {
      key: 'main_business_category',
      field: 'Main Business Category',
      value: main_business_category,
    },
    main_industry && {
      key: 'main_industry',
      field: 'Main Industry',
      value: main_industry,
    },
    main_sector && {
      key: 'main_sector',
      field: 'Main Sector',
      value: main_sector,
    },
    naics_2022?.primary && {
      key: 'naics',
      field: 'NAICS 2022 (North American Industry Classification System)',
      value: `${naics_2022.primary.code} - ${naics_2022.primary.label}`,
    },
    nace_rev2?.length && {
      key: 'nace_rev2',
      field:
        'NACE Rev2 (Statistical Classification of Economic Activities in the European Community)',
      value: (
        <>
          {nace_rev2.map((item) => (
            <span key={item.code}>
              {item.code} - {item.label} <br />
            </span>
          ))}
        </>
      ),
    },
    sic?.length && {
      key: 'sic',
      field: 'SIC (Standard Industrial Classification)',
      value: `${sic[0].code} - ${sic[0].label}`,
    },
    isic_v4?.length && {
      key: 'isic',
      field:
        'ISIC V4 (International Standard Industrial Classification of All Economic Activities)',
      value: `${isic_v4[0].code} - ${isic_v4[0].label}`,
    },
    sics?.length && {
      key: 'sics',
      field:
        'SICS (Sustainability Accounting Standards Board Industry Classification System)',
      value: (
        <>
          {sics.map((item) => (
            <span key={item.code}>
              {item.typeLabel}: {item.code} - {item.label} <br />
            </span>
          ))}
        </>
      ),
    },
    ibc_insurance?.length && {
      key: 'ibc_insurance',
      field: 'IBC Insurance (Insurance Bureau of Canada)',
      value: (
        <>
          {ibc_insurance.map((item) => (
            <span key={item.code}>
              {item.code} - {item.label} <br />
            </span>
          ))}
        </>
      ),
    },
  ].filter(Boolean); // Removes null or undefined values

  return (
    <StatementBlock title="Industry & Business Classification">
      <Table
        columns={[
          {
            dataIndex: 'field',
            key: 'field',
            render: (text) => <strong>{text}</strong>,
          },
          {
            dataIndex: 'value',
            key: 'value',
          },
        ]}
        dataSource={data}
        pagination={false}
        size="small"
        rowKey="key"
        showHeader={false} // Hides the table header
      />
    </StatementBlock>
  );
};

IndustryBusinessClassificationTable.propTypes = {
  firmographics: PropTypes.object,
};

export default IndustryBusinessClassificationTable;
