// engagement routes
import engagementsRoute from '../EngagementsPage/route';

// route lib
import parseRoute from '../../../route/lib/parseRoute.lib.route';

const route = ({companyId = ':id'} = {}, {query, hash} = {}) =>
  parseRoute({
    path: engagementsRoute({companyId}),
    query,
    hash,
  });

export default route;
