import React from 'react';
import {Table, Badge} from 'antd';

const IndustryTradeSummary = () => {
  const getBadgeStatus = (score) => {
    if (score <= 20) return 'success'; // Green
    if (score <= 50) return 'warning'; // Yellow
    return 'error'; // Red
  };

  const data = [
    {
      key: '1',
      period: '90 Day',
      piScore: 26,
      creditReferences: 298,
      highCredit: 'Can$3.6M',
      currentOwing: 'Can$376.8K',
      period1: 'Can$478.3K',
      period2: 'Can$339K',
      period3: 'Can$71.5K',
      totalOwing: 'Can$1.8M',
    },
    {
      key: '2',
      period: '13 Month',
      piScore: 27,
      creditReferences: 493,
      highCredit: 'Can$7.1M',
      currentOwing: 'Can$1.9M',
      period1: 'Can$804.2K',
      period2: 'Can$538.4K',
      period3: 'Can$175.5K',
      totalOwing: 'Can$3.4M',
    },
    {
      key: '3',
      period: 'All References',
      piScore: 13,
      creditReferences: 571,
      highCredit: 'Can$21.1M',
      currentOwing: 'Can$11.3M',
      period1: 'Can$1.5M',
      period2: 'Can$1.1M',
      period3: 'Can$544K',
      totalOwing: 'Can$14.4M',
    },
  ];

  const columns = [
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: 'PI Score',
      dataIndex: 'piScore',
      key: 'piScore',
      render: (score) => <Badge status={getBadgeStatus(score)} text={score} />,
    },
    {
      title: 'Credit References',
      dataIndex: 'creditReferences',
      key: 'creditReferences',
    },
    {
      title: 'High Credit',
      dataIndex: 'highCredit',
      key: 'highCredit',
    },
    {
      title: 'Current Owing',
      dataIndex: 'currentOwing',
      key: 'currentOwing',
    },
    {
      title: 'Period 1',
      dataIndex: 'period1',
      key: 'period1',
    },
    {
      title: 'Period 2',
      dataIndex: 'period2',
      key: 'period2',
    },
    {
      title: 'Period 3',
      dataIndex: 'period3',
      key: 'period3',
    },
    {
      title: 'Total Owing',
      dataIndex: 'totalOwing',
      key: 'totalOwing',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default IndustryTradeSummary;
