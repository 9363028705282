import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';

const OptionGroupTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${blackColor};
`;

export default OptionGroupTitle;
