// engagement containers
import EngagementContainer from '../../containers/EngagementContainer/EngagementContainer';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

// react router
import {useParams} from 'react-router-dom';

const EngagementPage = () => {
  const {engagementId} = useParams();
  return (
    <SubPage page={page}>
      <EngagementContainer engagementId={engagementId} />
    </SubPage>
  );
};

export default EngagementPage;
