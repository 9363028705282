// compliance api
import listComplianceFrameworkScoresApi from '../api/listScores.api.compliance';

// engagementService lib
import prepareComplianceData from '../lib/prepareComplianceData.lib.compliance';

const listFrameworkScoresService = async ({
  companyId,
  fileVersionId,
  serviceId,
  engagementId,
}) => {
  try {
    const query = !!fileVersionId
      ? {file_version_id: fileVersionId}
      : !!serviceId
      ? {engagement_service_id: serviceId}
      : !!engagementId
      ? {engagement_id: engagementId}
      : {company_id: companyId};

    const frameworkScores = await listComplianceFrameworkScoresApi(query);

    return prepareComplianceData(frameworkScores);
  } catch (error) {
    return {rating: 0, frameworks: []};
  }
};

export default listFrameworkScoresService;
