// ant components
import {Card, Select} from 'antd';

// compliance components
import ComplianceEntriesTable from '../ComplianceEntriesTable/ComplianceEntriesTable';
import ComplianceFindingCard from '../ComplianceFindingCard/ComplianceFindingCard';

// local components
import ComplianceFindingsContainer from './components/ComplianceFindingsContainer';
import ScoreTagBackground from './components/ScoreTagBackground';
import ScoreTagContainer from './components/ScoreTagContainer';
import ScoreValue from './components/ScoreValue';
import ScoreValueContainer from './components/ScoreValueContainer';

// local handlers
import selectAreaHandler from './handlers/selectArea.handler';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment, useState, useEffect} from 'react';

// risk lib
import getScoreColor from '../../../risk/lib/getScoreColor.lib.risk';

const ComplianceFrameworkDetails = ({framework}) => {
  const [area, setArea] = useState(null);
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    const parsedAreas = [...framework.areas].map(
      ({area, compliance, completeness}) => ({
        color: getScoreColor(compliance),
        completeness,
        id: area,
        label: area,
        score: compliance,
      })
    );
    setArea(null);
    setAreas(parsedAreas);
  }, [framework]);

  return (
    <Fragment>
      {!area ? (
        <ComplianceEntriesTable
          entries={areas}
          onSelect={selectAreaHandler({areas: framework.areas, setArea})}
        />
      ) : (
        <ComplianceFindingsContainer>
          <Select
            value={area.area}
            onChange={selectAreaHandler({areas: framework.areas, setArea})}
            size="large"
            style={{width: '100%'}}
          >
            {areas.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                <ScoreValueContainer>
                  <ScoreTagContainer>
                    <ScoreTagBackground color={option.color} />
                    <ScoreValue>{option.score}%</ScoreValue>
                  </ScoreTagContainer>
                  <ScoreTagContainer>
                    <ScoreTagBackground />
                    <ScoreValue>{option.completeness}%</ScoreValue>
                  </ScoreTagContainer>
                  {option.label}
                </ScoreValueContainer>
              </Select.Option>
            ))}
          </Select>
          {!area.findings.length && <Card>No findings found</Card>}
          {area.findings.map((finding) => (
            <ComplianceFindingCard
              key={finding.id}
              completeness={finding.completeness}
              compliance={finding.compliance}
              controlDescription={finding.controlDescription}
              controlId={finding.controlId}
              explanation={finding.explanation}
              files={finding.files}
              isPassed={finding.isPassed}
              isTested={finding.isTested}
              reference={finding.reference}
              response={finding.response}
            />
          ))}
        </ComplianceFindingsContainer>
      )}
    </Fragment>
  );
};

ComplianceFrameworkDetails.propTypes = {
  framework: PropTypes.object,
};

export default ComplianceFrameworkDetails;
