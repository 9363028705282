// ant components
import {message as messageApi} from 'antd';

// ant icons
import {FileSearchOutlined} from '@ant-design/icons';

// compliance routes
import complianceFileDetailRoute from '../../../compliance/pages/ComplianceFileDetailPage/route';

// contract events
import showContractAnswersModalEvent from '../../../contract/events/showContractAnswersModal.event.contract';

// engagement lib
import getEngagementFiles from '../../lib/getFiles.lib.engagement';

// engagement redux actions
import {setGeneralEngagementData as setGeneralEngagementDataAction} from '../../redux/reducer.redux.engagement';

// engagement services
import uploadEngagementFileService from '../../services/uploadEngagementFile.service.engagement';

// file services
import deleteFileService from '../../../file/services/delete.service.file';
import getDownloadLinkService from '../../../file/services/getDownloadLink.service.file';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// supplier lib
import getCompanyId from '../../../supplier/lib/getCompanyId.lib.supplier';

class EngagementFileManagerContainer extends Component {
  static propTypes = {
    children: PropTypes.func,
    company: PropTypes.object,
    dispatch: PropTypes.func,
    engagement: PropTypes.object,
    engagementFiles: PropTypes.array,
    service: PropTypes.object,
    uploadingFiles: PropTypes.array,
  };

  static defaultProps = {
    children: () => null,
  };

  state = {
    removing: [],
    downloading: [],
    search: '',
    uploading: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleUpload = (file) => {
    this.upload({file});
    return false;
  };

  search = (e) => {
    const value = !!e?.target ? e.target.value : e;
    this.setState({search: value});
  };

  upload = async ({file}) => {
    const {company, dispatch, engagement, service} = this.props;
    const {uploading} = this.state;
    if (uploading) return;

    this.setState({uploading: true});

    try {
      const createdFile = await uploadEngagementFileService({
        company,
        engagement,
        file,
        service,
      });
      if (!this.mounted) return;
      const updatedFiles = [...this.props.engagementFiles, createdFile];
      dispatch(setGeneralEngagementDataAction({engagementFiles: updatedFiles}));
      this.setState({uploading: false});
    } catch (error) {
      if (!this.mounted) return;
      messageApi.error(error?.message || 'Failed to upload file');
      this.setState({uploading: false});
    }
  };

  remove = async (fileToRemove) => {
    if (this.state.removing.includes(fileToRemove.id)) return;

    this.setState({
      removing: [...this.state.removing, fileToRemove.id],
    });

    try {
      await deleteFileService({file: fileToRemove});
      if (!this.mounted) return;

      this.setState({
        removing: [...this.state.removing].filter(
          (id) => id !== fileToRemove.id
        ),
      });
    } catch (error) {
      if (!this.mounted) return;

      this.setState({
        removing: [...this.state.removing].filter(
          (id) => id !== fileToRemove.id
        ),
      });
    }
  };

  handleRemoveClick = (file) => () => {
    const {removing} = this.state;
    if (removing.includes(file.id)) return;

    this.remove(file);
  };

  showAnswers = (file = null) => {
    const {engagement} = this.props;
    showContractAnswersModalEvent.publish({
      engagementId: engagement.id,
      fileId: file.id,
    });
  };

  fileActions = () => {
    const {company, engagement} = this.props;
    return [
      {
        key: 'showReport',
        label: 'Show Report',
        icon: <FileSearchOutlined />,
        url: (file) => {
          return complianceFileDetailRoute({
            companyId: getCompanyId(company),
            engagementId: engagement.id,
            fileVersionId: file.latest_file_version_id,
          });
        },
      },
    ];
  };

  shouldDisplayShowAnswersButton = () => {
    const {engagementFiles, uploadingFiles} = this.props;
    return (
      !!engagementFiles.length &&
      engagementFiles.some(
        (file) =>
          file?.version?.is_uploaded || !uploadingFiles.includes(file.id)
      )
    );
  };

  files = () => {
    const {engagement, engagementFiles, service} = this.props;
    const {search} = this.state;

    const lowerCaseSearch = search.toLowerCase();

    const filteredFiles = getEngagementFiles({
      files: engagementFiles,
      engagement,
      service,
    });

    const searchedFiles = !!lowerCaseSearch.trim().length
      ? filteredFiles.filter((file) =>
          file.name.toLowerCase().includes(lowerCaseSearch)
        )
      : filteredFiles;

    return searchedFiles;
  };

  downloadFiles = async (file) => {
    if (this.state.downloading.includes(file.id)) return;

    this.setState({
      downloading: [...this.state.downloading, file.id],
    });

    try {
      const url = await getDownloadLinkService({file});
      if (!!url) window.open(url, '_blank');
    } catch (error) {
      // do nothing
    }

    if (!this.mounted) return;
    this.setState({
      downloading: [...this.state.downloading].filter((id) => id !== file.id),
    });
  };

  render() {
    const {children} = this.props;
    const {downloading, removing, search, uploading} = this.state;

    return children({
      downloading,
      fileActions: this.fileActions(),
      files: this.files(),
      onDelete: this.handleRemoveClick,
      onDownload: this.downloadFiles,
      onSearch: this.search,
      onUpload: this.handleUpload,
      removing,
      search,
      uploading,
    });
  }
}

export default connect((state) => ({
  company: state.company.company,
  engagement: state.engagement.engagement,
  engagementFiles: state.engagement.engagementFiles,
  service: state.engagement.service,
  uploadingFiles: state.file.uploading,
}))(EngagementFileManagerContainer);
