import React from 'react';
import {Table} from 'antd';

const IndustryBreakdown = () => {
  const data = [
    {
      key: '1',
      industry: 'Retail Trade',
      tradeLines: 10,
      current: 'Can$10,894',
      period1: 'Can$9,421',
      period2: 'Can$4,065',
      period3: 'Can$20,332',
      total: 'Can$10,894',
    },
    {
      key: '2',
      industry: 'Wholesale Trade',
      tradeLines: 68,
      current: 'Can$89,987',
      period1: 'Can$95,688',
      period2: 'Can$144,385',
      period3: 'Can$17,507',
      total: 'Can$89,987',
    },
    {
      key: '3',
      industry: 'Manufacturing',
      tradeLines: 11,
      current: 'Can$46,535',
      period1: 'Can$32,703',
      period2: 'Can$76,902',
      period3: 'Can$4,655',
      total: 'Can$46,535',
    },
    {
      key: '4',
      industry: 'Construction',
      tradeLines: 33,
      current: 'Can$987,663',
      period1: 'Can$96,495',
      period2: 'Can$19,444',
      period3: 'Can$75,868',
      total: 'Can$987,663',
    },
    {
      key: '5',
      industry: 'Services',
      tradeLines: 37,
      current: 'Can$6,963',
      period1: 'Can$34,425',
      period2: 'Can$42,176',
      period3: 'Can$24,969',
      total: 'Can$6,963',
    },
    {
      key: '6',
      industry: 'Finance, Insurance, and Real Estate',
      tradeLines: 4,
      current: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      total: 'Can$0',
    },
    {
      key: '7',
      industry:
        'Transportation, Communications, Electric, Gas, and Sanitary Services',
      tradeLines: 1,
      current: 'Can$1,874',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$0',
      total: 'Can$1,874',
    },
    {
      key: '8',
      industry: 'Federal, State, and Local Government',
      tradeLines: 1,
      current: 'Can$98',
      period1: 'Can$49',
      period2: 'Can$49',
      period3: 'Can$1,082',
      total: 'Can$98',
    },
  ];

  const columns = [
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: 'Trade Lines',
      dataIndex: 'tradeLines',
      key: 'tradeLines',
    },
    {
      title: 'Current',
      dataIndex: 'current',
      key: 'current',
    },
    {
      title: 'Period 1',
      dataIndex: 'period1',
      key: 'period1',
    },
    {
      title: 'Period 2',
      dataIndex: 'period2',
      key: 'period2',
    },
    {
      title: 'Period 3',
      dataIndex: 'period3',
      key: 'period3',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default IndustryBreakdown;
