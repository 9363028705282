// compliance components
import ControlVarianceListModal from '../../components/ControlVarianceListModal/ControlVarianceListModal';

// compliance events
import showControlVarianceListEvent from '../../events/showControlVarianceList.event.compliance';

// compliance lib
import isCustomAnsysFindingResponse from '../../lib/isCustomAnsysFindingResponse.lib.compliance';
import parseFindingControlDescription from '../../lib/parseFindingControlDescription.lib.compliance';
import parseFindingResponse from '../../lib/parseFindingResponse.lib.compliance';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class ControlVarianceListContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    frameworks: PropTypes.array,
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    results: [],
    selectedCompliance: null,
    selectedCoverageLevel: '0',
    selectedFrameworks: [],
    showResults: false,
    visible: false,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showControlVarianceListEvent.subscribe(this.show));
  }

  show = () => {
    this.setState({
      ...this.constructor.DEFAULT_STATE,
      visible: true,
    });
  };

  hide = () => {
    this.setState({visible: false});
  };

  computeResults = () => {
    const {frameworks} = this.props;
    const {selectedCompliance, selectedCoverageLevel, selectedFrameworks} =
      this.state;
    const numericCompliance = Number(selectedCompliance) || 0;
    const results = [...frameworks]
      .map((framework) => {
        const isSelected = selectedFrameworks.includes(framework.framework);
        if (!isSelected) return null;

        const areas = [...framework.areas]
          .map((area) => ({
            ...area,
            findings: [...area.findings].filter(
              (finding) =>
                finding.compliance <= numericCompliance &&
                finding.completeness <= (selectedCoverageLevel || 0)
            ),
          }))
          .filter(({findings}) => !!findings.length);
        return !!areas.length ? {...framework, areas} : null;
      })
      .filter((framework) => !!framework);
    this.setState({showResults: true, results});
  };

  backToFilters = () => {
    this.setState({showResults: false});
  };

  insertData = (key) => (e) => {
    const value = !!e?.target ? e.target.value : e;
    this.setState({[key]: value, showResults: false});
  };

  selectData = (key) => (value) => {
    const previousValues = [...this.state[key]];
    const updateValues = previousValues.includes(value)
      ? previousValues.filter((v) => v !== value)
      : [...previousValues, value];
    this.setState({[key]: updateValues, showResults: false});
  };

  compliances = () => [
    {
      value: '100',
      label: 'Excellent',
    },
    {
      value: '80',
      label: 'Very Good',
    },
    {
      value: '60',
      label: 'Good',
    },
    {
      value: '40',
      label: 'Poor',
    },
    {
      value: '20',
      label: 'Very Poor',
    },
  ];

  frameworks = () =>
    [...this.props.frameworks]
      .map((framework) => ({
        value: framework.framework,
        label: framework.framework,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

  sanitizeCSVValue = (value) => {
    if (value === null || value === undefined) return '-';
    if (typeof value !== 'string') return `"${value}"`;

    let sanitized = value.replace(/"/g, '""');
    return `"${sanitized}"`;
  };

  downloadCsv = () => {
    const {results} = this.state;
    const findings = results
      .map((framework) =>
        framework.areas
          .map((area) =>
            [...area.findings].map((finding) => ({
              ...finding,
              area: area.area,
              framework: framework.framework,
            }))
          )
          .flat()
      )
      .flat()
      .map((finding) => {
        const customFields = !!finding.response
          ? isCustomAnsysFindingResponse(finding.response)
            ? {
                Response: parseFindingResponse(finding.response)
                  .map(
                    (finding) => `${finding.title}: ${finding.content || '-'}`
                  )
                  .join('\n'),
              }
            : {Response: finding.response}
          : {Response: '-'};
        return {
          Framework: finding.framework,
          Area: finding.area,
          'Control ID': finding.controlId,
          'Control Description': parseFindingControlDescription(
            finding.controlDescription || '-'
          ),
          'Control Efficacy': finding.compliance,
          Commentary: finding.explanation || '-',
          ...customFields,
          Coverage: finding.completeness,
          'Control Tested': finding.isTested ? 'Yes' : 'No',
          'Test Result': !finding.isTested
            ? 'n/a'
            : finding.isPassed
            ? 'Passed'
            : 'Failed',
          References: finding.reference,
        };
      });
    const columns = Object.keys(findings[0]).map((key) =>
      this.sanitizeCSVValue(key)
    );
    const csvContent = [
      columns.join(','),
      ...findings.map((finding) =>
        Object.values(finding)
          .map((value) => this.sanitizeCSVValue(value))
          .join(',')
      ),
    ].join('\n');
    const blob = new Blob([csvContent], {type: 'text/csv'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'findings.csv';
    a.click();
  };

  render() {
    const {
      results,
      selectedCompliance,
      selectedCoverageLevel,
      selectedFrameworks,
      showResults,
      visible,
    } = this.state;

    return (
      <ControlVarianceListModal
        compliances={this.compliances()}
        frameworks={this.frameworks()}
        onBack={this.backToFilters}
        onClose={this.hide}
        onConfirm={this.computeResults}
        onCsvDownload={this.downloadCsv}
        onInsert={this.insertData}
        onSelect={this.selectData}
        results={results}
        selectedCompliance={selectedCompliance}
        selectedCoverageLevel={selectedCoverageLevel}
        selectedFrameworks={selectedFrameworks}
        showResults={showResults}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({
  company: state.company.company,
  frameworks: state.engagement.compliance?.frameworks || [],
}))(subscriptionHOC(ControlVarianceListContainer));
