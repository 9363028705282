import React from 'react';
import {Table, Card} from 'antd';

const TradePaymentInformation = () => {
  const tradeLines = 4;

  const data = [
    {
      key: '1',
      current: 'Can$0',
      period1: 'Can$0',
      period2: 'Can$0',
      period3: 'Can$729',
      total: 'Can$729',
    },
    {
      key: '2',
      current: '0.00%',
      period1: '0.00%',
      period2: '0.00%',
      period3: '100.00%',
      total: '100.00%',
    },
  ];

  const columns = [
    {title: 'Current', dataIndex: 'current', key: 'current'},
    {title: 'Period 1', dataIndex: 'period1', key: 'period1'},
    {title: 'Period 2', dataIndex: 'period2', key: 'period2'},
    {title: 'Period 3', dataIndex: 'period3', key: 'period3'},
    {title: 'Total', dataIndex: 'total', key: 'total'},
  ];

  return (
    <div style={{display: 'flex', alignItems: 'flex-start'}}>
      <Card style={{width: 200, marginRight: 16}}>
        <h3>Total Trade Lines</h3>
        <h1 style={{color: 'green', margin: 0}}>{tradeLines}</h1>
      </Card>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        style={{flex: 1}}
      />
    </div>
  );
};

export default TradePaymentInformation;
