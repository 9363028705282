// compliance services
import listFrameworkScoresService from './listFrameworkScores.service.compliance';

// contract api
import listContractAnswersApi from '../../contract/api/listAnswers.api.contract';

const listFrameworkScoresAndQuestionnairesService = async ({
  companyId,
  fileVersionId,
  serviceId,
  engagementId,
}) => {
  try {
    const query = !!fileVersionId
      ? {file_version_id: fileVersionId}
      : !!serviceId
      ? {engagement_service_id: serviceId}
      : !!engagementId
      ? {engagement_id: engagementId}
      : {company_id: companyId};

    const [compliance, {data: questionnaires}] = await Promise.all([
      listFrameworkScoresService({
        companyId,
        fileVersionId,
        serviceId,
        engagementId,
      }),
      !!engagementId ? listContractAnswersApi(engagementId, query) : {data: []},
    ]);

    return {
      compliance,
      questionnaires,
    };
  } catch (error) {
    return {
      compliance: {rating: 0, frameworks: []},
      questionnaires: [],
    };
  }
};

export default listFrameworkScoresAndQuestionnairesService;
