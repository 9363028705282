import styled from 'styled-components';

// layout colors
import grey600Color from '../../../../layout/colors/grey600.color.layout';

const SelectContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid ${grey600Color};
`;

export default SelectContainer;
