// currency
import currency from 'currency.js';

const multiply = (number, ...numbersToSum) =>
  numbersToSum.reduce(
    (total, numberToSum) => total.multiply(numberToSum),
    currency(number)
  ).value;

export default multiply;
