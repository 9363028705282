// ant icons
import {DownloadOutlined, LoadingOutlined} from '@ant-design/icons';

// file containers
import FileDownloaderContainer from '../../containers/FileDownloaderContainer/FileDownloaderContainer';

// local components
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const InlineFileDownloader = ({children, fileId, fileVersionId}) => (
  <FileDownloaderContainer fileId={fileId} fileVersionId={fileVersionId}>
    {({downloading, onDownload}) => (
      <Container onClick={onDownload}>
        {downloading ? <LoadingOutlined spin /> : <DownloadOutlined />}
        {children}
      </Container>
    )}
  </FileDownloaderContainer>
);

InlineFileDownloader.propTypes = {
  children: PropTypes.node,
  fileId: PropTypes.string,
  fileVersionId: PropTypes.string,
};

export default InlineFileDownloader;
