// ant icons
import {
  PlusOutlined,
  LoadingOutlined,
  SlidersOutlined,
} from '@ant-design/icons';

// engagement components
import HeadMenu from '../../HeadMenu/HeadMenu';

// local handlers
import openControlVarianceModalHandler from '../handlers/openControlVarianceModal.handler';
import openCreateEngagementHandler from '../handlers/openCreateEngagement.handler';
import openCreateEngagementServiceHandler from '../handlers/openCreateEngagementService.handler';

const generateActions = ({
  actions = [],
  companyId,
  creatingEngagement,
  creatingEngagementService,
  engagementId,
  excludeDefaultActions = false,
  hideActions = false,
  navigate,
}) =>
  hideActions
    ? []
    : [
        ...(excludeDefaultActions
          ? []
          : [
              {
                key: 'newEngagement',
                label: (
                  <HeadMenu
                    label={
                      creatingEngagement
                        ? 'Creating Engagement'
                        : 'New Engagement'
                    }
                    description="Create a new engagement with the selected supplier."
                  />
                ),
                icon: creatingEngagement ? (
                  <LoadingOutlined spin />
                ) : (
                  <PlusOutlined />
                ),
                onClick: openCreateEngagementHandler(),
              },
              {type: 'divider'},
              {
                key: 'newService',
                label: (
                  <HeadMenu
                    label={
                      creatingEngagementService
                        ? 'Creating Service'
                        : 'New Service'
                    }
                    description="Add an additional service to the selected engagement."
                  />
                ),
                icon: creatingEngagementService ? (
                  <LoadingOutlined spin />
                ) : (
                  <PlusOutlined />
                ),
                onClick: openCreateEngagementServiceHandler({
                  companyId,
                  engagementId,
                  navigate,
                }),
              },
              {type: 'divider'},
              {
                key: 'controlVariance',
                label: <HeadMenu label="Control Variance Report" />,
                icon: <SlidersOutlined />,
                onClick: openControlVarianceModalHandler(),
              },
            ]),
        ...(!!actions?.length ? [{type: 'divider'}] : []),
        ...[...actions].map(({label, description, ...action}) => ({
          ...action,
          label: <HeadMenu label={label} description={description} />,
        })),
      ];

export default generateActions;
