// cyberSecurity lib
import generateSupplierCyberSecurityData from '../../cyberSecurity/lib/generateSupplierCyberSecurityData.lib.cyberSecurity';

// data lib
import prepareData from '../../data/lib/prepare.lib.data';

// financial lib
import mapFinancials from '../../financial/lib/mapFinancials.lib.financial';

const prepareSupplierDashboardData = ({
  alerts = [],
  company,
  tenantData = {},
}) => {
  const preparedOriginalHaloScores = prepareData({
    scores: company.HaloScores,
  });
  const preparedWeightedHaloScores = prepareData({
    scores: company.weightedHaloScores,
  });

  const companyTenantData = tenantData?.[company.CompanyId] || {};

  const haloAlerts = [...alerts].filter(
    ({supplierId, workflow}) =>
      workflow.actions.includes('alert') &&
      `${supplierId}` === `${company.CompanyId}`
  );

  const combinedAlerts = [...haloAlerts];

  const supplier = {
    ...companyTenantData,
    ...company,
    alerts: combinedAlerts,
    CyberSecurity: generateSupplierCyberSecurityData({
      supplier: company,
      tenantData: companyTenantData,
    }),
    HaloScores: preparedWeightedHaloScores.haloScores,
    historicHaloScores: preparedWeightedHaloScores.historicHaloScores,
    originalHaloScores: {
      HaloScores: preparedOriginalHaloScores.haloScores,
      historicHaloScores: preparedOriginalHaloScores.historicHaloScores,
    },
  };

  if (!!supplier.financials) {
    supplier.financials = {
      ...supplier.financials,
      ...mapFinancials(supplier),
    };
  }

  if (!!supplier?.tags?.length)
    supplier.tags = [...supplier.tags].map((tag) => {
      const companyTag = [...(tag?.companies || [])].find(
        ({company_id}) =>
          company_id.replace('company_', '') === company.CompanyId
      );
      return !!companyTag ? {...companyTag, id: tag.id} : tag;
    });

  // TODO: Remove this when we have real data
  if (supplier.CompanyId === '208e60da614940d18bbad2114724435c') {
    supplier.originalHaloScores.HaloScores.Financial_Financial.Value = 42;
  }
  if (supplier.CompanyId === '170a250c0b1446f981c8d1be7af4e0f7') {
    supplier.originalHaloScores.HaloScores.Financial_Financial.Value = 81;
  }

  return supplier;
};

export default prepareSupplierDashboardData;
