import styled from 'styled-components';

const Container = styled.div`
  width: ${({width}) => width};
  height: 10px;
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
`;

export default Container;
