import React from 'react';
import {Table, Badge} from 'antd';

const IndustryTradeSummary = () => {
  const data = [
    {
      key: '1',
      category: '90 Day',
      piScore: 12,
      creditReferences: 3,
      highCredit: 'Can$140.7K',
      currentOwing: 'Can$605',
      period1: 'Can$334',
      period2: 'Can$0',
      period3: 'Can$0',
      totalOwing: 'Can$939',
    },
    {
      key: '2',
      category: '13 Month',
      piScore: 77,
      creditReferences: 8,
      highCredit: 'Can$169.1K',
      currentOwing: 'Can$2.2K',
      period1: 'Can$334',
      period2: 'Can$10K',
      period3: 'Can$6.8K',
      totalOwing: 'Can$19.3K',
    },
    {
      key: '3',
      category: 'All References',
      piScore: 65,
      creditReferences: 14,
      highCredit: 'Can$211.7K',
      currentOwing: 'Can$5.8K',
      period1: 'Can$334',
      period2: 'Can$10K',
      period3: 'Can$7.8K',
      totalOwing: 'Can$24K',
    },
  ];

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'PI Score',
      dataIndex: 'piScore',
      key: 'piScore',
      render: (value) => {
        let color = 'green';
        if (value > 50) color = 'red';
        else if (value > 0) color = 'yellow';
        return <Badge color={color} text={value} />;
      },
    },
    {
      title: 'Credit References',
      dataIndex: 'creditReferences',
      key: 'creditReferences',
    },
    {
      title: 'High Credit',
      dataIndex: 'highCredit',
      key: 'highCredit',
    },
    {
      title: 'Current Owing',
      dataIndex: 'currentOwing',
      key: 'currentOwing',
    },
    {
      title: 'Period 1',
      dataIndex: 'period1',
      key: 'period1',
    },
    {
      title: 'Period 2',
      dataIndex: 'period2',
      key: 'period2',
    },
    {
      title: 'Period 3',
      dataIndex: 'period3',
      key: 'period3',
    },
    {
      title: 'Total Owing',
      dataIndex: 'totalOwing',
      key: 'totalOwing',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default IndustryTradeSummary;
