import styled from 'styled-components';

// layout colors
import green400Color from '../../../colors/green400.color.layout';

const Container = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: ${green400Color};
`;

export default Container;
