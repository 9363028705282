// file api
import getFileDownloadLinkApi from '../api/getDownloadLink.api.file';

const getDownloadLinkService = async ({file, fileId, fileVersionId}) => {
  try {
    const fileWithDownloadLink = await getFileDownloadLinkApi({
      fileId: fileId || file.id,
      fileVersionId: fileVersionId || file.version.id,
    });
    return fileWithDownloadLink?.version?.download_url || null;
  } catch (error) {
    return null;
  }
};

export default getDownloadLinkService;
