// ant components
import {Table, Tag} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// layout colors
import grey800Color from '../../../layout/colors/grey800.color.layout';

// react
import React from 'react';

// Social Media Logos
const socialIcons = {
  facebook:
    'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg',
  twitter:
    'https://upload.wikimedia.org/wikipedia/en/6/60/Twitter_Logo_as_of_2021.svg',
  instagram:
    'https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png',
  linkedin:
    'https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png',
  ios_app:
    'https://upload.wikimedia.org/wikipedia/commons/3/31/App_Store_iOS_icon.png',
  android_app:
    'https://upload.wikimedia.org/wikipedia/commons/d/d0/Android_robot.svg',
  youtube:
    'https://upload.wikimedia.org/wikipedia/commons/0/09/YouTube_full-color_icon_%282017%29.svg',
};

const ContactInformationTable = ({firmographics}) => {
  if (!firmographics) return null;

  const {phone_numbers, emails, website_url, social_media} = firmographics;

  const data = [
    phone_numbers?.length && {
      key: 'phone_numbers',
      field: 'Phone Numbers',
      value: (
        <>
          {phone_numbers.map((phone) => (
            <span key={phone.number}>
              <a href={`tel:${phone.number}`}>{phone.number}</a>{' '}
              {phone.primary && <Tag color={grey800Color}>Primary</Tag>}
              <br />
            </span>
          ))}
        </>
      ),
    },
    emails?.length && {
      key: 'emails',
      field: 'Emails',
      value: (
        <>
          {emails.map((email) => (
            <span key={email.email}>
              <a href={`mailto:${email.email}`}>{email.email}</a>{' '}
              {email.primary && <Tag color={grey800Color}>Primary</Tag>}
              <br />
            </span>
          ))}
        </>
      ),
    },
    website_url && {
      key: 'website',
      field: 'Website',
      value: (
        <a href={website_url} target="_blank" rel="noopener noreferrer">
          {website_url.replace(/^https?:\/\//, '')}
        </a>
      ),
    },
    social_media?.length && {
      key: 'social_media',
      field: 'Social Media',
      value: (
        <>
          {social_media.map((media) => {
            const icon = socialIcons[media.media];
            return (
              <a
                key={media.media}
                href={media.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{marginRight: 10}}
              >
                <img src={icon} alt={media.media} width="24" height="24" />
              </a>
            );
          })}
        </>
      ),
    },
  ].filter(Boolean); // Removes null or undefined values

  return (
    <StatementBlock title="Contact Information">
      <Table
        columns={[
          {
            dataIndex: 'field',
            key: 'field',
            render: (text) => <strong>{text}</strong>,
          },
          {
            dataIndex: 'value',
            key: 'value',
          },
        ]}
        dataSource={data}
        pagination={false}
        size="small"
        rowKey="key"
        showHeader={false} // Hides the table header
      />
    </StatementBlock>
  );
};

export default ContactInformationTable;
