import React from 'react';
import {Table} from 'antd';

const SanctionSourcesTable = ({data}) => {
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => (text && text !== 'None' ? text : '-'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => (text && text !== 'None' ? text : '-'),
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      render: (text) =>
        text ? (
          <a href={text} target="_blank" rel="noopener noreferrer">
            View
          </a>
        ) : (
          '-'
        ),
    },
    {
      title: 'Date Added',
      dataIndex: 'dateAdded',
      key: 'dateAdded',
    },
    {
      title: 'Copyrighted',
      dataIndex: 'isCopyrighted',
      key: 'isCopyrighted',
      render: (text) => (text === 'True' ? 'Yes' : 'No'),
    },
    {
      title: 'Date Published',
      dataIndex: 'datePublished',
      key: 'datePublished',
      render: (text) => (text && text !== 'None' ? text : '-'),
    },
    {
      title: 'Document',
      dataIndex: 'documentLink',
      key: 'documentLink',
      render: (text) =>
        text && text !== 'None' ? (
          <a href={text} target="_blank" rel="noopener noreferrer">
            Download
          </a>
        ) : (
          '-'
        ),
    },
    {
      title: 'Datasets',
      dataIndex: 'datasets',
      key: 'datasets',
      render: (datasets) => datasets.join(', '),
    },
  ];

  return (
    <Table columns={columns} dataSource={data} rowKey="id" pagination={false} />
  );
};

export default SanctionSourcesTable;
