// engagement routes
import engagementRoute from '../../../engagement/pages/EngagementDetailPage/route';

// object lib
import getRawObjectId from '../../../object/lib/getRawId.lib.object';

// route lib
import parseRoute from '../../../route/lib/parseRoute.lib.route';

const route = (
  {
    companyId = ':id',
    engagementId = ':engagementId',
    fileVersionId = ':fileVersionId',
  } = {},
  {query, hash} = {}
) =>
  parseRoute({
    path: `${engagementRoute({
      companyId,
      engagementId,
    })}/file/${getRawObjectId(fileVersionId)}`,
    query,
    hash,
  });

export default route;
