import styled from 'styled-components';

const ScoreContainer = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translateX(-20px);
  position: relative;
  left: ${({score}) => `${score}%`};
  font-weight: 700;
  line-height: 1em;

  & > span {
    color: ${({color}) => color};
  }
`;

export default ScoreContainer;
